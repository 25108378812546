const types = {
  RESET_APPLICATION: 'RESET_APPLICATION',
  SET_SIDENAV_STATE: 'SET_SIDENAV_STATE',
  SET_CLIPBOARD_STATE: 'SET_CLIPBOARD_STATE',
  SET_HELP_REQUEST_NAV_ALERT_STATE: 'SET_HELP_REQUEST_NAV_ALERT_STATE'
}

const getDefaultState = () => {
  return {
    sidenavState: 'ACTIVE',
    clipboardState: 'HIDDEN',
    helpRequestNavAlert: false
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  sidenavState: state => state.sidenavState, // OFF: not logged in, ACTIVE: fully open, HIDDEN: closed
  clipboardState: state => state.clipboardState, // OFF: not logged in, ACTIVE: fully open, HIDDEN: closed
  helpRequestNavAlert: state => state.helpRequestNavAlert // FALSE: no unacknowledgedHelpRequests, TRUE: has unacknowledgedHelpRequests
}

// actions
const actions = {
  reset ({ commit }) { // reset the user
    commit('types.RESET_APPLICATION')
  },
  setSidenavState ({ commit }, payload) { // just the current user id
    commit(types.SET_SIDENAV_STATE, payload)
  },
  setClipboardState ({ commit }, payload) { // just the current user id
    commit(types.SET_CLIPBOARD_STATE, payload)
  },
  setHelpRequestNavAlert ({ commit }, payload) { // just the current user id
    commit(types.SET_HELP_REQUEST_NAV_ALERT_STATE, payload)
  }
}

// mutations
const mutations = {
  [types.RESET_APPLICATION] (state) {
    Object.assign(state, getDefaultState())
  },
  [types.SET_SIDENAV_STATE] (state, payload) {
    state.sidenavState = payload
  },
  [types.SET_HELP_REQUEST_NAV_ALERT_STATE] (state, payload) {
    state.helpRequestNavAlert = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
