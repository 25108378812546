<template>
  <footer
    class="ovb-footer"
  >
    <div class="ovb-footer-content">
      <div class="content-row container mobile-container">
        <div>
          <img
            class="ovb-text-logo"
            alt="Our Visual Brain"
            :src="ovbLogo"
          />
          <div class="my-2">
              <span class="is-size-6">5775 Soundview Dr<br>
                  Suite 102A<br>
                    Gig Harbor, WA 98335</span>
          </div>
        </div>
        <div
          class="nav-list"
        >
          <div
            class="nav-title"
          >
            Resources
          </div>
          <a
            href="https://ourvisualbrain.hflip.co/bea2844c1f.html"
            target="_blank"
            class="footer-link"
          >
            <span class="icon is-medium mr-1"><i class="mdi mdi-book-open-variant"></i></span>OVB User's Guide
          </a>
          <a
            href="/pdf/OVB Parent Companion Guide-020623.pdf"
            target="_blank"
            class="footer-link"
          >
            <span class="icon is-medium mr-1"><i class="mdi mdi-file-pdf-box"></i></span>OVB Parent Companion Guide
          </a>
          <a
            href="/pdf/OVB Calibration Instructions.pdf"
            target="_blank"
            class="footer-link"
          >
            <span class="icon is-medium mr-1"><i class="mdi mdi-file-pdf-box"></i></span>OVB Calibration Instructions
          </a>
        </div>
        <div
          class="nav-list"
        >
          <div
            class="nav-title"
          >
            About
          </div>
          <router-link
            class="footer-link"
            :to="{ name: 'PrivacyPolicy', params: {stickyNav: true}}"
          >Privacy Policy</router-link>
          <router-link
            class="footer-link"
            :to="{ name: 'TermsAndConditions', params: {stickyNav: true}}"
          >Terms and Conditions</router-link>
        </div>
        <div
          class="nav-list"
        >
          <div
            class="nav-title"
          >
            Contact
          </div>
          <div>
            <a
              :href="'mailto:' + emailHelp"
              class="footer-link"
            >
              {{ emailHelp }}
            </a>
          </div>
        </div>
      </div>
      <div class="footer-separator mt-6" />
      <div class="content-row container">
        <div
          class="copyright-notice"
        >
          <p>
            Copyright © {{ currentYear }} Our Visual Brain LLC. All rights reserved.
            <br>The information and graphics on this Web Site may not be reproduced in any material form or transmitted to any persons without permission from Our Visual Brain.
          </p>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'TheFooter',
  data () {
    return {
      ovbLogo: require('@/assets/img/ovb_logo_text_only.svg')
    }
  },
  computed: {
    emailHelp () {
      return this.$store.state.user.supportEmail
    },
    currentYear () {
      return this.$dateFns.getYear(new Date())
    }
  },
  mounted () {
    this.$store.dispatch('user/checkHousekeepingTasks')
  }
}
</script>

<style scoped lang="scss">
footer.ovb-footer {
  background-color: $jet;
  padding: 2rem 0 2rem 0;
  display: flex;
  flex-direction: column;
  color: $gray-light;
  position: relative;
  @include fullhd {
    padding: 2rem 0 3rem 0;
  }
}
.ovb-text-logo {
  width: 15rem;
  opacity: .5;
}
.ovb-footer-content {
  background-color: $jet;
  flex: 1;
  .footer-separator {
    border-top: 0.5px solid $gray-light;
    opacity: 0.25;
  }
  .content-row {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
    @include tablet {
      flex-direction: row;
    }
    @include desktop {
      padding: 0;
    }
  }
  .nav-list {
    display: flex;
    flex-direction: column;
    .nav-title {
      font-family: $family-semibold;
      font-size: 1.25rem;
      opacity: 0.5;
    }
    .footer-link {
      color: $gray-light;
      &:hover {
        text-decoration: underline;
      }
    }
  }
}
</style>
