import { gsap } from 'gsap'
import { Expo, ScrollToPlugin, CSSPlugin } from 'gsap/all'
gsap.registerPlugin(ScrollToPlugin, CSSPlugin)

export const goToRef = {
  methods: {
    goto (refName) {
      const element = this.$refs[refName]
      const top = element.offsetTop
      gsap.to(window, 0.1, { scrollTo: { x: 0, y: top }, ease: Expo.easeInOut })
    },
    scrollToElement (el, offset) {
      const heightOffset = offset || 28
      const rect = el.getBoundingClientRect()
      const tgtY = (rect.top - rect.height - heightOffset) + window.scrollY
      gsap.to(window, 0.75, { scrollTo: { x: 0, y: tgtY }, ease: Expo.easeInOut })
    },
    scrollToTop () {
      const tgtY = window.scrollY * -1
      gsap.to(window, 0.75, { scrollTo: { x: 0, y: tgtY }, ease: Expo.easeInOut })
    },
    scrollToRefName (refName, offset) {
      const heightOffset = offset || 0
      const element = this.$refs[refName]
      const top = element.offsetTop - heightOffset
      gsap.to(window, 0.75, { scrollTo: { x: 0, y: top }, ease: Expo.easeInOut })
    },
    scrollToChildRef (refName, offset) {
      const heightOffset = offset || 0
      const parentTop = this.$refs[refName].offsetParent ? this.$refs[refName].offsetParent.offsetTop : 0
      const elementTop = this.$refs[refName].offsetTop
      const top = (parentTop + elementTop) - heightOffset
      gsap.to(window, 1, { scrollTo: { x: 0, y: top }, ease: Expo.easeInOut })
    },
    demoScroll () {
      const tl = gsap.timeline({
        // yes, we can add it to an entire timeline!
        scrollTrigger: {
          trigger: window,
          pin: true, // pin the trigger element while active
          start: 'top top', // when the top of the trigger hits the top of the viewport
          end: 'bottom bottom', // end after scrolling 500px beyond the start
          scrub: 1, // smooth scrubbing, takes 1 second to "catch up" to the scrollbar
          snap: {
            snapTo: 'labels', // snap to the closest label in the timeline
            duration: { min: 0.2, max: 3 }, // the snap animation should be at least 0.2 seconds, but no more than 3 seconds (determined by velocity)
            delay: 0.2, // wait 0.2 seconds from the last scroll event before doing the snapping
            ease: 'power1.inOut' // the ease of the snap animation ("power3" by default)
          }
        }
      })
      setTimeout(() => tl.play(), 2000)
    }
  }
}

export const spaceDasher = {
  methods: {
    spaceDasher: function (val) {
      return val ? val.replace(/--/g, '').replace(/\s-\s/g, '-').replace(/\s+/g, '-').toLowerCase() : null
    }
  }
}
