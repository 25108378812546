import router from '@/router'
import omitDeep from 'omit-deep'
import apolloProvider from '@/graphql'
// import { getInstance } from '@/auth/authWrapper.js'
// import errorHandler from './_errorHandler/errorHandler'

import cloneLicensePack from '@/graphql/mutation/cloneLicensePack.graphql'
import publishLicensePack from '@/graphql/mutation/publishLicensePack.graphql'
import discontinueLicensePack from '@/graphql/mutation/discontinueLicensePack.graphql'
import discardLicensePack from '@/graphql/mutation/discardLicensePack.graphql'
import upsertLicensePack from '@/graphql/mutation/upsertLicensePack.graphql'
import removeLicensePackLicenseType from '@/graphql/mutation/removeLicensePackLicenseType.graphql'
import reactivateAppUser from '@/graphql/mutation/reactivateAppUser.graphql'
import deactivateAppUser from '@/graphql/mutation/deactivateAppUser.graphql'
import removeAuth0User from '@/graphql/mutation/removeAuth0User.graphql'
import activateAppTenantSubscription from '@/graphql/mutation/activateAppTenantSubscription.graphql'
import deactivateAppTenantSubscription from '@/graphql/mutation/deactivateAppTenantSubscription.graphql'
import deactivateAppTenantSubscriptions from '@/graphql/mutation/deactivateAppTenantSubscriptions.graphql'
import activateLicense from '@/graphql/mutation/activateLicense.graphql'
import deactivateLicense from '@/graphql/mutation/deactivateLicense.graphql'
import deleteLicense from '@/graphql/mutation/deleteLicense.graphql'
import renewAppUserLicense from '@/graphql/mutation/renewAppUserLicense.graphql'
import updateAppTenantSetting from '@/graphql/mutation/updateAppTenantSetting.graphql'
import upsertContentSlug from '@/graphql/mutation/upsertContentSlug.graphql'
import setContentSlugCurrentVersionSet from '@/graphql/mutation/setContentSlugCurrentVersionSet.graphql'
import raiseException from '@/graphql/mutation/raiseException.graphql'
import reportAppErrors from '@/graphql/mutation/reportAppErrors.graphql'
import removeAppTenant from '@/graphql/mutation/removeAppTenant.graphql'
import removeAppUser from '@/graphql/mutation/removeAppUser.graphql'
import createSubsidiary from '@/graphql/mutation/createSubsidiary.graphql'
import deleteAppErrors from '@/graphql/mutation/deleteAppErrors.graphql'
import subscribeToLicensePackNewTenant from '@/graphql/mutation/subscribeToLicensePackNewTenant.graphql'
import requestPricing from '@/graphql/mutation/requestPricing.graphql'
import subscribeFromBrochure from '@/graphql/mutation/subscribeFromBrochure.graphql'
import contactFromBrochure from '@/graphql/mutation/contactFromBrochure.graphql'
import sendEmail from '@/graphql/mutation/sendEmail.graphql'
import queueWorkflow from '@/graphql/mutation/queueWorkflow.graphql'
import setAppEula from '@/graphql/mutation/setAppEula.graphql'
import linkOldCustomerToAppTenant from '@/graphql/mutation/linkOldCustomerToAppTenant.graphql'
import linkOldUserToAppUser from '@/graphql/mutation/linkOldUserToAppUser.graphql'
import unlinkOldUserToAppUser from '@/graphql/mutation/unlinkOldUserToAppUser.graphql'
import upsertHelpTopic from '@/graphql/mutation/upsertHelpTopic.graphql'
import deleteHelpTopic from '@/graphql/mutation/deleteHelpTopic.graphql'
import updateLicenseExpiration from '@/graphql/mutation/updateLicenseExpiration.graphql'
import updateAppTenantName from '@/graphql/mutation/updateAppTenantName.graphql'
import consolidateAppTenantSubscription from '@/graphql/mutation/consolidateAppTenantSubscription.graphql'
import moveLicensesToSubscription from '@/graphql/mutation/moveLicensesToSubscription.graphql'
import changeLicenseType from '@/graphql/mutation/changeLicenseType.graphql'
import voidLicenses from '@/graphql/mutation/voidLicenses.graphql'
import unvoidLicenses from '@/graphql/mutation/unvoidLicenses.graphql'
import addressErrorReport from '@/graphql/mutation/addressErrorReport.graphql'
import changeAppUserEmail from '@/graphql/mutation/changeAppUserEmail.graphql'
import updateContactInfo from '@/graphql/mutation/updateContactInfo.graphql'
import promoteUserToAdmin from '@/graphql/mutation/promoteUserToAdmin.graphql'
import demoteUserFromAdmin from '@/graphql/mutation/demoteUserFromAdmin.graphql'
import promoteUserToDemoAdmin from '@/graphql/mutation/promoteUserToDemoAdmin.graphql'
import demoteUserFromDemoAdmin from '@/graphql/mutation/demoteUserFromDemoAdmin.graphql'
import activateSupportedLanguage from '@/graphql/mutation/activateSupportedLanguage.graphql'
import deactivateSupportedLanguage from '@/graphql/mutation/deactivateSupportedLanguage.graphql'
import updateAppUserPreferredLanguage from '@/graphql/mutation/updateAppUserPreferredLanguage.graphql'
import changeAppTenantType from '@/graphql/mutation/changeAppTenantType.graphql'
import upsertAppTenantGroup from '@/graphql/mutation/upsertAppTenantGroup.graphql'
import deleteAppTenantGroup from '@/graphql/mutation/deleteAppTenantGroup.graphql'
import promoteUserToAppTenantGroupAdmin from '@/graphql/mutation/promoteUserToAppTenantGroupAdmin.graphql'
import demoteUserFromAppTenantGroupAdmin from '@/graphql/mutation/demoteUserFromAppTenantGroupAdmin.graphql'
import addAppTenantGroupMember from '@/graphql/mutation/addAppTenantGroupMember.graphql'
import removeAppTenantGroupMember from '@/graphql/mutation/removeAppTenantGroupMember.graphql'
import setAppTenantTags from '@/graphql/mutation/setAppTenantTags.graphql'

const scrubPayload = async (payload) => omitDeep(payload, ['__typename'])

// const errHandler = (response) => {
//   if (response.errors.length > 0) {
//     alert('oh no, oh no, oh no no no no no')
//   }
//   return response
// }

// const types = {
//   CLONE_LICENSE_PACK: 'CLONE_LICENSE_PACK'
// }

const SLUG = 'SLUG'
const IMAGE = 'IMAGE'

const getDefaultState = () => {
  return {
    loadingKeys: {
      upsertLicensePackActive: false
    },
    licenseTypes: [],
    allAddOns: [],
    licensePackDetailTab: 0,
    entityAppState: [],
    refreshKey: 0,
    recentException: null,
    recordedExceptions: [],
    contentSlugDefinitions: {
      home: [
        {
          contentType: SLUG,
          entityType: 'APPROUTE',
          key: 'headline',
          description: 'top banner headline for home page',
          content: '<h1>This website rulez</h1>'
        },
        {
          contentType: SLUG,
          entityType: 'APPROUTE',
          key: 'trusted_platform',
          description: 'top banner headline for home page',
          content: '<h1>This website rulez</h1>'
        },
        {
          contentType: SLUG,
          entityType: 'APPROUTE',
          key: 'footer',
          description: 'footer content for home page',
          content: '<p>TBD</p>'
        }
      ],
      about: [
        {
          contentType: SLUG,
          entityType: 'APPROUTE',
          key: 'headline',
          description: 'top banner headline for about page',
          content: '<h1>This website rulez</h1>'
        },
        {
          contentType: SLUG,
          entityType: 'APPROUTE',
          key: 'footer',
          description: 'footer content for about page',
          content: '<p>TBD</p>'
        }
      ],
      LICENSEPACK: [
        {
          contentType: SLUG,
          entityType: 'LICENSEPACK',
          key: 'benefits_summary',
          description: 'content that will appear when hovering licensePack on the front page',
          content: '<ul><li>these are</li><li>the reasons</li><li>this licensePack is awesome</li></ul>'
        }
      ]
    },
    contentImageDefinitions: {
    // https://sharp.pixelplumbing.com/api-resize#parameters
    // note that options must contain height and width. server ONLY uses options:
    // const transform = sharp()
    // .resize(p.options)
    // .toFormat(p.format)
      home: [
        {
          contentType: IMAGE,
          entityType: 'APPROUTE',
          key: 'hero',
          description: 'the hero image for the home page',
          versionSpecs: [
            {
              key: 'small_png',
              options: {
                height: 100,
                width: 100
              },
              format: 'png'
            },
            {
              key: 'medium_png',
              options: {
                height: 150,
                width: 150
              },
              format: 'png'
            },
            {
              key: 'large_png',
              options: {
                height: 200,
                width: 200
              },
              format: 'png'
            },
            {
              key: 'small_webp',
              options: {
                height: 100,
                width: 100
              },
              format: 'webp'
            },
            {
              key: 'medium_webp',
              options: {
                height: 150,
                width: 150
              },
              format: 'webp'
            },
            {
              key: 'large_webp',
              options: {
                height: 200,
                width: 200
              },
              format: 'webp'
            }
          ]
        }
      ],
      LICENSEPACK: [
        {
          contentType: IMAGE,
          entityType: 'LICENSEPACK',
          key: 'hero',
          description: 'the hero image for this licensePack',
          versionSpecs: [
            {
              key: 'small_png',
              options: {
                height: 100,
                width: 100
              },
              format: 'png'
            },
            {
              key: 'medium_png',
              options: {
                height: 150,
                width: 150
              },
              format: 'png'
            },
            {
              key: 'large_png',
              options: {
                height: 200,
                width: 200
              },
              format: 'png'
            },
            {
              key: 'small_webp',
              options: {
                height: 100,
                width: 100
              },
              format: 'webp'
            },
            {
              key: 'medium_webp',
              options: {
                height: 150,
                width: 150
              },
              format: 'webp'
            },
            {
              key: 'large_webp',
              options: {
                height: 200,
                width: 200
              },
              format: 'webp'
            }
          ]
        }
      ]
    }
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  upsertLicensePackActive: state => state.upsertLicensePackActive,
  licenseTypes: state => state.licenseTypes,
  licensePackDetailTab: state => state.licensePackDetailTab,
  licensePackManagerAvailability: state => {
    const eas = state.entityAppState.find(eas => eas.entityId === 'LicensePackManager')
    return eas ? eas.availability : 'PUBLISHED'
  },
  licensePackManagerSubscriptionType: state => {
    const eas = state.entityAppState.find(eas => eas.entityId === 'LicensePackManager')
    return eas ? eas.subscriptionType : 'ANCHOR'
  }
}

// actions
const actions = {
  logout ({ commit }) {
    commit('resetApp', null)
  },
  cloneLicensePack: {
    root: true,
    handler: async (store, licensePackId) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: cloneLicensePack,
          variables: {
            id: licensePackId
          }
        })
        .then(result => {
          return result.data.cloneLicensePack.licensePack
        })
    }
  },
  publishLicensePack: {
    root: true,
    handler: async (store, payload) => {
      const discontinue = (payload.discontinueExisting || false)
      return apolloProvider.defaultClient
        .mutate({
          mutation: publishLicensePack,
          variables: {
            id: payload.licensePackId,
            discontinueExisting: discontinue
          }
        })
        .then(result => {
          return result.data.publishLicensePack.licensePack
        })
    }
  },
  discardLicensePack: {
    root: true,
    handler: async (store, licensePackId) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: discardLicensePack,
          variables: {
            id: licensePackId
          }
        })
        .then(result => {
          return result.data.discardLicensePack.licensePack
        })
    }
  },
  discontinueLicensePack: {
    root: true,
    handler: async (store, licensePackId) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: discontinueLicensePack,
          variables: {
            id: licensePackId
          }
        })
        .then(result => {
          return result.data.discontinueLicensePack.licensePack
        })
    }
  },
  upsertLicensePack: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertLicensePack,
          variables: cleanPayload
        })
        .then(result => {
          store.commit('refreshKey')
        })
    }
  },
  removeLicensePackLicenseType: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: removeLicensePackLicenseType,
          variables: cleanPayload
        })
        .then(result => {
          return result.data.removeLicensePackLicenseType
        })
    }
  },
  reactivateAppUser: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: reactivateAppUser,
          variables: cleanPayload
        })
    }
  },
  deactivateAppUser: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deactivateAppUser,
          variables: cleanPayload
        })
    }
  },
  removeAuth0User: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: removeAuth0User,
          variables: payload
        })
    }
  },
  subscribeToLicensePackNewTenant: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: subscribeToLicensePackNewTenant,
          variables: payload
        })
        .then(result => {
          router.push({ name: 'AppTenant', params: { appTenantId: result.data.subscribeToLicensePackNewTenant.appTenantSubscription.appTenantId } })
        })
    }
  },
  activateAppTenantSubscription: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: activateAppTenantSubscription,
          variables: cleanPayload
        })
        .then(result => {
          return result.data.activateAppTenantSubscription.appTenantSubscription
        })
    }
  },
  deactivateAppTenantSubscription: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deactivateAppTenantSubscription,
          variables: cleanPayload
        })
        .then(result => {
          return result.data.deactivateAppTenantSubscription.appTenantSubscription
        })
    }
  },
  deactivateAppTenantSubscriptions: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deactivateAppTenantSubscriptions,
          variables: cleanPayload
        })
        .then(result => {
          return result.data.deactivateAppTenantSubscriptions.appTenantSubscription
        })
    }
  },
  activateLicense: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: activateLicense,
          variables: cleanPayload
        })
        .then(result => {
          return result.data.activateLicense.license
        })
    }
  },
  deactivateLicense: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deactivateLicense,
          variables: cleanPayload
        })
        .then(result => {
          return result.data.deactivateLicense.license
        })
    }
  },
  deleteLicense: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteLicense,
          variables: cleanPayload
        })
        .then(result => {
          return result.data.deleteLicense
        })
    }
  },
  renewAppUserLicense: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: renewAppUserLicense,
          variables: cleanPayload
        })
    }
  },
  changeAppUserEmail: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: changeAppUserEmail,
          variables: cleanPayload
        })
    }
  },
  updateContactInfo: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: updateContactInfo,
          variables: cleanPayload
        })
    }
  },
  promoteUserToAdmin: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: promoteUserToAdmin,
          variables: cleanPayload
        })
    }
  },
  demoteUserFromAdmin: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: demoteUserFromAdmin,
          variables: cleanPayload
        })
    }
  },
  promoteUserToDemoAdmin: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: promoteUserToDemoAdmin,
          variables: cleanPayload
        })
    }
  },
  demoteUserFromDemoAdmin: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: demoteUserFromDemoAdmin,
          variables: cleanPayload
        })
    }
  },
  activateSupportedLanguage: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: activateSupportedLanguage,
          variables: cleanPayload
        })
    }
  },
  deactivateSupportedLanguage: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deactivateSupportedLanguage,
          variables: cleanPayload
        })
    }
  },
  changeAppTenantType: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: changeAppTenantType,
          variables: cleanPayload
        })
    }
  },
  upsertAppTenantGroup: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertAppTenantGroup,
          variables: cleanPayload
        })
    }
  },
  deleteAppTenantGroup: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteAppTenantGroup,
          variables: cleanPayload
        })
    }
  },
  promoteUserToAppTenantGroupAdmin: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: promoteUserToAppTenantGroupAdmin,
          variables: cleanPayload
        })
    }
  },
  demoteUserFromAppTenantGroupAdmin: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: demoteUserFromAppTenantGroupAdmin,
          variables: cleanPayload
        })
    }
  },
  addAppTenantGroupMember: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: addAppTenantGroupMember,
          variables: cleanPayload
        })
    }
  },
  removeAppTenantGroupMember: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: removeAppTenantGroupMember,
          variables: cleanPayload
        })
    }
  },
  setAppTenantTags: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: setAppTenantTags,
          variables: payload
        })
    }
  },
  updateAppUserPreferredLanguage: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: updateAppUserPreferredLanguage,
          variables: cleanPayload
        })
    }
  },
  updateAppTenantSetting: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: updateAppTenantSetting,
          variables: payload
        })
        .then(() => {
          store.commit('refreshKey')
        })
    }
  },
  upsertContentSlug: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertContentSlug,
          variables: payload
        })
        .then(() => {
          store.commit('refreshKey')
        })
    }
  },
  setContentSlugCurrentVersionSet: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: setContentSlugCurrentVersionSet,
          variables: payload
        })
        .then(() => {
          store.commit('refreshKey')
        })
    }
  },
  requestPricing: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: requestPricing,
          variables: payload
        })
        .then((result) => {
          return result.data.requestPricing.appUserAuth0Info
        })
    }
  },
  subscribeFromBrochure: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: subscribeFromBrochure,
          variables: payload
        })
        .then(() => {
          store.commit('refreshKey')
        })
    }
  },
  raiseException: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: raiseException,
          variables: payload,
          errorPolicy: 'ignore'
        })
    }
  },
  recordException: {
    root: true,
    handler: async (store, payload) => {
      const newMessage = payload.map(ne => ne.message)[0]
      const isAtRoot = window.location.pathname === '/'
      // console.log('recordException', isAtRoot, newMessage)
      if (!store.rootState.user.isLoggedIn && isAtRoot) { return }
      if (newMessage.indexOf('SESSION EXPIRED') > -1) {
        store.commit('setRecentException', 'LOGOUT')
      } else if (newMessage.indexOf('LICENSE EXPIRED') > -1) {
        store.commit('setRecentException', 'LICENSE_EXPIRED')
      } else if (newMessage.indexOf('app user already exists') > -1) {
        store.commit('setRecentException', 'USER_EXISTS')
      } else if (newMessage.indexOf('app_fn.subscribe_to_license_pack_new_tenant:::23505:::duplicate key value violates unique constraint') > -1) {
        store.commit('setRecentException', 'PREVIOUS_FREE_TRIAL')
      } else {
        store.commit('setRecentException', newMessage)
      }
    }
  },
  deleteExceptions: {
    root: true,
    handler: async (store, payload) => {
      apolloProvider.defaultClient
        .mutate({
          mutation: deleteAppErrors,
          variables: payload
        })
        .then(() => {
          store.commit('refreshKey')
        })
        .catch((e) => {
          console.log(e)
        })
    }
  },
  // clearExceptions: {
  //   root: true,
  //   handler: async (store, payload) => {
  //     store.commit('clearExceptions', payload)
  //   }
  // },
  clearRecentException: {
    root: true,
    handler: async (store, payload) => {
      store.commit('clearRecentException', payload)
    }
  },
  reportAppError: {
    root: true,
    handler: async (store, payload) => {
      const payloadToUse = {
        errorReportInfos: [
          {
            reportedByAppUserId: payload.reportedByAppUserId,
            reportedAsAppUserId: payload.reportedAsAppUserId,
            comment: payload.comment,
            operationName: payload.operationName,
            observedCount: payload.observedCount,
            variables: payload.variables,
            message: payload.message
          }
        ]
      }
      apolloProvider.defaultClient
        .mutate({
          mutation: reportAppErrors,
          variables: payloadToUse,
          errorPolicy: 'ignore'
        })
        .then(() => {
          const others = store.state.recordedExceptions.filter(re => re.message !== payload.message)
          const current = store.state.recordedExceptions.find(re => re.message === payload.message)
          store.commit('setRecordedExceptions', [...others, {
            ...current,
            observedCount: 0
          }])
          store.commit('refreshKey')
        })
    }
  // },
  // reportAppErrors: {
  //   root: true,
  //   handler: async (store, payload) => {
  //     const payloadToUse = {
  //       errorReportInfos: payload.map(e => {
  //         return {
  //           reportedByAppUserId: e.reportedByAppUserId,
  //           reportedAsAppUserId: e.reportedAsAppUserId,
  //           comment: e.comment,
  //           operationName: e.operationName,
  //           observedCount: e.observedCount,
  //           variables: e.variables,
  //           message: e.message
  //         }
  //       })
  //     }
  //     return apolloProvider.defaultClient
  //       .mutate({
  //         mutation: reportAppErrors,
  //         variables: payloadToUse,
  //         errorPolicy: 'ignore'
  //       })
  //   }
  },
  removeAppTenant: {
    root: true,
    handler: async (store, payload) => {
      apolloProvider.defaultClient
        .mutate({
          mutation: removeAppTenant,
          variables: payload
        })
        .then(() => {
          router.push({ name: 'AppTenantManager' })
        })
    }
  },
  removeAppUser: {
    root: true,
    handler: async (store, payload) => {
      apolloProvider.defaultClient
        .mutate({
          mutation: removeAppUser,
          variables: payload
        })
        .then(() => {
          router.push({ name: 'AppTenantAppUsers' })
        })
    }
  },
  removePatient: {
    root: true,
    handler: async (store, payload) => {
      apolloProvider.defaultClient
        .mutate({
          mutation: removeAppUser,
          variables: payload
        })
        .then(() => {
          router.push({ name: 'ClinicDashboard' })
        })
    }
  },
  createSubsidiary: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: createSubsidiary,
          variables: payload
        })
        .then((result) => {
          router.push({ name: 'AppTenant', params: { appTenantId: result.data.createSubsidiary.appTenant.id } })
        })
    }
  },
  contactFromBrochure: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: contactFromBrochure,
          variables: payload
        })
    }
  },
  sendEmail: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: sendEmail,
          variables: payload
        })
    }
  },
  queueWorkflow: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: queueWorkflow,
          variables: payload
        })
    }
  },
  setAppEula: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: setAppEula,
          variables: payload
        })
    }
  },
  linkOldCustomerToAppTenant: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: linkOldCustomerToAppTenant,
          variables: payload
        })
    }
  },
  linkOldUserToAppUser: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: linkOldUserToAppUser,
          variables: payload
        })
        .then(result => {
          router.push({
            name: 'PatientTherapyDashboard',
            params: { patientId: result.data.linkOldUserToAppUser.oldUser.appUser.patient.id }
          })
        })
    }
  },
  unlinkOldUserToAppUser: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: unlinkOldUserToAppUser,
          variables: payload
        })
    }
  },
  upsertHelpTopic: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertHelpTopic,
          variables: payload
        })
        .then(result => {
          router.push({ name: 'HelpTopicManagerDetail', params: { helpTopicId: result.data.upsertHelpTopic.helpTopic.id } })
        })
    }
  },
  deleteHelpTopic: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteHelpTopic,
          variables: payload
        })
    }
  },
  updateLicenseExpiration: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: updateLicenseExpiration,
          variables: payload
        })
    }
  },
  updateAppTenantName: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: updateAppTenantName,
          variables: payload
        })
    }
  },
  consolidateAppTenantSubscription: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: consolidateAppTenantSubscription,
          variables: payload
        })
    }
  },
  moveLicensesToSubscription: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: moveLicensesToSubscription,
          variables: payload
        })
    }
  },
  changeLicenseType: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: changeLicenseType,
          variables: payload
        })
    }
  },
  voidLicenses: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: voidLicenses,
          variables: payload
        })
    }
  },
  unvoidLicenses: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: unvoidLicenses,
          variables: payload
        })
    }
  },
  addressErrorReport: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: addressErrorReport,
          variables: payload
        })
    }
  }
}

// mutations
const mutations = {
  resetApp (state) {
    Object.assign(state, getDefaultState())
  },
  refreshKey (state) {
    state.refreshKey = state.refreshKey + 1
  },
  setRecentException (state, payload) {
    state.recentException = payload
  },
  clearRecentException (state) {
    state.recentException = null
  },
  setLoadingKey (state, payload) {
    state.loadingKeys[payload.key] = payload.active
  },
  setLicenseTypes (state, payload) {
    state.licenseTypes = payload
  },
  setAllAddOns (state, payload) {
    state.allAddOns = payload
  },
  setLicensePackDetailTab (state, payload) {
    state.licensePackDetailTab = payload
  },
  setEntityAppState (state, payload) {
    const entity = state.entityAppState.find(eas => eas.entityId === payload.entityId) || payload
    const others = state.entityAppState.filter(eas => eas.entityId !== payload.entityId)

    state.entityAppState = [
      ...others,
      {
        ...entity,
        ...payload
      }
    ]
  },
  setRecordedExceptions (state, payload) {
    state.recordedExceptions = payload
  },
  clearExceptions (state, payload) {
    const payloadMessages = payload.map(e => e.message)
    state.recordedExceptions = state.recordedExceptions.filter(re => payloadMessages.indexOf(re.message) === -1)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
