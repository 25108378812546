<template>
 <div
  class="text-xl"
 >
  ...redirecting to home page
 </div>
</template>

<script>
import currentAppUser from '@/graphql/query/currentAppUser.graphql'

export default {
  name: 'UserHomeRedirect',
  apollo: {
    query: currentAppUser,
    update: (data) => {
      const userInfo = data.currentAppUser
      this.$store.dispatch('user/setUser', userInfo)
        .then(() => {
          this.$router.push({ name: userInfo.homePath })
        })
    }
  }
}
</script>

<style scoped>

</style>
