import gql from 'graphql-tag'

const mutation = gql`mutation upsertAppUserDevice ($isClinicDevice: Boolean, $name: String, $userAgent: String!) {
    upsertAppUserDevice(
        input: { _isClinicDevice: $isClinicDevice, _name: $name, _userAgent: $userAgent }
    ) {
        query {
            clinicDevices {
                nodes {
                    id
                    isClinicDevice
                    name
                }
            }
            appUserDevices {
                nodes {
                    id
                    isClinicDevice
                    name
                }
            }
        }
    }
}
`
export default mutation
