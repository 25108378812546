<script>
export default {
  name: 'SystemRequirements',
  data () {
    return {
    }
  }
}
</script>

<template>
  <aside
    class="system-requirements"
  >
    <slot name="header"></slot>
    <div class="requirement-grid mb-4">
      <div>
        <h5 class="is-size-5">Supported Browsers</h5>
        <ul class="list-discs">
          <li>Firefox 102+</li>
          <li>Google Chrome 80+</li>
          <li>Microsoft Edge 80+</li>
          <li>Safari 14.1+</li>
          <li>Other browsers that use the Chrome browser engine (Chromium), such as Vivaldi and Opera, providing they are updated to Chromium 80+</li>
        </ul>
      </div>
      <div>
        <h5 class="is-size-5">Supported operating systems</h5>
        <ul class="list-discs">
          <li>Windows 10, 11 or newer</li>
          <li>Mac: OS X / macOS 10.13 (High Sierra) or newer</li>
          <li>Linux: 64-bit Ubuntu 18.04+, Debian 10+, openSUSE 15.2+, or Fedora Linux 32+</li>
          <li>Chrome OS: Any Chrome OS device updated to v80+</li>
          <li>Android: Any Android 7.0+ device with at least 1GB RAM</li>
          <li>iOS: Any iOS 14.1+ device</li>
          <li>iPadOS: Any iPadOS 14.1+ device</li>
        </ul>
      </div>
    </div>
    <div class="">
      <h5 class="is-size-6 is-family-semibold">WebGL required</h5>
      OVB activities require your browser to support WebGL. Nearly all modern devices support WebGL. If you see a message about WebGL not being supported, try installing any available system updates and check your graphics drivers are up-to-date.
    </div>
  </aside>
</template>

<style lang="scss" scoped>
  .system-requirements {
    font-size: 1rem;
    .requirement-grid {
      display: grid;
      //grid-template-columns: repeat( auto-fill, minmax(20rem, 1fr));
      grid-template-columns: 1fr;
      grid-gap: 1rem;
      @include tablet {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  .webgl-message {
    padding: 1rem;
    background-color: $gray-lighter;
    border-radius: 0.5rem;
  }
</style>
