var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
    callback: _vm.visibilityChanged,
    throttle: 300,
  }),expression:"{\n    callback: visibilityChanged,\n    throttle: 300,\n  }"}],staticClass:"testimonials-block"},[_c('div',{staticClass:"image-container",style:({
            backgroundImage: `url(${_vm.backgroundImage})`,
          })}),_vm._v(" "),_c('div',{staticClass:"image-overlay"}),_vm._v(" "),_c('div',{staticClass:"container"},[_c('div',{staticClass:"testimonials-container"},[_c('div',{staticClass:"testimonials-row"},[_c('div',{ref:"testimonial0",staticClass:"testimonial",attrs:{"id":"testimonial0"}},[_vm._m(0)]),_vm._v(" "),_c('div',{ref:"testimonial1",staticClass:"testimonial",attrs:{"id":"testimonial1"}},[_vm._m(1)]),_vm._v(" "),_c('div',{ref:"testimonial2",staticClass:"testimonial",attrs:{"id":"testimonial2"}},[_vm._m(2)]),_vm._v(" "),_c('div',{ref:"testimonial3",staticClass:"testimonial",attrs:{"id":"testimonial3"}},[_vm._m(3)]),_vm._v(" "),_c('div',{ref:"testimonial4",staticClass:"testimonial",attrs:{"id":"testimonial4"}},[_vm._m(4)]),_vm._v(" "),_c('div',{ref:"testimonial5",staticClass:"testimonial",attrs:{"id":"testimonial5"}},[_vm._m(5)]),_vm._v(" "),_c('div',{ref:"testimonial6",staticClass:"testimonial",attrs:{"id":"testimonial6"}},[_vm._m(6)]),_vm._v(" "),_c('div',{ref:"testimonial7",staticClass:"testimonial",attrs:{"id":"testimonial7"}},[_vm._m(7)]),_vm._v(" "),_c('div',{ref:"testimonial8",staticClass:"testimonial",attrs:{"id":"testimonial8"}},[_vm._m(8)]),_vm._v(" "),_c('div',{ref:"testimonial9",staticClass:"testimonial",attrs:{"id":"testimonial9"}},[_vm._m(9)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-body is-size-5-desktop"},[_vm._v("\n            “Great tool in these changing times to allow the doctor to not only program and adjust the assigned vision therapy activities but to also monitor the patient’s compliance and progress with the activities. I also like the variety of activities and that the platform is always upgrading & adding more activities.”\n            "),_c('div',{staticClass:"testimonial-author is-size-5-desktop"},[_vm._v("Dr. Mary Van Hoy, OD"),_c('br'),_vm._v("Eyes for Wellness")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-body is-size-5-desktop"},[_vm._v("\n            “This has made VT home activities for kids and adults much easier! The games are very fun, bright, and engaging, and work on all of the skills we need them to. Our patients love it and so do we!”\n            "),_c('div',{staticClass:"testimonial-author is-size-5-desktop"},[_vm._v("\n              Elizabeth Markley, VT"),_c('br'),_vm._v("Katmai Eye\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-body is-size-5-desktop"},[_vm._v("\n            “OVB has truly made our vision therapy patients more involved with their progress and it has made their VT experience a lot more fun.”\n            "),_c('div',{staticClass:"testimonial-author is-size-5-desktop"},[_vm._v("\n              Melanie Todd, COVT"),_c('br'),_vm._v("Triangle Visions\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-body is-size-5-desktop"},[_vm._v("\n            “Our Visual Brain has been a great value added in our clinics. The games are fun, engaging and really get results! Their team is also very open and responsive to feedback and suggestions - making their platform a solid long-term addition to our programs.”\n            "),_c('div',{staticClass:"testimonial-author is-size-5-desktop"},[_vm._v("\n              Dr. Paul Rollett, OD\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-body is-size-5-desktop"},[_vm._v("\n            “As a vision therapist, I'm very grateful that Our Visual Brain exists! The games are fun and engaging for patients, and I appreciate that the website is frequently updated so there is always something new to try. I have also emailed their team directly more than once to ask about technical questions, and their response time is fantastic.”\n            "),_c('div',{staticClass:"testimonial-author is-size-5-desktop"},[_vm._v("\n              Lorelei Feeny, COVT"),_c('br'),_vm._v("Triangle Visions\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-body is-size-5-desktop"},[_vm._v("\n            “We have been using OVB for several years at our clinic. It is easy to use for patients and simple for them. The simplicity, game-based nature, and fair price of the therapy activities make it a no-brainer for patients. OVB has amazing customer loyalty and they are open to suggestions and changes. I can't wait for the future of OVB, I would recommend it to any clinic.”\n            "),_c('div',{staticClass:"testimonial-author is-size-5-desktop"},[_vm._v("\n              Dr. Steven Blake, OD\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-body is-size-5-desktop"},[_vm._v("\n            “OVB is a powerful vision therapy tool that engages patients of all ages. Using OVB as a homework tool has increased home activity adherence and improved our patient outcomes.”\n            "),_c('div',{staticClass:"testimonial-author is-size-5-desktop"},[_vm._v("\n              Sandi Pollock, COVT"),_c('br'),_vm._v("Triangle Visions\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-body is-size-5-desktop"},[_vm._v("\n            “I have been a fan of Jennifer Powell's work since she started her original FAT CAT home vision therapy products to help doctors and therapists better serve their patients.  Her updated, and fully customizable online \"Our Visual Brain\" vision therapy platform is easy to use, monitors progress,  and provides a great tool for doctors and therapists to plan at home vision skill practice.  All activities can be appropriately individualized to meet the vision needs of the user.   Be ready for loads of fun while training visual perceptual skills, because that's what Our Visual Brain provides. Truly amazing!”\n            "),_c('div',{staticClass:"testimonial-author is-size-5-desktop"},[_vm._v("\n              Patricia Andrich"),_c('br'),_vm._v("Triangle Visions\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-body is-size-5-desktop"},[_vm._v("\n            “We first met Jennifer Powell at our annual OEPF SW Congress last January and were so impressed with the quality of activities in the Our Visual Brain program.  Since adding OVB to our practice shortly thereafter, we have been increasingly happy with our decision to do so.  Jennifer is so responsive to our questions and patient needs, and our patients of all ages, enjoy the activities.  It has become a vital part of our treatment, especially for our patients with strabismus and amblyopia, who can now utilize a wide range of red/green and MFBF activities on a daily basis.  We highly recommend OVB for any and all VT practices.”\n            "),_c('div',{staticClass:"testimonial-author is-size-5-desktop"},[_vm._v("\n              Vision Therapy of San Antonio\n            ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"testimonial-body is-size-5-desktop"},[_vm._v("\n            “As a Neuro-optometrist, I’m really impressed with OVB and can’t say enough about how much this will help my practice. Keeps patient’s interested and parents in the loop. Customer service is excellent and activities are engaging and relevant. Love love love.”\n            "),_c('div',{staticClass:"testimonial-author is-size-5-desktop"},[_vm._v("\n              Annie Reuter\n            ")])])
}]

export { render, staticRenderFns }