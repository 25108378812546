<script>
import clinicDevices from '@/graphql/query/clinicDevices'
import appUserDevices from '@/graphql/query/appUserDevices'
import { mapGetters } from 'vuex'
import upsertAppUserDevice from '@/graphql/mutation/upsertAppUserDevice.js'
import deleteActivityDevice from '@/graphql/mutation/deleteActivityDevice'
export default {
  name: 'DeviceSelector',
  props: {
    resetNameKey: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      devicesLoading: 0,
      savingDevice: false,
      saveAndSet: false,
      deviceForRemoval: null,
      removingDevice: false,
      selectedDevice: null,
      isClinicDevice: false,
      allDevices: [],
      devicesById () {
        return []
      },
      deviceListOptions: [],
      deviceList: [],
      activeTab: 0,
      newDeviceName: null,
      userAgent: navigator.userAgent
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo',
      licenseTypeKey: 'user/licenseTypeKey',
      actualAppUserInfo: 'user/actualAppUserInfo',
      isHomePatient: 'user/isHomePatient'
    }),
    isClinic () {
      return this.actualAppUserInfo !== null
    },
    canSelectDevice () {
      return this.deviceListOptions ? (this.deviceListOptions.length > 0) : false
    },
    isLoading () {
      return (this.devicesLoading > 0)
    },
    buttonsDisabled () {
      return this.savingDevice || !this.newDeviceName || this.duplicateNameError
    },
    duplicateNameError () {
      return this.newDeviceName ? this.allDeviceNames.includes(this.newDeviceName.toLowerCase()) : false
    },
    allDeviceNames () {
      return this.deviceListOptions.map(o => {
        return o.name.toLowerCase()
      })
    },
    deviceInputLabel () {
      return this.isClinic ? 'Clinic Devices:' : 'Devices:'
    }
  },
  watch: {
    resetNameKey (val) {
      this.newDeviceName = ''
      this.activeTab = this.deviceListOptions.length === 0 ? 1 : 0
    }
  },
  methods: {
    getIsClinic () {
      let clinic
      if (this.$route.name !== 'PatientDashboard') {
        clinic = true
      } else {
        // console.log('this.$store.state.user.isHomePatient: ', this.$store.state.user.isHomePatient)
        clinic = !(this.$store.state.user.userInfo.homePath === 'PatientDashboard')
      }
      return clinic
    },
    buildDeviceOptions (data) {
      return data.map(o => {
        return {
          id: o.id,
          name: o.name,
          isClinicDevice: o.isClinicDevice
        }
      })
    },
    saveAndSetDevice () {
      // flag to set after save
      this.saveAndSet = true
      this.saveDevice()
    },
    saveDevice () {
      this.savingDevice = true
      const tempName = this.newDeviceName
      this.$apollo.mutate({
        mutation: upsertAppUserDevice,
        variables: {
          name: this.newDeviceName,
          isClinicDevice: this.isClinicDevice,
          userAgent: this.userAgent
        }
      })
        .then(result => {
          this.newDeviceName = ''
          this.savingDevice = false
          const tgtDataLabel = this.isClinic ? 'clinicDevices' : 'appUserDevices'
          this.deviceListOptions = this.buildDeviceOptions(result.data.upsertAppUserDevice.query[tgtDataLabel].nodes)
          // set device
          this.$buefy.toast.open({
            message: `Device "${tempName}" has been added to your devices`,
            type: 'is-success',
            duration: 4000
          })
          // get the new device from the returned list by matching the name and set the new device
          if (this.saveAndSet) {
            this.selectedDevice = this.getDeviceByName(tempName)
            this.setDevice()
          }
        })
        .catch(error => {
          this.savingDevice = false
          console.log('ERROR SAVING DEVICE', error)
        })
    },
    removeDevice () {
      this.removingDevice = true
      const tempName = this.deviceForRemoval.name
      this.$apollo.mutate({
        mutation: deleteActivityDevice,
        variables: {
          activityDeviceId: this.deviceForRemoval.id
        }
      })
        .then(result => {
          this.removingDevice = false
          this.deviceForRemoval = null
          const tgtDataLabel = this.isClinic ? 'clinicDevices' : 'appUserDevices'
          this.deviceListOptions = this.buildDeviceOptions(result.data.deleteActivityDevice.query[tgtDataLabel].nodes)
          // set device
          this.$buefy.toast.open({
            message: `Device <span class="is-family-semibold">${tempName}"</span> has been removed`,
            type: 'is-success',
            duration: 4000
          })
        })
        .catch(error => {
          this.savingDevice = false
          console.log('ERROR SAVING DEVICE', error)
        })
    },
    getDeviceByName (deviceName) {
      return this.deviceListOptions.find(o => o.name === deviceName)
    },
    setDevice () {
      // save device as persistent cookie
      const expirationDate = new Date()
      let cookieString = ''
      expirationDate.setFullYear(expirationDate.getFullYear() + 1)
      const deviceString = `ovbDeviceId=${this.selectedDevice.id};`
      cookieString = deviceString + 'samesite=strict, Secure; path=/; expires=' + expirationDate.toUTCString()
      document.cookie = cookieString
      // set device in vuex users/setDevice
      this.$store.dispatch('user/setUserDevice', this.selectedDevice)
      this.$emit('CLOSE_MODAL')
      this.$buefy.toast.open({
        message: `Your device has been set to ${this.selectedDevice.name}`,
        type: 'is-success',
        duration: 4000
      })
    },
    getSetDevice (deviceList) {
      // get value from cookie
      const deviceId = document.cookie
        .split('; ')
        .find((row) => row.startsWith('ovbDeviceId='))
        ?.split('=')[1]
      // if cookie exists and value exists
      // find the device in this.deviceListOptions
      const thisDevice = deviceList.find(o => o.id === deviceId)
      // and set the device in vuex
      this.$store.dispatch('user/setUserDevice', thisDevice)
    },
    initDeviceSelector (data) {
      this.deviceListOptions = this.buildDeviceOptions(data)
      this.getSetDevice(this.deviceListOptions)
    }
  },
  beforeMount () {
    this.isClinicDevice = this.isClinic
  },
  mounted () {
    // this.resetDeviceName(this.defaultName)
    setTimeout(() => {
      if (this.deviceListOptions.length === 0) this.activeTab = 1
    }, 500)
  },
  // created () {
  //   console.log(this.$store.state.user.userInfo.appUserId)
  // },
  apollo: {
    clinicDevices: {
      query: clinicDevices,
      loadingKey: 'devicesLoading',
      fetchPolicy: 'network-only',
      variables () {
        return {
          appUserId: this.userInfo.appUserId
        }
      },
      skip () {
        // NEED TO ADD CRITERIA USING COOKIE IF WE CAN
        // if (this.$route.name === 'AuthenticatedRedirect') return true
        const shouldSkip = this.$store.state.user.actualAppUserInfo === null || !this.$store.state.user.userInfo.appUserId || this.$route.name === 'AuthenticatedRedirect'
        return shouldSkip
      },
      update (data) {
        // console.log('!!!!!!!clinicDevices')
        this.initDeviceSelector(data.clinicDevices.nodes)
      }
    },
    appUserDevices: {
      query: appUserDevices,
      loadingKey: 'devicesLoading',
      fetchPolicy: 'network-only',
      variables () {
        return {
          appUserId: this.userInfo.appUserId
        }
      },
      skip () {
        const shouldSkip = this.$store.state.user.actualAppUserInfo !== null || !this.$store.state.user.userInfo.appUserId
        return shouldSkip
      },
      update (data) {
        // console.log('!!!!!!appUserDevices')
        this.initDeviceSelector(data.appUserDevices.nodes)
      }
    }
  }
}
</script>

<template>
  <div class="device-selector">
    <b-tabs v-model="activeTab">
      <b-tab-item
        label="Select Your Device"
        :disabled="!canSelectDevice"
        class="tab-select-device"
      >
        <div class="mb-3">
          Select the device from the dropdown list below.<br>
          If your device is not in the list please <a @click="activeTab = 1">Add a New Device</a>.
        </div>
        <div class="field">
          <label>{{ deviceInputLabel }}</label>
          <p class="control">
            <multiselect
              v-model="selectedDevice"
              :options="deviceListOptions"
              :loading="isLoading"
              :searchable="false"
              :max-height="250"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Select your device"
              deselect-label="Can't remove this value"
              track-by="id"
              label="name"
              :allow-empty="true"
            >
              <template
                v-slot:singleLabel="{ option }"
              >
        <span
          class="option-label"
        >{{ option.name }}</span>
              </template>
            </multiselect>
          </p>
        </div>
        <div class="buttons is-right">
          <button
            @click="setDevice"
            class="button is-primary"
            :disabled="!selectedDevice"
          >
            Set as your current Device
          </button>
        </div>
      </b-tab-item>

      <b-tab-item label="Add a New Device">
        <div class="is-family-semibold is-size-5">
          Please give your device an identifiable nickname.
        </div>
        <div
          class="is-size-6"
        >
          We will use this nickname to save color profiles<sup>*</sup> for your device's screen.
        </div>
        <div class="field mt-4">
          <label
            :class="{'has-text-danger': duplicateNameError}"
          >
            Nickname:
          </label>
          <p
            class="control"
            :class="{'has-icons-right': duplicateNameError}"
          >
            <input
              :class="{ 'is-danger': duplicateNameError}"
              class="input"
              v-model.trim="newDeviceName"
              placeholder="Enter a nickname"
            />
            <span
              v-if="duplicateNameError"
              class="icon is-small is-right"
            >
              <i class="mdi mdi-24px mdi-alert has-text-danger"></i>
            </span>
          </p>
          <p v-if="duplicateNameError" class="has-text-danger mt-2">
            This device nickname is already taken
          </p>
        </div>
        <template
          v-if="isClinic"
        >
          <div class="field">
            <p class="control">
              <b-checkbox v-model="isClinicDevice">This is an in-clinic device</b-checkbox>
            </p>
          </div>
        </template>
        <div class="buttons mt-6">
          <button
            @click="saveDevice"
            class="button is-primary is-outlined"
            :class="{'is-loading': this.savingDevice}"
            :disabled="buttonsDisabled"
          ><span class="is-size-4 is-family-semibold mr-1">+</span>Add a Device
          </button>
          <span class="mr-2">OR</span>
          <button
            @click="saveAndSetDevice"
            class="button is-primary"
            :disabled="buttonsDisabled"
            :class="{'is-loading': this.savingDevice}"
          ><span class="is-size-4 is-family-semibold mr-1">+</span>Add and Select this Device
          </button>
        </div>
        <div
          class="is-size-7 has-text-tangerine"
        >
          <sup class="">*</sup>Some device screens will show colors less accurately than others, making calibration necessary on each device you are using during therapy.
        </div>
      </b-tab-item>

      <b-tab-item
        label="Remove a Device"
        :disabled="!canSelectDevice"
        class="tab-select-device"
      >
        <div class="mb-3">
          Select the device to remove from the dropdown list below.<br>
          <span class="has-text-danger is-family-semibold">Removing this device will also remove any calibrations associated with the device.</span>
        </div>
        <div class="field">
          <label>{{ deviceInputLabel }}</label>
          <p class="control">
            <multiselect
              v-model="deviceForRemoval"
              :options="deviceListOptions"
              :loading="isLoading"
              :searchable="false"
              :max-height="250"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Select your device"
              deselect-label="Can't remove this device"
              track-by="id"
              label="name"
              :allow-empty="true"
            >
              <template
                v-slot:singleLabel="{ option }"
              >
        <span
          class="option-label"
        >{{ option.name }}</span>
              </template>
            </multiselect>
          </p>
        </div>
        <div class="buttons is-right">
          <button
            @click="removeDevice"
            class="button is-danger"
            :disabled="!deviceForRemoval"
          >
            Remove this Device
          </button>
        </div>
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<style lang="scss" scoped>
  .device-selector {
    .tab-select-device {
      min-height: 22rem;
    }
  }
</style>
