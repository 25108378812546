<template>
 <div
  class="text-xl"
 >
  Logging out...
 </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'PreLogout',
  methods: {
    logout () {
      this.$buefy.dialog.alert({
        message: 'Your session has expired.  Please login again to continue.',
        trapFocus: true,
        onConfirm: () => {
          this.$store.dispatch('clearRecentException')
          this.$store.dispatch('brochure/setGuestUser', null)
          this.$store.dispatch('user/logout')
          this.$store.dispatch('app/logout')
          axios.get('/post-logout')
          this.$store.dispatch('RESET_OVB')
            .then(() => {
              this.$router.push({ name: 'PreLogout' })
            })
        }
      })
    }
  },
  mounted () {
    this.logout()
  }
}
</script>

<style scoped>

</style>
