<script>
const htmlEl = document.documentElement
export default {
  name: 'BaseModal',
  props: {
    name: {
      type: String,
      default: 'base-modal'
    },
    show: {
      type: Boolean,
      default: false
    },
    // modal type refers to the Bulma modal types: image or card.  Card is default
    modalType: {
      type: String,
      default: 'card'
    },
    modalStyle: {
      type: String,
      default: 'info'
    },
    customClass: {
      type: String,
      default: ''
    },
    customStyles: {
      type: String,
      default: ''
    },
    modalId: {
      type: String,
      default: null
    },
    closeOnMaskClick: {
      type: Boolean,
      default: true
    },
    hasCloseButton: {
      type: Boolean,
      default: false
    },
    hasHeader: {
      type: Boolean,
      default: true
    },
    hasFooter: {
      type: Boolean,
      default: true
    },
    hasBodyPadding: {
      type: Boolean,
      default: true
    },
    isFlexColumn: {
      type: Boolean,
      default: false
    },
    fullScreen: {
      type: Boolean,
      default: false
    },
    contentAreaCustomStyles: {
      type: String,
      default: ''
    }
  },
  data: function () {
    return {
      title: '',
      body: ''
    }
  },
  computed: {
    headerStyle () {
      return this.modalStyle.toLowerCase()
    }
  },
  watch: {
    show (val) {
      if (val) {
        document.addEventListener('keydown', this.onEscapeKey)
        htmlEl.classList.add('modal-open')
      } else {
        this.$refs.modalBody.scrollTop = 0
        setTimeout(() => { htmlEl.classList.remove('modal-open') }, 300)
        document.removeEventListener('keydown', this.onEscapeKey)
      }
    }
  },
  methods: {
    onEscapeKey (e) {
      if (e.keyCode === 27) {
        this.close()
        document.removeEventListener('keydown', this.onEscapeKey)
      }
    },
    close: function () {
      this.$emit('CLOSE_MODAL')
      this.title = ''
      this.body = ''
    },
    onMaskClick () {
      if (this.closeOnMaskClick) this.close()
    }
  },
  beforeDestroy () {
    if (this.$refs.modalBody) this.$refs.modalBody.scrollTop = 0
    setTimeout(() => {
      htmlEl.classList.remove('modal-open')
    }, 300)
    document.removeEventListener('keydown', this.onEscapeKey)
  }
}
</script>

<template>
  <MountingPortal
    mount-to="#modal-portal"
    append
    name="modal"
  >
    <transition
      name="base-modal"
    >
      <template
        v-if="modalType.toLowerCase() === 'card'"
      >
        <div
          class="modal-mask base-modal card-modal"
          :class="{modalId: modalId, 'is-full-screen': fullScreen}"
          @click="onMaskClick"
          v-show="show"
        >
          <div
            class="modal-container"
            :class="customClass"
            :style="customStyles"
            @click.stop
          >
            <header
              v-if="hasHeader"
              class="modal-header"
              :class="{'is-success': headerStyle === 'success', 'is-danger': headerStyle === 'danger'}"
            >
              <slot
                name="header"
              >
                header slot
              </slot>
              <button
                v-if="hasCloseButton"
                @click="close"
                class="delete"
              />
            </header>
            <div
              id="modalBody"
              ref="modalBody"
              class="modal-body"
              :style="contentAreaCustomStyles"
              :class="{'has-padding': hasBodyPadding, 'is-flex-column': isFlexColumn}"
            >
              <slot
                name="content"
              >
                content slot
              </slot>
            </div>
            <footer
              v-if="hasFooter"
              class="modal-footer"
            >
              <slot
                name="footer"
              >
                footer slot
              </slot>
            </footer>
          </div>
        </div>
      </template>
      <template
        v-if="modalType.toLowerCase() === 'image'"
      >
        <div
          class="modal-mask base-modal image-modal"
          :class="modalId"
          @click="onMaskClick"
          v-show="show"
        >
          <div
            class="modal-container"
            :style="customStyles"
            @click.stop
          >
            <div
              ref="modalBody"
              class="modal-body"
            >
              <slot
                name="content"
              >
                content slot
              </slot>
            </div>
          </div>
          <button
            @click="onMaskClick"
            class="modal-close is-large"
            aria-label="close" />
        </div>
      </template>
    </transition>
  </MountingPortal>
</template>

<style lang="scss" scoped />
