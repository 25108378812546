const types = {
  SET_SPROUTVIDEO_LIST: 'SET_SPROUTVIDEO_LIST',
  SET_SPROUTVIDEO_TAGS: 'SET_SPROUTVIDEO_TAGS'
}

const getDefaultState = () => {
  return {
    videoList: [],
    tagList: []
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  videoList: state => state.videoList,
  tagList: state => state.tagList
}

// actions
const actions = {
  setVideoList ({ commit }, payload) {
    commit(types.SET_SPROUTVIDEO_LIST, payload)
  },
  setTagList ({ commit }, payload) {
    commit(types.SET_SPROUTVIDEO_TAGS, payload)
  }
}

// mutations
const mutations = {
  [types.SET_SPROUTVIDEO_LIST] (state, payload) {
    state.videoList = payload
  },
  [types.SET_SPROUTVIDEO_TAGS] (state, payload) {
    state.tagList = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
