import store from '@/store'

export const authGuard = (to, from, next) => {
  const fn = () => {
    if (store.state.user.isLoggedIn) {
      if (to.meta && to.meta.needsPermission) {
        store.dispatch('USER_HAS_PERMISSION', to.meta.needsPermission)
          .then(hasPermission => {
            if (!hasPermission) {
              return next({ name: store.state.user.userInfo.homePath })
            } else {
              return next()
            }
          })
      }
      return next()
      // if (store.state.user.forceSignEula && to.name !== 'SignAppEula') {
      //   return next({ name: 'SignAppEula' })
      // } else {
      //   return next()
      // }
    }

    window.location = '/login'
    // authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  return fn()
}

// import { getInstance } from './authWrapper'
// import store from '@/store'

// export const authGuard = (to, from, next) => {
//   const authService = getInstance()
//   const fn = () => {
//     if (authService.isAuthenticated) {
//       if (to.meta && to.meta.needsPermission) {
//         store.dispatch('USER_HAS_PERMISSION', to.meta.needsPermission)
//           .then(hasPermission => {
//             if (!hasPermission) {
//               return next({ name: store.state.user.userInfo.homePath })
//             } else {
//               return next()
//             }
//           })
//       }
//       return next()
//       // if (store.state.user.forceSignEula && to.name !== 'SignAppEula') {
//       //   return next({ name: 'SignAppEula' })
//       // } else {
//       //   return next()
//       // }
//     }

//     authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
//   }

//   // // this is an attempt to stop the screen flash of the landing page on redirect
//   // if (from.name === null) {
//   //   return fn()
//   // }

//   if (!authService.loading) {
//     return fn()
//   }

//   authService.$watch('loading', loading => {
//     if (loading === false) {
//       return fn()
//     }
//   })
// }
