<template>
 <div
  class="text-xl"
 >
  Logging out...
 </div>
</template>

<script>

export default {
  name: 'PreLogout',
  methods: {
  },
  mounted () {
    window.location = '/logout'
  }
}
</script>

<style scoped>

</style>
