<template>
  <div>
    <div
      class="placeholder-page bg-white flex-grow"
    >
      Placeholder page
    </div>
    <div class="content">
      Text to test scrolling on mobile and desktop:

      <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum mollis ultricies vestibulum. Donec pharetra dolor non condimentum lacinia. Nulla facilisi. Sed ullamcorper lacus vitae neque sodales placerat. Suspendisse potenti. Nulla vel sollicitudin lorem. Nullam viverra tortor quam, ut facilisis mi sollicitudin eu. Curabitur nec varius magna, sit amet sodales odio. Proin eu nisl gravida, eleifend orci nec, viverra nunc. Quisque laoreet porta lorem, at porttitor neque scelerisque sit amet. Sed finibus posuere lorem, vel sollicitudin erat porta in. Nullam nec dictum ex. Nullam interdum consequat sagittis. Quisque sit amet tincidunt quam. Curabitur vitae fringilla nisl. Mauris a neque dui.</p>

      <p>Maecenas non rhoncus urna. Donec faucibus orci a tortor dictum, vitae blandit ipsum vulputate. Sed venenatis ultricies lorem, sed gravida nibh sagittis a. Quisque tristique hendrerit eleifend. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Maecenas quis venenatis ex. Morbi ac nisl egestas, pulvinar lectus ac, mattis est. Sed felis ex, pretium in felis nec, efficitur vulputate dui.</p>

      <p>Vestibulum tempor tincidunt neque quis viverra. Maecenas ornare mauris quis ipsum tincidunt, non aliquam augue lobortis. Mauris sodales ligula at orci egestas, ut commodo nisl suscipit. Etiam augue enim, fringilla blandit tristique nec, varius consectetur ligula. Sed sit amet velit nibh. Nullam id lobortis ante. Aenean mollis nec sem in euismod. Quisque imperdiet ac odio eu varius. Nullam vitae ultrices neque, ac placerat lacus.</p>

      <p>Mauris sodales vehicula sem, non aliquet urna pulvinar vitae. Etiam in blandit metus, nec dapibus velit. Etiam vel arcu non massa maximus pellentesque quis a nisl. Vivamus eget posuere tellus, non vulputate metus. Sed eu nisi id massa condimentum imperdiet. Morbi suscipit elit et metus hendrerit, sed egestas nibh semper. Suspendisse mollis molestie sollicitudin. Cras egestas posuere efficitur. Nullam eget ligula nunc. Nunc id gravida metus. Suspendisse sodales malesuada velit non elementum. Donec nec risus ut nunc varius fermentum. Mauris fermentum est ex, nec consequat justo ultrices id. Nullam id ante cursus, tincidunt sapien ac, dapibus ipsum.</p>

      <p>Aenean viverra condimentum mattis. Suspendisse vel lacus at nunc feugiat vehicula quis ac metus. Nullam mattis vitae mauris ac interdum. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Praesent eu nisl hendrerit, lacinia metus tincidunt, feugiat quam. Vestibulum sed varius magna. Mauris eget metus hendrerit, euismod risus et, imperdiet elit. Duis vehicula iaculis nibh, a ultricies tellus posuere a. Nulla et eros nisl. Phasellus in accumsan ipsum, ut ultricies magna. Praesent interdum libero ac eleifend consectetur. Nullam ac urna dolor. Duis a venenatis nulla. Sed euismod, dui sit amet aliquet mollis, erat orci feugiat nisi, a iaculis nunc mauris sed enim. In vulputate nisi id lorem blandit, at scelerisque leo vulputate.</p>

      <p>Aliquam cursus dolor felis. Maecenas et augue eros. Nulla facilisis aliquet est, porttitor dignissim sem dapibus in. Curabitur a risus magna. Vivamus bibendum ut dui non interdum. Integer ligula sapien, tempus id arcu eleifend, sagittis volutpat felis. Morbi tincidunt mi eget pharetra pulvinar. Maecenas maximus in urna non interdum. Suspendisse euismod justo vitae nunc sollicitudin tincidunt. Integer tellus tellus, pharetra sit amet sollicitudin sit amet, aliquet a nulla. Proin eu ante eros.</p>

      <p>Suspendisse auctor, tortor consectetur consequat fringilla, nisi nulla gravida nunc, eu vestibulum urna nibh nec nunc. Suspendisse potenti. Nam consectetur, mi sit amet condimentum placerat, sapien leo tempor felis, id dapibus mi ligula nec lorem. Sed imperdiet orci nulla, eget condimentum neque gravida sit amet. Mauris porta convallis dui ut tincidunt. Pellentesque ullamcorper vestibulum tellus, id elementum diam bibendum at. Quisque ut justo a elit mattis accumsan quis at nulla. Morbi blandit justo aliquet, iaculis mauris sit amet, egestas erat. Sed imperdiet in mi at maximus. Fusce in tortor eget mauris convallis tempor et eget purus. Cras a leo nisi. Maecenas nec vestibulum velit, ut finibus urna. Quisque eu ligula in massa facilisis volutpat eu vel magna.</p>

      <p>Curabitur eu turpis neque. Vivamus cursus eros nec imperdiet mattis. Nam sit amet fermentum dui. Vestibulum sapien ipsum, molestie quis leo ut, bibendum interdum mauris. Mauris at neque quis nibh placerat viverra. Nunc porta lectus lacus, vulputate imperdiet mauris feugiat eu. Morbi consectetur dolor nec tincidunt pellentesque. Vivamus libero neque, fringilla quis imperdiet at, iaculis in ante. Cras id porttitor risus. Pellentesque id dolor vitae libero rhoncus rhoncus. Maecenas tincidunt arcu elit, vel tincidunt lorem auctor at. Duis nec consequat nulla, in elementum eros. Pellentesque massa nulla, imperdiet non vulputate sed, congue vitae ligula. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.</p>

      <p>Cras vitae elit quis sapien laoreet sagittis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Nam mattis tortor sed consectetur pulvinar. Integer eu dui cursus, consectetur lectus ut, lacinia magna. Nam sit amet congue nisl. Curabitur consequat turpis sit amet accumsan finibus. Quisque pretium vel nulla in commodo.</p>

      <p>Aenean dapibus purus in enim pretium suscipit. Vivamus nulla nunc, efficitur non mattis id, lacinia lacinia ligula. Praesent dignissim est ut rhoncus viverra. Duis scelerisque accumsan nibh, sed ultricies dui luctus vitae. Maecenas rhoncus nec ligula a ultricies. Phasellus vehicula metus nulla, eleifend imperdiet neque tincidunt vitae. Integer vulputate dignissim varius. Mauris eleifend maximus est quis pellentesque. Vivamus tempor elit justo, eget consectetur eros blandit at.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PagePlaceholder'
}
</script>

<style scoped>

</style>
