const install = function (Vue, options) {
  Vue.prototype.$gameSessionType = {
    // these should not record scores
    DEMO: 'DEMO',
    GUEST: 'GUEST',
    EVCONFIG: 'EVCONFIG',
    EVLEVEL: 'EVLEVEL',
    FPNOSCORE: 'FPNOSCORE',
    // these should record scores
    TEST: 'TEST',
    LEAGUE: 'LEAGUE',
    TOURNAMENT: 'TOURNAMENT',
    ASSIGNMENT: 'ASSIGNMENT',
    FPSCORE: 'FPSCORE'
  }

  Vue.prototype.$gameSessionLaunchMode = {
    TESTINAPP: 'TESTINAPP',
    TESTEXTERNAL: 'TESTEXTERNAL',
    LAUNCHGAME: 'LAUNCHGAME'
  }

  Vue.prototype.$launchGameConfiguration = function (gameConfiguration, gameSessionType, gameSessionLaunchMode, options, targetWindow) {
    const payload = {
      gameConfigurationId: gameConfiguration.id,
      gameSessionType: gameSessionType,
      options: options
    }
    this.$store.dispatch('GET_GAME_SESSION', payload)
      .then(result => {
        if (gameSessionLaunchMode === this.$gameSessionLaunchMode.TESTINAPP) {
          // this.$router.push({ name: 'ActivitySessionTester', params: { sessionId: result.gameSessionId } })
          const routeData = this.$router.resolve({ name: 'ActivitySessionTester', params: { sessionId: result.gameSessionId } })
          targetWindow.location = routeData.href
          // window.open(routeData.href, '_blank')
        } else if (gameSessionLaunchMode === this.$gameSessionLaunchMode.TESTEXTERNAL) {
          targetWindow.location = result.sessionTestUrl
          // window.open(result.sessionTestUrl, '_blank')
        } else if (gameSessionLaunchMode === this.$gameSessionLaunchMode.LAUNCHGAME) {
          targetWindow.location = result.gameplayUrl
          // window.open(result.gameplayUrl, '_blank')
        } else {
          alert('invalid launch mode')
        }
      })
  }

  // 1. add global method or property
  Vue.prototype.$evalGameConfiguration = function (gameConfiguration, options) {
    const payload = {
      gameConfigurationId: gameConfiguration.id,
      gameSessionType: 'EVCONFIG',
      options: options || {}
    }
    this.$store.dispatch('GET_GAME_SESSION', payload)
      .then(result => {
        window.open(result.gameplayUrl, '_blank')
      })
  }

  Vue.prototype.$startAssignedGamePlayList = function (gamePlayListId) {
    const payload = {
      gamePlayListId: gamePlayListId,
      gameSessionType: 'ASSIGNMENT'
    }
    this.$store.dispatch('START_GAME_PLAY_LIST', payload)
      .then(result => {
        window.open(result.gameplayUrl, '_blank')
      })
  }

  Vue.prototype.$evalGamePlayList = function (gamePlayListId) {
    const payload = {
      gamePlayListId: gamePlayListId,
      gameSessionType: 'EVCONFIG'
    }
    this.$store.dispatch('START_GAME_PLAY_LIST', payload)
      .then(result => {
        window.open(result.sessionTestUrl, '_blank')
      })
  }
}

const GameplayPlugin = {
  install: install
}

export { GameplayPlugin }
