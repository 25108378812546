// import eventBus from '@/eventBus.js'
import apolloProvider from '@/graphql'
import sendBillingMessage from '@/graphql/mutation/sendBillingMessage.graphql'
import updatePaymentStatus from '@/graphql/mutation/updatePaymentStatus.graphql'

const types = {
  RESET_BILLING: 'RESET_BILLING',
  REFRESH_KEY: 'REFRESH_KEY',
  SEND_BILLING_MESSAGE: 'SEND_BILLING_MESSAGE',
  UPDATE_PAYMENT_STATUS: 'UPDATE_PAYMENT_STATUS'
}

const getDefaultState = () => {
  return {
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
}

// actions
const actions = {
  [types.RESET_BILLING]: {
    root: true,
    handler: async (store) => {
      store.commit(types.RESET_BILLING)
    }
  },
  [types.REFRESH_KEY]: {
    root: true,
    handler: async (store) => {
      store.commit(types.REFRESH_KEY)
    }
  },
  [types.SEND_BILLING_MESSAGE]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: sendBillingMessage,
          variables: payload
        })
    }
  },
  [types.UPDATE_PAYMENT_STATUS]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: updatePaymentStatus,
          variables: payload
        })
    }
  }
}

// mutations
const mutations = {
  [types.RESET_BILLING] (state) {
    Object.assign(state, getDefaultState())
  },
  [types.REFRESH_KEY] (state) {
    state.refreshKey += 1
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
