<script>
import CustomizableTools from '@/components/PromoSite/HomePage/CustomizableTools'
import SlideSection from '@/components/_common/special/SlideSection/SlideSection'
import SystemRequirements from '@/components/_common/SystemRequirements'
export default {
  name: 'GamesSection',
  components: {
    SlideSection,
    CustomizableTools,
    SystemRequirements
  },
  data () {
    return {
      sectionSlideKey: 0
    }
  },
  computed: {
    slides () {
      return [
        {
          id: 'slide-0',
          title: `<div class="${this.titleStyles} slide-title text-outline-light is-size-3-mobile is-size-2-tablet is-family-semibold mb-2">New Activities<br>Added Monthly</div>`,
          content: `<div style="${this.contentStyles}" class="text-outline-dark is-size-5-mobile is-size-4-tablet is-family-semibold mb-4"><ul class="disc-list">
    <li class="has-text-white text-outline-dark">Engaging &amp; Fun</li>
    <li class="has-text-white text-outline-dark">Basic to Advanced Skill Levels</li>
    <li class="has-text-white text-outline-dark">Activities for all ages</li>
</ul>
</div>`,
          align: 'RIGHT',
          type: 'VIDEO',
          buttonLabel: 'See Our Activities',
          event: 'GOTO_ACTIVITIES',
          duration: 6,
          hasContentBox: false,
          video: {
            mobile: require('@/assets/promo/video/gameSlides/slide_0.webm'),
            tablet: require('@/assets/promo/video/gameSlides/slide_0.webm'),
            desktop: require('@/assets/promo/video/gameSlides/slide_0.webm'),
            widescreen: require('@/assets/promo/video/gameSlides/slide_0.webm'),
            fullhd: require('@/assets/promo/video/gameSlides/slide_0.webm')
          },
          poster: {
            mobile: require('@/assets/promo/img/home/slideSection/slide_0_poster@0.33x.webp'),
            tablet: require('@/assets/promo/img/home/slideSection/slide_0_poster@0.5x.webp'),
            desktop: require('@/assets/promo/img/home/slideSection/slide_0_poster@0.5x.webp'),
            widescreen: require('@/assets/promo/img/home/slideSection/slide_0_poster@0.75x.webp'),
            fullhd: require('@/assets/promo/img/home/slideSection/slide_0_poster@0.75x.webp')
          }
        },
        {
          id: 'slide-1',
          title: `<div class="${this.titleStyles} slide-title text-outline-light is-size-3-mobile is-size-2-tablet is-family-semibold mb-2">Individualized Treatment Plans</div>`,
          content: `<div style="${this.contentStyles}" class="text-outline-dark is-size-5-mobile is-size-4-tablet is-family-semibold mb-2">
<ul class="">
    <li>Customize Activities Inside Your Dashboard</li>
    <li>TBI Protocols</li>
    <li>Innovative Anti-Suppression and MFBF Colors</li>
    <li>Dyslexic and Foreign Language Accommodation</li>
</ul>
</div>`,
          align: 'RIGHT',
          type: 'IMAGE',
          hasContentBox: true,
          duration: 6,
          video: {
            mobile: require('@/assets/promo/video/null.webm'),
            tablet: require('@/assets/promo/video/null.webm'),
            desktop: require('@/assets/promo/video/null.webm'),
            widescreen: require('@/assets/promo/video/null.webm'),
            fullhd: require('@/assets/promo/video/null.webm')
          },
          poster: {
            mobile: require('@/assets/promo/img/home/slideSection/tablet_01@0.33x.webp'),
            tablet: require('@/assets/promo/img/home/slideSection/tablet_01@0.5x.webp'),
            desktop: require('@/assets/promo/img/home/slideSection/tablet_01@0.5x.webp'),
            widescreen: require('@/assets/promo/img/home/slideSection/tablet_01@0.75x.webp'),
            fullhd: require('@/assets/promo/img/home/slideSection/tablet_01@0.75x.webp')
          }
        },
        {
          id: 'slide-2',
          title: `<div class="${this.titleStyles} slide-title text-outline-dark is-size-3-mobile is-size-2-tablet is-family-semibold mb-2">Comprehensive<br>Resource</div>`,
          content: `<div style="${this.contentStyles}" class="is-size-5-mobile is-size-4-tablet has-text-white is-family-semibold mb-2">
<ul class="">
    <li>Assignable demo videos to<br>support your treatment plan</li>
    <li>Downloadable worksheets<br>for all ages</li>
    <li>In-office protocols</li>
</ul>
</div>`,
          align: 'LEFT',
          type: 'IMAGE',
          duration: 6,
          buttonLabel: 'See Our Activities',
          event: 'GOTO_ACTIVITIES',
          hasContentBox: false,
          video: {
            mobile: require('@/assets/promo/video/null.webm'),
            tablet: require('@/assets/promo/video/null.webm'),
            desktop: require('@/assets/promo/video/null.webm'),
            widescreen: require('@/assets/promo/video/null.webm'),
            fullhd: require('@/assets/promo/video/null.webm')
          },
          poster: {
            mobile: require('@/assets/promo/img/home/slideSection/tablet_02@0.33x.webp'),
            tablet: require('@/assets/promo/img/home/slideSection/tablet_02@0.5x.webp'),
            desktop: require('@/assets/promo/img/home/slideSection/tablet_02@0.5x.webp'),
            widescreen: require('@/assets/promo/img/home/slideSection/tablet_02@0.75x.webp'),
            fullhd: require('@/assets/promo/img/home/slideSection/tablet_02@0.75x.webp')
          }
        }
      ]
    }
  },
  methods: {
    onGoToActivities () {
      this.$router.push({ name: 'ActivitiesView' })
    }
  }
}
</script>
<template>
  <section
    class="games-section"
  >
    <div class="container">
      <div class="responsive-row">
        <div class="responsive-row-item is-text-item">
          <h2 class="text-outline-light section-title-main">A Better Foundation</h2>
          <div class="section-subtitle">
            Prescribe more fun with Our Visual Brain, your secret sauce for increasing compliance!
          </div>
        </div>
      </div>
      <div class="responsive-row">
        <div
          class="responsive-row-item is-text-item"
          :class="{'container': $mq === 'mobile'}"
        >
          <div>
            <div  class="is-size-5 block">
              OVB is an engaging online platform that delivers VT-driven games that assist in teaching and building visual skills. We also incorporate academic skills, making our platform a comprehensive tool for enhancing visual and cognitive abilities. Our physician-conceived and validated approach enhances traditional therapy solutions through 160+ engaging activities that cover the full range of VT skills from basic through advanced. Recognizing that engagement promotes compliance, OVB adds new activities every month.
            </div>
              <div class="testimonial">
              <div class="testimonial-body is-size-5">
                &ldquo;OVB is a game changer; they have built a better mousetrap. Compliance is way up because my patients are engaged. They weren't having fun before and now they are. I love OVB in-office and know my patients are having fun with their VT homework.&rdquo;
                <div class="testimonial-author is-size-5">Dr. Ryan Capouch, OD FCOVD</div>
              </div>
            </div>
          </div>
        </div>
        <div class="responsive-row-item is-slide-item">
          <slide-section
            class="game-slides"
            :key="sectionSlideKey"
            height="25rem"
            width="42rem"
            :slides="slides"
            :auto-play="true"
            @RESET="sectionSlideKey += 1"
            :show-banner="true"
            :has-gradiant="true"
            @GOTO_ACTIVITIES="onGoToActivities"
          />
        </div>
      </div>
      <section
        class="mt-6"
      >
        <header class="container mobile-container">
          <h2 class="text-outline-light section-title-main">
            Over 160 Customizable Activities
          </h2>
          <h2 class="text-outline-light section-subtitle mb-6">
            Watch Compliance Rates Soar!
          </h2>
        </header>
        <div
          class="umbrella-container mx-4"
        >
          <div
            class="umbrella-category-list"
          >
            <div
              class="umbrella-category-item"
            >
              <router-link
                :to="{ name: 'ActivitiesView', params: {stickyNav: false}}"
              >
                <figure>
                  <img
                    alt="Accommodation Skills"
                    :src="require('@/assets/promo/img/umbrella_categories/accomodation_skills.svg')"
                  />
                  <figcaption>Accommodation Skills</figcaption>
                </figure>
              </router-link>
            </div>
            <div
              class="umbrella-category-item"
            >
              <router-link
                :to="{ name: 'ActivitiesView', params: {stickyNav: false}}"
              >
                <figure>
                  <img
                    alt="Information Processing"
                    :src="require('@/assets/promo/img/umbrella_categories/information_processing.svg')"
                  />
                  <figcaption>Information Processing</figcaption>
                </figure>
              </router-link>
            </div>
            <div
              class="umbrella-category-item"
            >
              <router-link
                :to="{ name: 'ActivitiesView', params: {stickyNav: false}}"
              >
                <figure>
                  <img
                    alt="Letter and Word"
                    :src="require('@/assets/promo/img/umbrella_categories/letter_and_word.svg')"
                  />
                  <figcaption>Letter &amp; Word</figcaption>
                </figure>
              </router-link>
            </div>
            <div
              class="umbrella-category-item"
            >
              <router-link
                :to="{ name: 'ActivitiesView', params: {stickyNav: false}}"
              >
                <figure>
                  <img
                    alt="Math/Algebra"
                    :src="require('@/assets/promo/img/umbrella_categories/math_algebra.svg')"
                  />
                  <figcaption>Math/Algebra</figcaption>
                </figure>
              </router-link>
            </div>
            <div
              class="umbrella-category-item"
            >
              <router-link
                :to="{ name: 'ActivitiesView', params: {stickyNav: false}}"
              >
                <figure>
                  <img
                    alt="Peripheral Skills"
                    :src="require('@/assets/promo/img/umbrella_categories/peripheral_skills.svg')"
                  />
                  <figcaption>Peripheral Skills</figcaption>
                </figure>
              </router-link>
            </div>
            <div
              class="umbrella-category-item"
            >
              <router-link
                :to="{ name: 'ActivitiesView', params: {stickyNav: false}}"
              >
                <figure>
                  <img
                    alt="Tachitoscope Skills"
                    :src="require('@/assets/promo/img/umbrella_categories/tachitoscope_skills.svg')"
                  />
                  <figcaption>Tachitoscope Skills</figcaption>
                </figure>
              </router-link>
            </div>
            <div
              class="umbrella-category-item"
            >
              <router-link
                :to="{ name: 'ActivitiesView', params: {stickyNav: false}}"
              >
                <figure>
                  <img
                    alt="Tracking Skills"
                    :src="require('@/assets/promo/img/umbrella_categories/tracking_skills.svg')"
                  />
                  <figcaption>Tracking Skills</figcaption>
                </figure>
              </router-link>
            </div>
            <div
              class="umbrella-category-item"
            >
              <router-link
                :to="{ name: 'ActivitiesView', params: {stickyNav: false}}"
              >
                <figure>
                  <img
                    alt="Vergence Skills"
                    :src="require('@/assets/promo/img/umbrella_categories/vergence_skills.svg')"
                  />
                  <figcaption>Vergence Skills</figcaption>
                </figure>
              </router-link>
            </div>
          </div>
        </div>
        <div class="responsive-row mt-6">
          <customizable-tools />
        </div>
      </section>
    </div>
    <div class="feedback-stripe mt-4">
      <div class="container mobile-container text-outline-light">
        <div class="block">
          We've gotten feedback that when compared to other therapy tools, <span class="is-family-semibold">Our Visual Brain</span> is more enjoyable, and skills are learned quicker. Therapists report improved compliance, and many parents have said their kids are asking to play our activities &mdash; for fun. Our activities are often used as a reward, both in-office and at home.
        </div>
      </div>
    </div>
    <div class="requirements-container">
      <header class="container mobile-container ">
        <h4 class="is-size-4 mb-2">System Requirements</h4>
      </header>
      <system-requirements class=" container mobile-container "/>
    </div>
  </section>
</template>
<style lang="scss">
.games-section {
  .requirements-container {
    padding: 2rem 0;
    color: $white;
    background-color: $dark-blue;
  }
  #slide-0 {
    .slide-overlay {
      background: linear-gradient(45deg, #2a4ae430, #fb949e6b)
    }
    .slide-title {
      margin-top: 1rem;
      line-height: 1.75rem;
      color: $watermelon;
      @include tablet {
        line-height: 2rem;
      }
    }
  }
  #slide-1 {
    .slide-overlay {
      background: linear-gradient(45deg, hsla(206, 31%, 50%, 10%), hsla(2, 78%, 83%, 70%) )
    }
    .content-container {
      max-width: 74%;
      @include tablet {
        max-width: 44%;
      }
    }
    .slide-title {
      margin-top: 1rem;
      line-height: 1.5rem;
      color: $watermelon;
      @include tablet {
        line-height: 2rem;
      }
    }
    ul {
      //list-style: disc;
      //margin-left: 1rem;
      color: #fff;
      li {
        line-height: 1.60rem;
        margin-top: 0.5rem;
      }
    }
  }
  #slide-2 {
    .slide-title {
      line-height: 1.5rem;
      color: #fff;
      @include tablet {
        line-height: 2rem;
      }
    }
    .slide-overlay {
      background: linear-gradient(45deg, #2a4ae430, #fb949e6b)
    }
    ul {
      //list-style: disc;
      //margin-left: 1rem;
      margin-bottom: 0.5rem;
      color: #fff;
      li {
        line-height: 1.60rem;
        margin-top: 0.75rem;
      }
    }
  }
  .slide-tablet-1 {
    list-style: disc;
    margin-left: 1rem;
  }
  .slide-tablet-2 {
    max-width: 65%;
    list-style: disc;
    margin-left: 1rem;
  }
}

</style>
<style scoped lang="scss">
.play-now-button {
  background-color: $watermelon;
  color: $white;
}
.compliance-title {
  font-size: $size-3;
  line-height: $size-3;
  color: $feldgrau;
  font-family: $family-semibold;
}
.games-section {
  //height: 100vh;
  background: $eggshell;
  //background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(251,249,239,1) 100%);
  padding: 2rem 0 0 0;
}
.game-slides {
  box-shadow: rgba(0, 0, 0, 0.2) 0 10px 50px;
}
.detail-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.umbrella-container {
  display: block;
}
.umbrella-category-list {
  display: grid;
  grid-template-columns: repeat( auto-fit, minmax(5rem, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 1rem;
  .umbrella-category-item {
    width: 6rem;
    display: flex;
    justify-content: center;
    figure {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 5rem;
      }
    }
    figcaption {
      font-size: 90%;
      text-align: center;
      color: $feldgrau;
      line-height: 1rem;
      padding-top: 0.25rem;
    }
  }
}
ul.points-of-interest {
  columns: 3 22rem;
}
.points-of-interest {
  .poi {
    padding: 0.5rem;
  }
}
.feedback-stripe {
  background-color: $toothpaste;
  color: $charcoal;
  padding: 1rem 0;
  font-size: 1rem;
  @include tablet {
    font-size: 1.5rem;
  }
}
</style>
