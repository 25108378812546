import gql from 'graphql-tag'

const mutation = gql`mutation deleteActivityDevice($activityDeviceId: String!) {
    deleteActivityDevice(input: {_activityDeviceId: $activityDeviceId}) {
        boolean
        query {
            clinicDevices {
                nodes {
                    id
                    isClinicDevice
                    name
                }
            }
            appUserDevices {
                nodes {
                    id
                    isClinicDevice
                    name
                }
            }
        }
    }
}
`
export default mutation
