const install = function (Vue, options) {
  Vue.prototype.$hexToRgb = function (hex) {
    // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, function (m, r, g, b) {
      return r + r + g + g + b + b
    })
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    const r = parseInt(result[1], 16)
    const g = parseInt(result[2], 16)
    const b = parseInt(result[3], 16)
    // const display = `${result ? `r:${r} g:${g} b:${b}` : null}`
    const display = `${result ? `${r.toString().padStart(3, 0)} ${g.toString().padStart(3, 0)} ${b.toString().padStart(3, 0)}` : null}`
    return {
      r: r,
      g: g,
      b: b,
      display: display
    }
  }

  function componentToHex (c) {
    if (c < 256) {
      return Math.abs(c).toString(16).padStart(2, '0')
    }
    return '00'
  }

  Vue.prototype.$rgbToHex = function (r, g, b) {
    return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b)
  }
}

const ColorsPlugin = {
  install: install
}

export { ColorsPlugin }
