export const demo = {
  methods: {
    demoCalendar () {
      // console.log(document.currentScript)
      const target = document.currentScript
      // const calendar = document.calendar
      window.addEventListener('load', function () {
        // eslint-disable-next-line
        calendar.schedulingButton.load({
          url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ2AX6UZeaBPDes138vDc8rkF1mvE-Rkt5M0MyqIFAggLIbR9PKIa-v_9_vimRTiDK3irb_dn4Du?gv=true',
          color: '#039BE5',
          label: 'Book an appointment',
          target
        })
      })
      // const calendar = document.calendar
      // const target = document.currentScript
      // calendar.schedulingButton.load({
      //   url: 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ2AX6UZeaBPDes138vDc8rkF1mvE-Rkt5M0MyqIFAggLIbR9PKIa-v_9_vimRTiDK3irb_dn4Du?gv=true',
      //   color: '#039BE5',
      //   label: 'Book an appointment',
      //   target
      // })
    }
  }
}
