import gql from 'graphql-tag'

const query = gql`query appUserDevices {
    appUserDevices {
        nodes {
            id
            isClinicDevice
            name
        }
    }
}
`
export default query
