import router from '@/router'
import omitDeep from 'omit-deep'
import apolloProvider from '@/graphql'
import setPatientTherapyCategories from '@/graphql/mutation/setPatientTherapyCategories.graphql'
import getGameplaySession from '@/graphql/mutation/getGameplaySession.graphql'
import startGamePlayList from '@/graphql/mutation/startGamePlayList.graphql'
import terminateGameplaySession from '@/graphql/mutation/terminateGameplaySession.graphql'
import upsertGame from '@/graphql/mutation/upsertGame.graphql'
import upsertGameLevel from '@/graphql/mutation/upsertGameLevel.graphql'
import upsertGameConfiguration from '@/graphql/mutation/upsertGameConfiguration.graphql'
import createPatientGameAssignment from '@/graphql/mutation/createPatientGameAssignment.graphql'
import reconfigurePatientGameAssignment from '@/graphql/mutation/reconfigurePatientGameAssignment.graphql'
import removePatientGameAssignment from '@/graphql/mutation/removePatientGameAssignment.graphql'
import promotePlayListEntry from '@/graphql/mutation/promotePlayListEntry.graphql'
import reactivatePatientGameAssignment from '@/graphql/mutation/reactivatePatientGameAssignment.graphql'
import createPatientWeMoveAssignment from '@/graphql/mutation/createPatientWeMoveAssignment.graphql'
import removePatientWeMoveAssignment from '@/graphql/mutation/removePatientWeMoveAssignment.graphql'
import createPatientWorksheetAssignment from '@/graphql/mutation/createPatientWorksheetAssignment.graphql'
import removePatientWorksheetAssignment from '@/graphql/mutation/removePatientWorksheetAssignment.graphql'
import addPatientClipboardGames from '@/graphql/mutation/addPatientClipboardGames.graphql'
import removePatientClipboardGames from '@/graphql/mutation/removePatientClipboardGames.graphql'
import clearPatientClipboardGames from '@/graphql/mutation/clearPatientClipboardGames.graphql'
import addPatientClipboardWeMoves from '@/graphql/mutation/addPatientClipboardWeMoves.graphql'
import removePatientClipboardWeMoves from '@/graphql/mutation/removePatientClipboardWeMoves.graphql'
import clearPatientClipboardWeMoves from '@/graphql/mutation/clearPatientClipboardWeMoves.graphql'
import addPatientClipboardWorksheets from '@/graphql/mutation/addPatientClipboardWorksheets.graphql'
import removePatientClipboardWorksheets from '@/graphql/mutation/removePatientClipboardWorksheets.graphql'
import clearPatientClipboardWorksheets from '@/graphql/mutation/clearPatientClipboardWorksheets.graphql'
import upsertClinicGamePlayList from '@/graphql/mutation/upsertClinicGamePlayList.graphql'
import upsertClinicGamePlayListEntry from '@/graphql/mutation/upsertClinicGamePlayListEntry.graphql'
import removeClinicGamePlayListEntry from '@/graphql/mutation/removeClinicGamePlayListEntry.graphql'
import removeClinicGamePlayList from '@/graphql/mutation/removeClinicGamePlayList.graphql'
import clearGamePlayListEntries from '@/graphql/mutation/clearGamePlayListEntries.graphql'
import cloneClinicPlaylistForPatient from '@/graphql/mutation/cloneClinicPlaylistForPatient.graphql'
// import sendMessageToPatient from '@/graphql/mutation/sendMessageToPatient.graphql'
import sendMessageRegardingPatient from '@/graphql/mutation/sendMessageRegardingPatient.graphql'
import sendMessageFromPatient from '@/graphql/mutation/sendMessageFromPatient.graphql'
import acknowledgePatientMessages from '@/graphql/mutation/acknowledgePatientMessages.graphql'
import enablePresetGameConfigurations from '@/graphql/mutation/enablePresetGameConfigurations.graphql'
import disablePresetGameConfigurations from '@/graphql/mutation/disablePresetGameConfigurations.graphql'
import enableCustomGameConfigurations from '@/graphql/mutation/enableCustomGameConfigurations.graphql'
import disableCustomGameConfigurations from '@/graphql/mutation/disableCustomGameConfigurations.graphql'
import publishLibraryGameConfiguration from '@/graphql/mutation/publishLibraryGameConfiguration.graphql'
import unpublishLibraryGameConfiguration from '@/graphql/mutation/unpublishLibraryGameConfiguration.graphql'
import deactivateGameConfigurationTemplate from '@/graphql/mutation/deactivateGameConfigurationTemplate.graphql'
import deleteGameConfiguration from '@/graphql/mutation/deleteGameConfiguration.graphql'
import deleteActivityVersion from '@/graphql/mutation/deleteActivityVersion.graphql'
import setLibraryGameStatus from '@/graphql/mutation/setLibraryGameStatus.graphql'
import upsertGameSettingConfig from '@/graphql/mutation/upsertGameSettingConfig.graphql'
import deprecateGameSettingConfig from '@/graphql/mutation/deprecateGameSettingConfig.graphql'
import setGameCategorizations from '@/graphql/mutation/setGameCategorizations.graphql'
import upsertGameScoringConfigs from '@/graphql/mutation/upsertGameScoringConfigs.graphql'
import sendGameImplementationMessage from '@/graphql/mutation/sendGameImplementationMessage.graphql'
import setTherapyCategoryGames from '@/graphql/mutation/setTherapyCategoryGames.graphql'
import setTherapyCategoryWeMoves from '@/graphql/mutation/setTherapyCategoryWeMoves.graphql'
import setTherapyCategoryWorksheets from '@/graphql/mutation/setTherapyCategoryWorksheets.graphql'
import setCognitiveDomainGames from '@/graphql/mutation/setCognitiveDomainGames.graphql'
import upsertTherapyCategories from '@/graphql/mutation/upsertTherapyCategories.graphql'
import upsertCognitiveDomains from '@/graphql/mutation/upsertCognitiveDomains.graphql'
import deleteTherapyCategory from '@/graphql/mutation/deleteTherapyCategory.graphql'
import deleteCognitiveDomain from '@/graphql/mutation/deleteCognitiveDomain.graphql'
import reportScore from '@/graphql/mutation/reportScore.graphql'
import setGameState from '@/graphql/mutation/setGameState.graphql'
import createGameVersion from '@/graphql/mutation/createGameVersion.graphql'
import deleteGameVersion from '@/graphql/mutation/deleteGameVersion.graphql'
import setGameVersionSettingsOverride from '@/graphql/mutation/setGameVersionSettingsOverride.graphql'
import promoteGameSettingConfigOrdinal from '@/graphql/mutation/promoteGameSettingConfigOrdinal.graphql'
import demotePlayListEntry from '@/graphql/mutation/demotePlayListEntry.graphql'
import sendClinicDashboardMessage from '@/graphql/mutation/sendClinicDashboardMessage.graphql'
import addPaletteColor from '@/graphql/mutation/addPaletteColor.graphql'
import deletePaletteColor from '@/graphql/mutation/deletePaletteColor.graphql'
import requestGameDemo from '@/graphql/mutation/requestGameDemo.graphql'
import upsertWorksheet from '@/graphql/mutation/upsertWorksheet.graphql'
import upsertWeMove from '@/graphql/mutation/upsertWeMove.graphql'
import deleteWeMove from '@/graphql/mutation/deleteWeMove.graphql'
import deleteWorksheet from '@/graphql/mutation/deleteWorksheet.graphql'
import setTherapyCategoryParent from '@/graphql/mutation/setTherapyCategoryParent.graphql'
import setTherapyCategoryChildren from '@/graphql/mutation/setTherapyCategoryChildren.graphql'
import upgradePatientLicense from '@/graphql/mutation/upgradePatientLicense.graphql'
import createNewPatient from '@/graphql/mutation/createNewPatient.graphql'
import createActivityExplorerConfiguration from '@/graphql/mutation/createActivityExplorerConfiguration.graphql'
import promoteUserToGameLanguageManager from '@/graphql/mutation/promoteUserToGameLanguageManager.graphql'
import demoteUserFromGameLanguageManager from '@/graphql/mutation/demoteUserFromGameLanguageManager.graphql'
import updateGameLanguageInstructions from '@/graphql/mutation/updateGameLanguageInstructions.graphql'
import upsertPatientNote from '@/graphql/mutation/upsertPatientNote.graphql'
import deletePatientNote from '@/graphql/mutation/deletePatientNote.graphql'
import requestActivityDeploymentToProd from '@/graphql/mutation/requestActivityDeploymentToProd.graphql'
import cloneClinicPlaylistForClinic from '@/graphql/mutation/cloneClinicPlaylistForClinic.graphql'
import promoteUserToActivityDeveloper from '@/graphql/mutation/promoteUserToActivityDeveloper.graphql'
import demoteUserFromActivityDeveloper from '@/graphql/mutation/demoteUserFromActivityDeveloper.graphql'
import setPatientOdOs from '@/graphql/mutation/setPatientOdOs.graphql'
import upsertAppUserDevice from '@/graphql/mutation/upsertAppUserDevice.graphql'
import deleteAppUserDevice from '@/graphql/mutation/deleteAppUserDevice.graphql'
import upsertAppUserDeviceColorCalibration from '@/graphql/mutation/upsertAppUserDeviceColorCalibration.graphql'

const scrubPayload = async (payload) => omitDeep(payload, ['__typename'])
const DEFAULT_CLIPBOARD_ID = 'GENERAL'

const types = {
  RESET_OVB: 'RESET_OVB',
  REFRESH_KEY: 'REFRESH_KEY',
  SET_ALL_LIBRARIES: 'SET_ALL_LIBRARIES',
  SET_WORKING_LIBRARY: 'SET_WORKING_LIBRARY',
  UPDATE_WORKING_LIBRARY_SETTINGS: 'UPDATE_WORKING_LIBRARY_SETTINGS',
  SET_WORKING_ARTIFACT_TYPE: 'SET_WORKING_ARTIFACT_TYPE',
  SET_PATIENT_THERAPY_CATEGORIES: 'SET_PATIENT_THERAPY_CATEGORIES',
  SET_SILVER_ACTIVITIES: 'SET_SILVER_ACTIVITIES',
  ADD_CLIPBOARD_GAMES: 'ADD_CLIPBOARD_GAMES',
  REMOVE_CLIPBOARD_GAMES: 'REMOVE_CLIPBOARD_GAMES',
  CLEAR_CLIPBOARD_GAMES: 'CLEAR_CLIPBOARD_GAMES',
  SET_GAMES: 'SET_GAMES',
  SET_WEMOVES: 'SET_WEMOVES',
  SET_WORKSHEETS: 'SET_WORKSHEETS',
  SET_THERAPY_CATEGORIES: 'SET_THERAPY_CATEGORIES',
  GET_GAME_SESSION: 'GET_GAME_SESSION',
  START_GAME_PLAY_LIST: 'START_GAME_PLAY_LIST',
  TERMINATE_GAME_SESSION: 'TERMINATE_GAME_SESSION',
  UPSERT_GAME: 'UPSERT_GAME',
  UPSERT_GAME_LEVEL: 'UPSERT_GAME_LEVEL',
  UPSERT_GAME_CONFIGURATION: 'UPSERT_GAME_CONFIGURATION',
  UPSERT_CLINIC_GAME_PLAY_LIST: 'UPSERT_CLINIC_GAME_PLAY_LIST',
  UPSERT_PATIENT_GAME_ASSIGNMENT: 'UPSERT_PATIENT_GAME_ASSIGNMENT',
  REMOVE_PATIENT_GAME_ASSIGNMENT: 'REMOVE_PATIENT_GAME_ASSIGNMENT',
  REACTIVATE_PATIENT_GAME_ASSIGNMENT: 'REACTIVATE_PATIENT_GAME_ASSIGNMENT',
  PROMOTE_PLAY_LIST_ENTRY: 'PROMOTE_PLAY_LIST_ENTRY',
  DEMOTE_PLAY_LIST_ENTRY: 'DEMOTE_PLAY_LIST_ENTRY',
  ADD_PATIENT_CLIPBOARD_GAMES: 'ADD_PATIENT_CLIPBOARD_GAMES',
  REMOVE_PATIENT_CLIPBOARD_GAMES: 'REMOVE_PATIENT_CLIPBOARD_GAMES',
  CLEAR_PATIENT_CLIPBOARD_GAMES: 'CLEAR_PATIENT_CLIPBOARD_GAMES',
  ADD_PATIENT_CLIPBOARD_WEMOVES: 'ADD_PATIENT_CLIPBOARD_WEMOVES',
  REMOVE_PATIENT_CLIPBOARD_WEMOVES: 'REMOVE_PATIENT_CLIPBOARD_WEMOVES',
  CLEAR_PATIENT_CLIPBOARD_WEMOVES: 'CLEAR_PATIENT_CLIPBOARD_WEMOVES',
  ADD_PATIENT_CLIPBOARD_WORKSHEETS: 'ADD_PATIENT_CLIPBOARD_WORKSHEETS',
  REMOVE_PATIENT_CLIPBOARD_WORKSHEETS: 'REMOVE_PATIENT_CLIPBOARD_WORKSHEETS',
  CLEAR_PATIENT_CLIPBOARD_WORKSHEETS: 'CLEAR_PATIENT_CLIPBOARD_WORKSHEETS',
  CREATE_PATIENT_WEMOVE_ASSIGNMENT: 'CREATE_PATIENT_WEMOVE_ASSIGNMENT',
  REMOVE_PATIENT_WEMOVE_ASSIGNMENT: 'REMOVE_PATIENT_WEMOVE_ASSIGNMENT',
  CREATE_PATIENT_WORKSHEET_ASSIGNMENT: 'CREATE_PATIENT_WORKSHEET_ASSIGNMENT',
  REMOVE_PATIENT_WORKSHEET_ASSIGNMENT: 'REMOVE_PATIENT_WORKSHEET_ASSIGNMENT',
  SET_PATIENT_FOCUS: 'SET_PATIENT_FOCUS',
  REMOVE_RECENT_PATIENT: 'REMOVE_RECENT_PATIENT',
  CLEAR_RECENT_PATIENTS: 'CLEAR_RECENT_PATIENTS',
  SET_CLINIC_PLAYLIST_FOCUS: 'SET_CLINIC_PLAYLIST_FOCUS',
  REMOVE_RECENT_CLINIC_PLAYLIST: 'REMOVE_RECENT_CLINIC_PLAYLIST',
  CLEAR_RECENT_CLINIC_PLAYLISTS: 'CLEAR_RECENT_CLINIC_PLAYLISTS',
  UPSERT_CLINIC_GAME_PLAY_LIST_ENTRY: 'UPSERT_CLINIC_GAME_PLAY_LIST_ENTRY',
  REMOVE_CLINIC_GAME_PLAY_LIST_ENTRY: 'REMOVE_CLINIC_GAME_PLAY_LIST_ENTRY',
  REMOVE_CLINIC_GAME_PLAY_LIST: 'REMOVE_CLINIC_GAME_PLAY_LIST',
  CLEAR_GAME_PLAY_LIST_ENTRIES: 'CLEAR_GAME_PLAY_LIST_ENTRIES',
  CLONE_CLINIC_PLAY_LIST_FOR_PATIENT: 'CLONE_CLINIC_PLAY_LIST_FOR_PATIENT',
  CLONE_CLINIC_PLAY_LIST_FOR_CLINIC: 'CLONE_CLINIC_PLAY_LIST_FOR_CLINIC',
  SEND_MESSAGE_TO_PATIENT: 'SEND_MESSAGE_TO_PATIENT',
  SEND_MESSAGE_REGARDING_PATIENT: 'SEND_MESSAGE_REGARDING_PATIENT',
  SEND_MESSAGE_FROM_PATIENT: 'SEND_MESSAGE_FROM_PATIENT',
  ACKNOWLEDGE_PATIENT_MESSAGES: 'ACKNOWLEDGE_PATIENT_MESSAGES',
  ENABLE_PRESET_GAME_CONFIGURATIONS: 'ENABLE_PRESET_GAME_CONFIGURATIONS',
  DISABLE_PRESET_GAME_CONFIGURATIONS: 'DISABLE_PRESET_GAME_CONFIGURATIONS',
  ENABLE_CUSTOM_GAME_CONFIGURATIONS: 'ENABLE_CUSTOM_GAME_CONFIGURATIONS',
  DISABLE_CUSTOM_GAME_CONFIGURATIONS: 'DISABLE_CUSTOM_GAME_CONFIGURATIONS',
  PUBLISH_LIBRARY_GAME_CONFIGURATION: 'PUBLISH_LIBRARY_GAME_CONFIGURATION',
  UNPUBLISH_LIBRARY_GAME_CONFIGURATION: 'UNPUBLISH_LIBRARY_GAME_CONFIGURATION',
  DEACTIVATE_GAME_CONFIGURATION_TEMPLATE: 'DEACTIVATE_GAME_CONFIGURATION_TEMPLATE',
  DELETE_GAME_CONFIGURATION: 'DELETE_GAME_CONFIGURATION',
  DELETE_ACTIVITY_VERSION: 'DELETE_ACTIVITY_VERSION',
  SET_LIBRARY_GAME_STATUS: 'SET_LIBRARY_GAME_STATUS',
  UPSERT_GAME_SETTING_CONFIG: 'UPSERT_GAME_SETTING_CONFIG',
  DEPRECATE_GAME_SETTING_CONFIG: 'DEPRECATE_GAME_SETTING_CONFIG',
  SET_GAME_CATEGORIZATIONS: 'SET_GAME_CATEGORIZATIONS',
  UPSERT_GAME_SCORING_CONFIGS: 'UPSERT_GAME_SCORING_CONFIGS',
  SEND_GAME_IMPLEMENTATION_MESSAGE: 'SEND_GAME_IMPLEMENTATION_MESSAGE',
  SET_THERAPY_CATEGORY_GAMES: 'SET_THERAPY_CATEGORY_GAMES',
  SET_THERAPY_CATEGORY_WE_MOVES: 'SET_THERAPY_CATEGORY_WE_MOVES',
  SET_THERAPY_CATEGORY_WORKSHEETS: 'SET_THERAPY_CATEGORY_WORKSHEETS',
  SET_COGNITIVE_DOMAIN_GAMES: 'SET_COGNITIVE_DOMAIN_GAMES',
  UPSERT_THERAPY_CATEGORIES: 'UPSERT_THERAPY_CATEGORIES',
  UPSERT_COGNITIVE_DOMAINS: 'UPSERT_COGNITIVE_DOMAINS',
  DELETE_THERAPY_CATEGORY: 'DELETE_THERAPY_CATEGORY',
  DELETE_COGNITIVE_DOMAIN: 'DELETE_COGNITIVE_DOMAIN',
  UPSERT_WE_MOVE: 'UPSERT_WE_MOVE',
  UPSERT_WORKSHEET: 'UPSERT_WORKSHEET',
  REPORT_SCORE: 'REPORT_SCORE',
  REPORT_GAME_STATE: 'REPORT_GAME_STATE',
  CREATE_GAME_VERSION: 'CREATE_GAME_VERSION',
  DELETE_GAME_VERSION: 'DELETE_GAME_VERSION',
  SET_GAME_VERSION_SETTINGS_OVERRIDE: 'SET_GAME_VERSION_SETTINGS_OVERRIDE',
  PROMOTE_GAME_SETTING_CONFIG_ORDINAL: 'PROMOTE_GAME_SETTING_CONFIG_ORDINAL',
  SEND_CLINIC_DASHBOARD_MESSAGE: 'SEND_CLINIC_DASHBOARD_MESSAGE',
  ADD_PALETTE_COLOR: 'ADD_PALETTE_COLOR',
  DELETE_PALETTE_COLOR: 'DELETE_PALETTE_COLOR',
  REQUEST_GAME_DEMO: 'REQUEST_GAME_DEMO',
  DELETE_WE_MOVE: 'DELETE_WE_MOVE',
  DELETE_WORKSHEET: 'DELETE_WORKSHEET',
  SET_THERAPY_CATEGORY_PARENT: 'SET_THERAPY_CATEGORY_PARENT',
  SET_THERAPY_CATEGORY_CHILDREN: 'SET_THERAPY_CATEGORY_CHILDREN',
  UPGRADE_PATIENT_LICENSE: 'UPGRADE_PATIENT_LICENSE',
  CREATE_NEW_PATIENT: 'CREATE_NEW_PATIENT',
  UPSERT_GAME_CONFIGURATION_FOR_BROWSER: 'UPSERT_GAME_CONFIGURATION_FOR_BROWSER',
  SET_DEMO_SESSION: 'SET_DEMO_SESSION',
  SET_CURRENT_DEMO_CONFIGURATION: 'SET_CURRENT_DEMO_CONFIGURATION',
  SET_CURRENT_ACTIVITY_EXPLORER_CONFIGURATION: 'SET_CURRENT_ACTIVITY_EXPLORER_CONFIGURATION',
  CREATE_ACTIVITY_EXPLORER_CONFIGURATION: 'CREATE_ACTIVITY_EXPLORER_CONFIGURATION',
  PROMOTE_USER_TO_GAME_LANGUAGE_MANAGER: 'PROMOTE_USER_TO_GAME_LANGUAGE_MANAGER',
  DEMOTE_USER_FROM_GAME_LANGUAGE_MANAGER: 'DEMOTE_USER_FROM_GAME_LANGUAGE_MANAGER',
  UPDATE_GAME_LANGUAGE_INSTRUCTIONS: 'UPDATE_GAME_LANGUAGE_INSTRUCTIONS',
  UPSERT_PATIENT_NOTE: 'UPSERT_PATIENT_NOTE',
  DELETE_PATIENT_NOTE: 'DELETE_PATIENT_NOTE',
  REQUEST_ACTIVITY_DEPLOYMENT_TO_PROD: 'REQUEST_ACTIVITY_DEPLOYMENT_TO_PROD',
  PROMOTE_USER_TO_ACTIVITY_DEVELOPER: 'PROMOTE_USER_TO_ACTIVITY_DEVELOPER',
  DEMOTE_USER_FROM_ACTIVITY_DEVELOPER: 'DEMOTE_USER_FROM_ACTIVITY_DEVELOPER',
  UPSERT_APP_USER_DEVICE: 'UPSERT_APP_USER_DEVICE',
  DELETE_APP_USER_DEVICE: 'DELETE_APP_USER_DEVICE',
  UPSERT_APP_USER_DEVICE_COLOR_CALIBRATION: 'UPSERT_APP_USER_DEVICE_COLOR_CALIBRATION',
  SET_PATIENT_OD_OS: 'SET_PATIENT_OD_OS'
}

const SLUG = 'SLUG'
const IMAGE = 'IMAGE'

const getDefaultState = () => {
  return {
    clipboardGames: {},
    demoUserInfo: null,
    games: [],
    weMoves: [],
    worksheets: [],
    therapyCategories: [],
    silverActivities: [],
    libraries: [],
    workingLibrary: null,
    workingArtifactType: 'GAMES',
    refreshKey: 0,
    patientFocus: null,
    clinicGamePlayListFocus: null,
    recentPatients: [],
    recentClinicGamePlayLists: [],
    demoSession: null,
    currentDemoConfiguration: null,
    currentActivityExplorerConfiguration: null,
    contentSlugDefinitions: {
      GAME: [
        {
          contentType: SLUG,
          entityType: 'GAME',
          key: 'benefits_summary',
          description: 'content that will appear when hovering game on the front page',
          content: '<ul><li>these are</li><li>the reasons</li><li>this game is awesome</li></ul>'
        }
      ],
      THERAPYCATEGORY: [
        {
          contentType: SLUG,
          entityType: 'THERAPYCATEGORY',
          key: 'benefits_summary',
          description: 'content that will appear when hovering category on the front page',
          content: '<ul><li>these are</li><li>the reasons</li><li>this is awesome</li></ul>'
        },
        {
          contentType: SLUG,
          entityType: 'THERAPYCATEGORY',
          key: 'something_else',
          description: 'content that will appear when hovering somewhere else',
          content: '<ul><li>these are</li><li>the reasons</li><li>this is awesome</li></ul>'
        }
      ],
      COGNITIVEDOMAIN: [
        {
          contentType: SLUG,
          entityType: 'COGNITIVEDOMAIN',
          key: 'benefits_summary',
          description: 'content that will appear when hovering domain on the front page',
          content: '<ul><li>these are</li><li>the reasons</li><li>this is awesome</li></ul>'
        },
        {
          contentType: SLUG,
          entityType: 'COGNITIVEDOMAIN',
          key: 'something_else',
          description: 'content that will appear when hovering somewhere else',
          content: '<ul><li>these are</li><li>the reasons</li><li>this is awesome</li></ul>'
        }
      ],
      LIBRARY: [
        {
          contentType: SLUG,
          entityType: 'LIBRARY',
          key: 'benefits_summary',
          description: 'content that will appear when hovering library on the front page',
          content: '<ul><li>these are</li><li>the reasons</li><li>this is awesome</li></ul>'
        },
        {
          contentType: SLUG,
          entityType: 'LIBRARY',
          key: 'something_else',
          description: 'content that will appear when hovering somewhere else',
          content: '<ul><li>these are</li><li>the reasons</li><li>this is awesome</li></ul>'
        }
      ],
      WEMOVE: [
        {
          contentType: SLUG,
          entityType: 'WEMOVE',
          key: 'benefits_summary',
          description: 'content that will appear when hovering we move on the front page',
          content: '<ul><li>these are</li><li>the reasons</li><li>this is awesome</li></ul>'
        },
        {
          contentType: SLUG,
          entityType: 'WEMOVE',
          key: 'something_else',
          description: 'content that will appear when hovering somewhere else',
          content: '<ul><li>these are</li><li>the reasons</li><li>this is awesome</li></ul>'
        }
      ],
      WORKSHEET: [
        {
          contentType: SLUG,
          entityType: 'WORKSHEET',
          key: 'benefits_summary',
          description: 'content that will appear when hovering we move on the front page',
          content: '<ul><li>these are</li><li>the reasons</li><li>this is awesome</li></ul>'
        },
        {
          contentType: SLUG,
          entityType: 'WORKSHEET',
          key: 'something_else',
          description: 'content that will appear when hovering somewhere else',
          content: '<ul><li>these are</li><li>the reasons</li><li>this is awesome</li></ul>'
        }
      ]
    },
    contentImageDefinitions: {
    // https://sharp.pixelplumbing.com/api-resize#parameters
    // note that options must contain height and width. server ONLY uses options:
    // const transform = sharp()
    //   .resize(p.options)
    //   .toFormat(p.format, p.formatOptions)
      GAME: [
        {
          contentType: IMAGE,
          entityType: 'GAME',
          key: 'hero',
          description: 'the hero image for this game',
          versionSpecs: [
            {
              key: 'mobile',
              options: {
                width: 366,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'tablet',
              options: {
                width: 672,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'desktop',
              options: {
                width: 1344,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'fallback',
              options: {
                width: 1344,
                height: null
              },
              format: 'jpg'
            }
          ]
        },
        {
          contentType: IMAGE,
          entityType: 'GAME',
          key: 'FULL_COLOR',
          description: 'Full Color',
          versionSpecs: [
            {
              key: 'mobile',
              options: {
                width: 366,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'tablet',
              options: {
                width: 672,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'desktop',
              options: {
                width: 1344,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'fallback',
              options: {
                width: 1344,
                height: null
              },
              format: 'jpg'
            }
          ]
        },
        {
          contentType: IMAGE,
          entityType: 'GAME',
          key: 'MFBF',
          description: 'MFBF',
          versionSpecs: [
            {
              key: 'mobile',
              options: {
                width: 366,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'tablet',
              options: {
                width: 672,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'desktop',
              options: {
                width: 1344,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'fallback',
              options: {
                width: 1344,
                height: null
              },
              format: 'jpg'
            }
          ]
        },
        {
          contentType: IMAGE,
          entityType: 'GAME',
          key: 'RED_GREEN',
          description: 'Red/Green',
          versionSpecs: [
            {
              key: 'mobile',
              options: {
                width: 366,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'tablet',
              options: {
                width: 672,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'desktop',
              options: {
                width: 1344,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'fallback',
              options: {
                width: 1344,
                height: null
              },
              format: 'jpg'
            }
          ]
        },
        {
          contentType: IMAGE,
          entityType: 'GAME',
          key: 'REDUCED_STIMULUS',
          description: 'Reduced Stimulus',
          versionSpecs: [
            {
              key: 'mobile',
              options: {
                width: 366,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'tablet',
              options: {
                width: 672,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'desktop',
              options: {
                width: 1344,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'fallback',
              options: {
                width: 1344,
                height: null
              },
              format: 'jpg'
            }
          ]
        },
        {
          contentType: IMAGE,
          entityType: 'GAME',
          key: 'DYSLEXIC_FORMAT',
          description: 'Dyslexic Format',
          versionSpecs: [
            {
              key: 'mobile',
              options: {
                width: 366,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'tablet',
              options: {
                width: 672,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'desktop',
              options: {
                width: 1344,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'fallback',
              options: {
                width: 1344,
                height: null
              },
              format: 'jpg'
            }
          ]
        }
      ],
      THERAPYCATEGORY: [
        {
          contentType: IMAGE,
          entityType: 'THERAPYCATEGORY',
          key: 'hero',
          description: 'the hero image for this therapyCategory',
          versionSpecs: [
            {
              key: 'small_png',
              options: {
                height: 100,
                width: 100
              },
              format: 'png'
            },
            {
              key: 'medium_png',
              options: {
                height: 150,
                width: 150
              },
              format: 'png'
            },
            {
              key: 'large_png',
              options: {
                height: 200,
                width: 200
              },
              format: 'png'
            },
            {
              key: 'small_webp',
              options: {
                height: 100,
                width: 100
              },
              format: 'webp'
            },
            {
              key: 'medium_webp',
              options: {
                height: 150,
                width: 150
              },
              format: 'webp'
            },
            {
              key: 'large_webp',
              options: {
                height: 200,
                width: 200
              },
              format: 'webp'
            }
          ]
        }
      ],
      COGNITIVEDOMAIN: [
        {
          contentType: IMAGE,
          entityType: 'COGNITIVEDOMAIN',
          key: 'hero',
          description: 'the hero image for this cognitiveDomain',
          versionSpecs: [
            {
              key: 'small_png',
              options: {
                height: 100,
                width: 100
              },
              format: 'png'
            },
            {
              key: 'medium_png',
              options: {
                height: 150,
                width: 150
              },
              format: 'png'
            },
            {
              key: 'large_png',
              options: {
                height: 200,
                width: 200
              },
              format: 'png'
            },
            {
              key: 'small_webp',
              options: {
                height: 100,
                width: 100
              },
              format: 'webp'
            },
            {
              key: 'medium_webp',
              options: {
                height: 150,
                width: 150
              },
              format: 'webp'
            },
            {
              key: 'large_webp',
              options: {
                height: 200,
                width: 200
              },
              format: 'webp'
            }
          ]
        }
      ],
      LIBRARY: [
        {
          contentType: IMAGE,
          entityType: 'LIBRARY',
          key: 'hero',
          description: 'the hero image for this library',
          versionSpecs: [
            {
              key: 'small_png',
              options: {
                height: 100,
                width: 100
              },
              format: 'png'
            },
            {
              key: 'medium_png',
              options: {
                height: 150,
                width: 150
              },
              format: 'png'
            },
            {
              key: 'large_png',
              options: {
                height: 200,
                width: 200
              },
              format: 'png'
            },
            {
              key: 'small_webp',
              options: {
                height: 100,
                width: 100
              },
              format: 'webp'
            },
            {
              key: 'medium_webp',
              options: {
                height: 150,
                width: 150
              },
              format: 'webp'
            },
            {
              key: 'large_webp',
              options: {
                height: 200,
                width: 200
              },
              format: 'webp'
            }
          ]
        }
      ],
      WEMOVE: [
        {
          contentType: IMAGE,
          entityType: 'WEMOVE',
          key: 'hero',
          description: 'the hero image for this video',
          versionSpecs: [
            {
              key: 'mobile',
              options: {
                width: 366,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'tablet',
              options: {
                width: 672,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'desktop',
              options: {
                width: 1344,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'fallback',
              options: {
                width: 1344,
                height: null
              },
              format: 'jpg'
            }
          ]
        }
        // {
        //   contentType: IMAGE,
        //   entityType: 'WEMOVE',
        //   key: 'hero',
        //   description: 'the hero image for this we move',
        //   versionSpecs: [
        //     {
        //       key: 'small_png',
        //       options: {
        //         height: 100,
        //         width: 100
        //       },
        //       format: 'png'
        //     },
        //     {
        //       key: 'medium_png',
        //       options: {
        //         height: 150,
        //         width: 150
        //       },
        //       format: 'png'
        //     },
        //     {
        //       key: 'large_png',
        //       options: {
        //         height: 200,
        //         width: 200
        //       },
        //       format: 'png'
        //     },
        //     {
        //       key: 'small_webp',
        //       options: {
        //         height: 100,
        //         width: 100
        //       },
        //       format: 'webp'
        //     },
        //     {
        //       key: 'medium_webp',
        //       options: {
        //         height: 150,
        //         width: 150
        //       },
        //       format: 'webp'
        //     },
        //     {
        //       key: 'large_webp',
        //       options: {
        //         height: 200,
        //         width: 200
        //       },
        //       format: 'webp'
        //     }
        //   ]
        // }
      ],
      WORKSHEET: [
        {
          contentType: IMAGE,
          entityType: 'WORKSHEET',
          key: 'hero',
          description: 'the hero image for this worksheet',
          versionSpecs: [
            {
              key: 'mobile',
              options: {
                width: 366,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'tablet',
              options: {
                width: 672,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'desktop',
              options: {
                width: 1344,
                height: null
              },
              format: 'webp'
            },
            {
              key: 'fallback',
              options: {
                width: 1344,
                height: null
              },
              format: 'jpg'
            }
          ]
        }
      ]
      // WORKSHEET: [
      //   {
      //     contentType: IMAGE,
      //     entityType: 'WORKSHEET',
      //     key: 'hero',
      //     description: 'the hero image for this worksheet',
      //     versionSpecs: [
      //       {
      //         key: 'small_png',
      //         options: {
      //           height: 100,
      //           width: 100
      //         },
      //         format: 'png'
      //       },
      //       {
      //         key: 'medium_png',
      //         options: {
      //           height: 150,
      //           width: 150
      //         },
      //         format: 'png'
      //       },
      //       {
      //         key: 'large_png',
      //         options: {
      //           height: 200,
      //           width: 200
      //         },
      //         format: 'png'
      //       },
      //       {
      //         key: 'small_webp',
      //         options: {
      //           height: 100,
      //           width: 100
      //         },
      //         format: 'webp'
      //       },
      //       {
      //         key: 'medium_webp',
      //         options: {
      //           height: 150,
      //           width: 150
      //         },
      //         format: 'webp'
      //       },
      //       {
      //         key: 'large_webp',
      //         options: {
      //           height: 200,
      //           width: 200
      //         },
      //         format: 'webp'
      //       }
      //     ]
      //   }
      // ]
    }
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  // sidenavState: state => state.sidenavState // OFF: not logged in, ACTIVE: fully open, HIDDEN: closed
  clipboardGames: state => state.clipboardGames,
  games: state => state.games,
  therapyCategories: state => state.therapyCategories,
  refreshKey: state => state.refreshKey,
  workingLibrary: state => state.workingLibrary

}

// actions
const actions = {
  [types.RESET_OVB]: {
    root: true,
    handler: async (store) => {
      store.commit(types.RESET_OVB)
    }
  },
  [types.REFRESH_KEY]: {
    root: true,
    handler: async (store) => {
      store.commit(types.REFRESH_KEY)
    }
  },
  [types.SET_PATIENT_THERAPY_CATEGORIES]: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      // console.log(JSON.stringify(cleanPayload, null, 2))
      return apolloProvider.defaultClient
        .mutate({
          mutation: setPatientTherapyCategories,
          variables: cleanPayload
        })
        .then(result => {
          return result.data.setPatientTherapyCategories
        })
    }
  },
  [types.SET_WORKING_ARTIFACT_TYPE]: {
    root: true,
    handler: async (store, payload) => {
      store.commit(types.SET_WORKING_ARTIFACT_TYPE, payload)
    }
  },
  [types.SET_ALL_LIBRARIES]: {
    root: true,
    handler: async (store, payload) => {
      store.commit(types.SET_ALL_LIBRARIES, payload)
    }
  },
  [types.SET_WORKING_LIBRARY]: {
    root: true,
    handler: async (store, payload) => {
      store.commit(types.SET_WORKING_LIBRARY, payload)
    }
  },
  [types.SET_SILVER_ACTIVITIES]: {
    root: true,
    handler: async (store, payload) => {
      store.commit(types.SET_SILVER_ACTIVITIES, payload)
    }
  },
  [types.GET_GAME_SESSION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: getGameplaySession,
          variables: payload
        })
        .then(result => {
          return result.data.getGameplaySession.getGameplaySessionResult
        })
    }
  },
  [types.START_GAME_PLAY_LIST]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: startGamePlayList,
          variables: payload
        })
        .then(result => {
          return result.data.startGamePlayList.getGameplaySessionResult
        })
    }
  },
  [types.TERMINATE_GAME_SESSION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: terminateGameplaySession,
          variables: payload
        })
        .then(result => {
          return result.data.terminateGameSession.gameSession
        })
    }
  },
  [types.REPORT_GAME_STATE]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: setGameState,
          variables: payload
        })
        .then(result => {
          return result.data.setGameState.gameSession
        })
    }
  },
  [types.CREATE_GAME_VERSION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: createGameVersion,
          variables: payload
        })
        .then(result => {
          // this.$store.commit('app/setEntityAppState', {
          //   entityId: this.game.playKey,
          //   type: 'Game',
          //   workingVersionName: this.selectedVersion.versionName
          // })
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DELETE_GAME_VERSION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteGameVersion,
          variables: payload
        })
        .then(result => {
          if (payload.versionName === 'base') {
            router.push({ name: 'ActivityManager' })
          } else {
            store.commit(types.REFRESH_KEY)
          }
        })
    }
  },
  [types.SET_GAME_VERSION_SETTINGS_OVERRIDE]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: setGameVersionSettingsOverride,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.PROMOTE_GAME_SETTING_CONFIG_ORDINAL]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: promoteGameSettingConfigOrdinal,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DELETE_ACTIVITY_VERSION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: createGameVersion,
          variables: payload
        })
        .then(result => {
          // this.$store.commit('app/setEntityAppState', {
          //   entityId: this.game.playKey,
          //   type: 'Game',
          //   workingVersionName: this.selectedVersion.versionName
          // })
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.UPSERT_GAME]: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertGame,
          variables: cleanPayload
        })
        .then(result => {
          if (['ActivityDetailByPlayKey', 'ActivityDetailById'].indexOf(router.currentRoute.name) > -1) {
            store.commit(types.REFRESH_KEY)
          } else {
            router.push({ name: 'ActivityDetailByPlayKey', params: { playKey: result.data.upsertGame.game.playKey } })
          }
        })
    }
  },
  [types.UPSERT_GAME_LEVEL]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertGameLevel,
          variables: payload
        })
        .then(result => {
          return result.data.upsertGameLevel.level
        })
    }
  },
  [types.UPSERT_GAME_SETTING_CONFIG]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertGameSettingConfig,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DEPRECATE_GAME_SETTING_CONFIG]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deprecateGameSettingConfig,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.SET_GAME_CATEGORIZATIONS]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: setGameCategorizations,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.UPSERT_GAME_SCORING_CONFIGS]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertGameScoringConfigs,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.SEND_GAME_IMPLEMENTATION_MESSAGE]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: sendGameImplementationMessage,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.SET_THERAPY_CATEGORY_GAMES]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: setTherapyCategoryGames,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.SET_THERAPY_CATEGORY_WE_MOVES]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: setTherapyCategoryWeMoves,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.SET_THERAPY_CATEGORY_WORKSHEETS]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: setTherapyCategoryWorksheets,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.SET_COGNITIVE_DOMAIN_GAMES]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: setCognitiveDomainGames,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.UPSERT_THERAPY_CATEGORIES]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertTherapyCategories,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.UPSERT_COGNITIVE_DOMAINS]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertCognitiveDomains,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DELETE_THERAPY_CATEGORY]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteTherapyCategory,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DELETE_COGNITIVE_DOMAIN]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteCognitiveDomain,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.UPSERT_WE_MOVE]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertWeMove,
          variables: variables
        })
        .then(result => {
          if (['WeMoveManagerDetail'].indexOf(router.currentRoute.name) > -1) {
            store.commit(types.REFRESH_KEY)
          } else {
            router.push({ name: 'WeMoveManagerDetail', params: { weMoveKey: result.data.upsertWeMove.weMove.key } })
          }
        })
    }
  },
  [types.UPSERT_WORKSHEET]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertWorksheet,
          variables: variables
        })
        .then(result => {
          if (['WorksheetManagerDetail'].indexOf(router.currentRoute.name) > -1) {
            store.commit(types.REFRESH_KEY)
          } else {
            router.push({ name: 'WorksheetManagerDetail', params: { worksheetKey: result.data.upsertWorksheet.worksheet.key } })
          }
        })
    }
  },
  [types.DELETE_WE_MOVE]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteWeMove,
          variables: payload
        })
        .then(result => {
          router.push({ name: 'WeMoveManager' })
        })
    }
  },
  [types.DELETE_WORKSHEET]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteWorksheet,
          variables: payload
        })
        .then(result => {
          router.push({ name: 'WorksheetManager' })
        })
    }
  },
  [types.SET_THERAPY_CATEGORY_PARENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: setTherapyCategoryParent,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.CREATE_NEW_PATIENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: createNewPatient,
          variables: payload
        })
        .then(result => {
          router.push({
            name: 'ClinicPatientOverview',
            params: { patientId: result.data.createNewPatient.patient.id }
          })
        })
    }
  },
  [types.UPGRADE_PATIENT_LICENSE]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: upgradePatientLicense,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.SET_THERAPY_CATEGORY_CHILDREN]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: setTherapyCategoryChildren,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.UPSERT_GAME_CONFIGURATION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertGameConfiguration,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.UPSERT_GAME_CONFIGURATION_FOR_BROWSER]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertGameConfiguration,
          variables: payload
        })
        .then(result => {
          return result
        })
    }
  },
  [types.UPSERT_CLINIC_GAME_PLAY_LIST]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertClinicGamePlayList,
          variables: payload
        })
        .then(result => {
          return result.data.upsertClinicGamePlayList.gamePlayList
        })
    }
  },
  [types.UPSERT_CLINIC_GAME_PLAY_LIST_ENTRY]: {
    root: true,
    handler: async (store, payload) => {
      const mutation = upsertClinicGamePlayListEntry
      return apolloProvider.defaultClient
        .mutate({
          mutation: mutation,
          variables: payload
        })
    }
  },
  [types.REMOVE_CLINIC_GAME_PLAY_LIST_ENTRY]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: removeClinicGamePlayListEntry,
          variables: payload
        })
    }
  },
  [types.REMOVE_CLINIC_GAME_PLAY_LIST]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: removeClinicGamePlayList,
          variables: payload
        })
    }
  },
  [types.UPSERT_PATIENT_GAME_ASSIGNMENT]: {
    root: true,
    handler: async (store, payload) => {
      const mutation = payload.patientGameAssignmentInfo.id ? reconfigurePatientGameAssignment : createPatientGameAssignment
      return apolloProvider.defaultClient
        .mutate({
          mutation: mutation,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.REMOVE_PATIENT_GAME_ASSIGNMENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: removePatientGameAssignment,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.PROMOTE_PLAY_LIST_ENTRY]: {
    root: true,
    handler: async (store, payload) => {
      const mutation = promotePlayListEntry
      return apolloProvider.defaultClient
        .mutate({
          mutation: mutation,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DEMOTE_PLAY_LIST_ENTRY]: {
    root: true,
    handler: async (store, payload) => {
      const mutation = demotePlayListEntry
      return apolloProvider.defaultClient
        .mutate({
          mutation: mutation,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.CREATE_PATIENT_WEMOVE_ASSIGNMENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: createPatientWeMoveAssignment,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
          // return result.data.createPatientWeMoveAssignment.patientWeMoveAssignment
        })
    }
  },
  [types.REMOVE_PATIENT_WEMOVE_ASSIGNMENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: removePatientWeMoveAssignment,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
          return result.data.removeWeMoveAssignment.patientWeMoveAssignment
        })
    }
  },
  [types.CREATE_PATIENT_WORKSHEET_ASSIGNMENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: createPatientWorksheetAssignment,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.REMOVE_PATIENT_WORKSHEET_ASSIGNMENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: removePatientWorksheetAssignment,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.ADD_PATIENT_CLIPBOARD_GAMES]: {
    root: true,
    handler: async (store, payload) => {
      const variables = {
        gameIds: payload.games.map(g => g.id),
        patientIds: payload.patients.map(p => p.id)
      }
      return apolloProvider.defaultClient
        .mutate({
          mutation: addPatientClipboardGames,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.REMOVE_PATIENT_CLIPBOARD_GAMES]: {
    root: true,
    handler: async (store, payload) => {
      const variables = {
        gameIds: payload.games.map(g => g.id),
        patientIds: payload.patients.map(p => p.id)
      }
      return apolloProvider.defaultClient
        .mutate({
          mutation: removePatientClipboardGames,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.CLEAR_PATIENT_CLIPBOARD_GAMES]: {
    root: true,
    handler: async (store, payload) => {
      const variables = {
        patientIds: payload.games.map(p => p.id)
      }
      return apolloProvider.defaultClient
        .mutate({
          mutation: clearPatientClipboardGames,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.ADD_PATIENT_CLIPBOARD_WEMOVES]: {
    root: true,
    handler: async (store, payload) => {
      const variables = {
        weMoveIds: payload.weMoves.map(g => g.id),
        patientIds: payload.patients.map(p => p.id)
      }
      return apolloProvider.defaultClient
        .mutate({
          mutation: addPatientClipboardWeMoves,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.REMOVE_PATIENT_CLIPBOARD_WEMOVES]: {
    root: true,
    handler: async (store, payload) => {
      const variables = {
        weMoveIds: payload.weMoves.map(g => g.id),
        patientIds: payload.patients.map(p => p.id)
      }
      return apolloProvider.defaultClient
        .mutate({
          mutation: removePatientClipboardWeMoves,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.CLEAR_PATIENT_CLIPBOARD_WEMOVES]: {
    root: true,
    handler: async (store, payload) => {
      const variables = {
        patientIds: payload.weMoves.map(p => p.id)
      }
      return apolloProvider.defaultClient
        .mutate({
          mutation: clearPatientClipboardWeMoves,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.ADD_PATIENT_CLIPBOARD_WORKSHEETS]: {
    root: true,
    handler: async (store, payload) => {
      const variables = {
        worksheetIds: payload.worksheets.map(g => g.id),
        patientIds: payload.patients.map(p => p.id)
      }
      return apolloProvider.defaultClient
        .mutate({
          mutation: addPatientClipboardWorksheets,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.REMOVE_PATIENT_CLIPBOARD_WORKSHEETS]: {
    root: true,
    handler: async (store, payload) => {
      const variables = {
        worksheetIds: payload.worksheets.map(g => g.id),
        patientIds: payload.patients.map(p => p.id)
      }
      return apolloProvider.defaultClient
        .mutate({
          mutation: removePatientClipboardWorksheets,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.CLEAR_PATIENT_CLIPBOARD_WORKSHEETS]: {
    root: true,
    handler: async (store, payload) => {
      const variables = {
        patientIds: payload.worksheets.map(p => p.id)
      }
      return apolloProvider.defaultClient
        .mutate({
          mutation: clearPatientClipboardWorksheets,
          variables: variables
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
          // eventBus.$emit('patient-assignments-updated')
        })
    }
  },
  [types.REACTIVATE_PATIENT_GAME_ASSIGNMENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: reactivatePatientGameAssignment,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.CLONE_CLINIC_PLAY_LIST_FOR_PATIENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: cloneClinicPlaylistForPatient,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.CLONE_CLINIC_PLAY_LIST_FOR_CLINIC]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: cloneClinicPlaylistForClinic,
          variables: payload
        })
    }
  },
  [types.CLEAR_GAME_PLAY_LIST_ENTRIES]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: clearGamePlayListEntries,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  // [types.SEND_MESSAGE_TO_PATIENT]: {
  //   root: true,
  //   handler: async (store, payload) => {
  //     return apolloProvider.defaultClient
  //       .mutate({
  //         mutation: sendMessageToPatient,
  //         variables: payload
  //       })
  //       .then(result => {
  //         store.commit(types.REFRESH_KEY)
  //       })
  //   }
  // },
  [types.SEND_MESSAGE_REGARDING_PATIENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: sendMessageRegardingPatient,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.SEND_MESSAGE_FROM_PATIENT]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: sendMessageFromPatient,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.ACKNOWLEDGE_PATIENT_MESSAGES]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: acknowledgePatientMessages,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.ENABLE_PRESET_GAME_CONFIGURATIONS]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: enablePresetGameConfigurations,
          variables: payload
        })
        .then(result => {
          store.commit(types.UPDATE_WORKING_LIBRARY_SETTINGS, result.data.enablePresetGameConfigurations.appTenantLibrarySetting)
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DISABLE_PRESET_GAME_CONFIGURATIONS]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: disablePresetGameConfigurations,
          variables: payload
        })
        .then(result => {
          store.commit(types.UPDATE_WORKING_LIBRARY_SETTINGS, result.data.disablePresetGameConfigurations.appTenantLibrarySetting)
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.ENABLE_CUSTOM_GAME_CONFIGURATIONS]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: enableCustomGameConfigurations,
          variables: payload
        })
        .then(result => {
          store.commit(types.UPDATE_WORKING_LIBRARY_SETTINGS, result.data.enableCustomGameConfigurations.appTenantLibrarySetting)
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DISABLE_CUSTOM_GAME_CONFIGURATIONS]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: disableCustomGameConfigurations,
          variables: payload
        })
        .then(result => {
          store.commit(types.UPDATE_WORKING_LIBRARY_SETTINGS, result.data.disableCustomGameConfigurations.appTenantLibrarySetting)
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.PUBLISH_LIBRARY_GAME_CONFIGURATION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: publishLibraryGameConfiguration,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.UNPUBLISH_LIBRARY_GAME_CONFIGURATION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: unpublishLibraryGameConfiguration,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DEACTIVATE_GAME_CONFIGURATION_TEMPLATE]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: deactivateGameConfigurationTemplate,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DELETE_GAME_CONFIGURATION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteGameConfiguration,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DELETE_ACTIVITY_VERSION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteActivityVersion,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.SET_LIBRARY_GAME_STATUS]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: setLibraryGameStatus,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.REPORT_SCORE]: {
    root: true,
    handler: async (store, payload) => {
      const variables = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: reportScore,
          variables: variables
        })
        .then(result => {
          return result.data.reportScore.json
        })
    }
  },
  [types.SEND_CLINIC_DASHBOARD_MESSAGE]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: sendClinicDashboardMessage,
          variables: payload
        })
    }
  },
  [types.ADD_PALETTE_COLOR]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: addPaletteColor,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.DELETE_PALETTE_COLOR]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: deletePaletteColor,
          variables: payload
        })
        .then(result => {
          store.commit(types.REFRESH_KEY)
        })
    }
  },
  [types.REQUEST_GAME_DEMO]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: requestGameDemo,
          variables: payload
        })
        .then(result => {
          return result
        })
    }
  },
  [types.CREATE_ACTIVITY_EXPLORER_CONFIGURATION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: createActivityExplorerConfiguration,
          variables: payload
        })
        .then(result => {
          return result
        })
    }
  },
  [types.PROMOTE_USER_TO_GAME_LANGUAGE_MANAGER]: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: promoteUserToGameLanguageManager,
          variables: cleanPayload
        })
    }
  },
  [types.DEMOTE_USER_FROM_GAME_LANGUAGE_MANAGER]: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: demoteUserFromGameLanguageManager,
          variables: cleanPayload
        })
    }
  },
  [types.UPDATE_GAME_LANGUAGE_INSTRUCTIONS]: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: updateGameLanguageInstructions,
          variables: cleanPayload
        })
    }
  },
  [types.UPSERT_PATIENT_NOTE]: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertPatientNote,
          variables: cleanPayload
        })
    }
  },
  [types.DELETE_PATIENT_NOTE]: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: deletePatientNote,
          variables: cleanPayload
        })
    }
  },
  [types.REQUEST_ACTIVITY_DEPLOYMENT_TO_PROD]: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: requestActivityDeploymentToProd,
          variables: cleanPayload
        })
    }
  },
  [types.PROMOTE_USER_TO_ACTIVITY_DEVELOPER]: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: promoteUserToActivityDeveloper,
          variables: cleanPayload
        })
    }
  },
  [types.DEMOTE_USER_FROM_ACTIVITY_DEVELOPER]: {
    root: true,
    handler: async (store, payload) => {
      const cleanPayload = await scrubPayload(payload)
      return apolloProvider.defaultClient
        .mutate({
          mutation: demoteUserFromActivityDeveloper,
          variables: cleanPayload
        })
    }
  },
  [types.SET_PATIENT_OD_OS]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: setPatientOdOs,
          variables: payload
        })
    }
  },
  [types.UPSERT_APP_USER_DEVICE]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertAppUserDevice,
          variables: payload
        })
    }
  },
  [types.DELETE_APP_USER_DEVICE]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: deleteAppUserDevice,
          variables: payload
        })
    }
  },
  [types.UPSERT_APP_USER_DEVICE_COLOR_CALIBRATION]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: upsertAppUserDeviceColorCalibration,
          variables: payload
        })
    }
  }
}

// mutations
const mutations = {
  [types.RESET_OVB] (state) {
    Object.assign(state, getDefaultState())
  },
  [types.REFRESH_KEY] (state) {
    state.refreshKey += 1
  },
  [types.SET_SIDENAV_STATE] (state, payload) {
    state.sidenavState = payload
  },
  [types.ADD_CLIPBOARD_GAMES] (state, payload) {
    const gameIds = payload.games.map(g => g.id)
    const clipboardId = DEFAULT_CLIPBOARD_ID
    const clipboard = state.clipboardGames[clipboardId] || []
    const newClipboard = [
      ...clipboard.filter(g => gameIds.indexOf(g.id) === -1),
      ...state.games.filter(g => gameIds.indexOf(g.id) > -1)
    ]
    state.clipboardGames = {
      ...state.clipboardGames,
      [clipboardId]: newClipboard
    }
  },
  [types.REMOVE_CLIPBOARD_GAMES] (state, payload) {
    const gameIds = payload.games.map(g => g.id)
    const clipboardId = DEFAULT_CLIPBOARD_ID
    const clipboard = state.clipboardGames[clipboardId] || []
    const newClipboard = [...clipboard.filter(g => gameIds.indexOf(g.id) === -1)]
    state.clipboardGames = {
      ...state.clipboardGames,
      [clipboardId]: newClipboard
    }
  },
  [types.CLEAR_CLIPBOARD_GAMES] (state, payload) {
    const clipboardId = DEFAULT_CLIPBOARD_ID
    state.clipboardGames = {
      ...state.clipboardGames,
      [clipboardId]: []
    }
  },
  [types.SET_GAMES] (state, payload) {
    state.games = payload
  },
  [types.SET_WEMOVES] (state, payload) {
    state.weMoves = payload
  },
  [types.SET_WORKSHEETS] (state, payload) {
    state.worksheets = payload
  },
  [types.SET_THERAPY_CATEGORIES] (state, payload) {
    state.therapyCategories = payload
  },
  [types.SET_SILVER_ACTIVITIES] (state, payload) {
    state.silverActivities = payload
  },
  [types.SET_WORKING_ARTIFACT_TYPE] (state, payload) {
    state.workingArtifactType = payload
  },
  [types.SET_ALL_LIBRARIES] (state, payload) {
    state.libraries = payload
  },
  [types.SET_WORKING_LIBRARY] (state, payload) {
    state.workingLibrary = payload
  },
  [types.UPDATE_WORKING_LIBRARY_SETTINGS] (state, payload) {
    state.workingLibrary = {
      ...state.workingLibrary,
      appTenantLibrarySettings: {
        ...state.workingLibrary.appTenantLibrarySettings,
        nodes: [
          ...state.workingLibrary.appTenantLibrarySettings.nodes.filter(ats => ats.libraryId !== payload.libraryId),
          payload
        ]
      }
    }
  },
  [types.SET_PATIENT_FOCUS] (state, payload) {
    state.recentPatients = [
      payload,
      ...state.recentPatients.filter(p => p.id !== payload.id)
    ]

    state.patientFocus = payload
  },
  [types.REMOVE_RECENT_PATIENT] (state, payload) {
    state.recentPatients = [
      ...state.recentPatients.filter(p => p.id !== payload.id)
    ]
  },
  [types.CLEAR_RECENT_PATIENTS] (state) {
    state.recentPatients = []
  },
  [types.SET_CLINIC_PLAYLIST_FOCUS] (state, payload) {
    state.recentClinicGamePlayLists = [
      payload,
      ...state.recentClinicGamePlayLists.filter(p => p.id !== payload.id)
    ]
    state.clinicGamePlayListFocus = payload
  },
  [types.REMOVE_RECENT_CLINIC_PLAYLIST] (state, payload) {
    state.recentClinicGamePlayLists = [
      ...state.recentClinicGamePlayLists.filter(p => p.id !== payload.id)
    ]
  },
  [types.CLEAR_RECENT_CLINIC_PLAYLISTS] (state) {
    state.recentClinicGamePlayLists = []
  },
  [types.SET_DEMO_SESSION] (state, payload) {
    state.demoSession = payload
    if (!payload) {
      state.currentDemoConfiguration = null
    }
  },
  [types.SET_CURRENT_DEMO_CONFIGURATION] (state, payload) {
    state.currentDemoConfiguration = payload
  },
  [types.SET_CURRENT_ACTIVITY_EXPLORER_CONFIGURATION] (state, payload) {
    state.currentActivityExplorerConfiguration = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
