<script>
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
gsap.registerPlugin(CSSPlugin)
export default {
  name: 'TestimonialsBlock',
  components: {
  },
  data () {
    return {
      playbackCount: 0,
      currentTgtNum: 0,
      isVisible: false, // if component is visible/scrolled to on the screen, within tolerances
      isPaused: false,
      tl: null,
      initialized: false, // set for when refs are available
      playForward: true
    }
  },
  computed: {
    currentTgt () {
      return this.$refs['testimonial' + this.currentTgtNum]
    },
    backgroundImage () {
      return require('@/assets/promo/img/home/testimonials/activities@0.75x.webp')
    }
  },
  watch: {
    isVisible (val) {
      if (val) {
        this.tl.play()
      } else {
        this.tl.pause()
      }
    }
  },
  methods: {
    visibilityChanged (isVisible) {
      this.isVisible = isVisible
    },
    createTimeline () {
      const tl = gsap.timeline({ repeat: -1 })
      const delayLength = 6
      const duration = 3
      tl.pause()
      tl.set(this.$refs.testimonial0, { css: { opacity: 0, left: '10rem' } })
      tl.set(this.$refs.testimonial1, { css: { opacity: 0, left: '10rem' } })
      tl.set(this.$refs.testimonial2, { css: { opacity: 0, left: '10rem' } })
      tl.set(this.$refs.testimonial3, { css: { opacity: 0, left: '10rem' } })
      tl.set(this.$refs.testimonial4, { css: { opacity: 0, left: '10rem' } })
      tl.set(this.$refs.testimonial5, { css: { opacity: 0, left: '10rem' } })
      tl.set(this.$refs.testimonial6, { css: { opacity: 0, left: '10rem' } })
      tl.set(this.$refs.testimonial7, { css: { opacity: 0, left: '10rem' } })
      tl.set(this.$refs.testimonial8, { css: { opacity: 0, left: '10rem' } })
      tl.set(this.$refs.testimonial9, { css: { opacity: 0, left: '10rem' } })

      tl.to(this.$refs.testimonial0, { css: { opacity: 1, left: 0 }, ease: 'power4.out', duration: duration })
      tl.to(this.$refs.testimonial0, { css: { opacity: 0, left: '-10rem' }, ease: 'power4.out', duration: duration, delay: delayLength })
      tl.to(this.$refs.testimonial1, { css: { opacity: 1, left: 0 }, ease: 'power4.out', duration: duration }, '<')
      tl.to(this.$refs.testimonial1, { css: { opacity: 0, left: '-10rem' }, ease: 'power4.out', duration: duration, delay: delayLength })
      tl.to(this.$refs.testimonial2, { css: { opacity: 1, left: 0 }, ease: 'power4.out', duration: duration }, '<')
      tl.to(this.$refs.testimonial2, { css: { opacity: 0, left: '-10rem' }, ease: 'power4.out', duration: duration, delay: delayLength })
      tl.to(this.$refs.testimonial3, { css: { opacity: 1, left: 0 }, ease: 'power4.out', duration: duration }, '<')
      tl.to(this.$refs.testimonial3, { css: { opacity: 0, left: '-10rem' }, ease: 'power4.out', duration: duration, delay: delayLength })
      tl.to(this.$refs.testimonial4, { css: { opacity: 1, left: 0 }, ease: 'power4.out', duration: duration }, '<')
      tl.to(this.$refs.testimonial4, { css: { opacity: 0, left: '-10rem' }, ease: 'power4.out', duration: duration, delay: delayLength })
      tl.to(this.$refs.testimonial5, { css: { opacity: 1, left: 0 }, ease: 'power4.out', duration: duration }, '<')
      tl.to(this.$refs.testimonial5, { css: { opacity: 0, left: '-10rem' }, ease: 'power4.out', duration: duration, delay: delayLength })
      tl.to(this.$refs.testimonial6, { css: { opacity: 1, left: 0 }, ease: 'power4.out', duration: duration }, '<')
      tl.to(this.$refs.testimonial6, { css: { opacity: 0, left: '-10rem' }, ease: 'power4.out', duration: duration, delay: delayLength })

      tl.to(this.$refs.testimonial7, { css: { opacity: 1, left: 0 }, ease: 'power4.out', duration: duration }, '<')
      tl.to(this.$refs.testimonial7, { css: { opacity: 0, left: '-10rem' }, ease: 'power4.out', duration: duration, delay: delayLength })

      tl.to(this.$refs.testimonial8, { css: { opacity: 1, left: 0 }, ease: 'power4.out', duration: duration }, '<')
      tl.to(this.$refs.testimonial8, { css: { opacity: 0, left: '-10rem' }, ease: 'power4.out', duration: duration, delay: delayLength })

      tl.to(this.$refs.testimonial9, { css: { opacity: 1, left: 0 }, ease: 'power4.out', duration: duration }, '<')
      tl.to(this.$refs.testimonial9, { css: { opacity: 0, left: '-10rem' }, ease: 'power4.out', duration: duration, delay: delayLength })
      return tl
    }
  },
  mounted () {
    setTimeout(x => {
      this.$nextTick(() => {
        this.tl = this.createTimeline()
      })
    }, 500)
  },
  beforeDestroy () {
    this.tl.pause()
    this.tl.kill()
    this.tl = null
  }
}
</script>
<template>
  <article
    class="testimonials-block"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 300,
    }"
  >
    <div
      class="image-container"
      :style="{
              backgroundImage: `url(${backgroundImage})`,
            }"
    />
    <div
    class="image-overlay"
    />
    <div class="container">
      <div class="testimonials-container">
        <div class="testimonials-row">
          <div
            class="testimonial"
            id="testimonial0"
            ref="testimonial0"
          >
            <div class="testimonial-body is-size-5-desktop">
              &ldquo;Great tool in these changing times to allow the doctor to not only program and adjust the assigned vision therapy activities but to also monitor the patient’s compliance and progress with the activities. I also like the variety of activities and that the platform is always upgrading & adding more activities.&rdquo;
              <div class="testimonial-author is-size-5-desktop">Dr. Mary Van Hoy, OD<br>Eyes for Wellness</div>
            </div>
          </div>
          <div
            class="testimonial"
            ref="testimonial1"
            id="testimonial1"
          >
            <div class="testimonial-body is-size-5-desktop">
              &ldquo;This has made VT home activities for kids and adults much easier! The games are very fun, bright, and engaging, and work on all of the skills we need them to. Our patients love it and so do we!&rdquo;
              <div
                class="testimonial-author is-size-5-desktop"
              >
                Elizabeth Markley, VT<br>Katmai Eye
              </div>
            </div>
          </div>
          <div
            class="testimonial"
            id="testimonial2"
            ref="testimonial2"
          >
            <div
              class="testimonial-body is-size-5-desktop"
            >
              &ldquo;OVB has truly made our vision therapy patients more involved with their progress and it has made their VT experience a lot more fun.&rdquo;
              <div
                class="testimonial-author is-size-5-desktop"
              >
                Melanie Todd, COVT<br>Triangle Visions
              </div>
            </div>
          </div>
          <div
            class="testimonial"
            id="testimonial3"
            ref="testimonial3"
          >
            <div
              class="testimonial-body is-size-5-desktop"
            >
              &ldquo;Our Visual Brain has been a great value added in our clinics. The games are fun, engaging and really get results! Their team is also very open and responsive to feedback and suggestions - making their platform a solid long-term addition to our programs.&rdquo;
              <div
                class="testimonial-author is-size-5-desktop"
              >
                Dr. Paul Rollett, OD
              </div>
            </div>
          </div>
          <div
            class="testimonial"
            id="testimonial4"
            ref="testimonial4"
          >
            <div
              class="testimonial-body is-size-5-desktop"
            >
              &ldquo;As a vision therapist, I'm very grateful that Our Visual Brain exists! The games are fun and engaging for patients, and I appreciate that the website is frequently updated so there is always something new to try. I have also emailed their team directly more than once to ask about technical questions, and their response time is fantastic.&rdquo;
              <div
                class="testimonial-author is-size-5-desktop"
              >
                Lorelei Feeny, COVT<br>Triangle Visions
              </div>
            </div>
          </div>
          <div
            class="testimonial"
            id="testimonial5"
            ref="testimonial5"
          >
            <div
              class="testimonial-body is-size-5-desktop"
            >
              &ldquo;We have been using OVB for several years at our clinic. It is easy to use for patients and simple for them. The simplicity, game-based nature, and fair price of the therapy activities make it a no-brainer for patients. OVB has amazing customer loyalty and they are open to suggestions and changes. I can't wait for the future of OVB, I would recommend it to any clinic.&rdquo;
              <div
                class="testimonial-author is-size-5-desktop"
              >
                Dr. Steven Blake, OD
              </div>
            </div>
          </div>
          <div
            class="testimonial"
            id="testimonial6"
            ref="testimonial6"
          >
            <div
              class="testimonial-body is-size-5-desktop"
            >
              &ldquo;OVB is a powerful vision therapy tool that engages patients of all ages. Using OVB as a homework tool has increased home activity adherence and improved our patient outcomes.&rdquo;
              <div
                class="testimonial-author is-size-5-desktop"
              >
                Sandi Pollock, COVT<br>Triangle Visions
              </div>
            </div>
          </div>
          <div
            class="testimonial"
            id="testimonial7"
            ref="testimonial7"
          >
            <div
              class="testimonial-body is-size-5-desktop"
            >
              &ldquo;I have been a fan of Jennifer Powell's work since she started her original FAT CAT home vision therapy products to help doctors and therapists better serve their patients.  Her updated, and fully customizable online "Our Visual Brain" vision therapy platform is easy to use, monitors progress,  and provides a great tool for doctors and therapists to plan at home vision skill practice.  All activities can be appropriately individualized to meet the vision needs of the user.   Be ready for loads of fun while training visual perceptual skills, because that's what Our Visual Brain provides. Truly amazing!&rdquo;
              <div
                class="testimonial-author is-size-5-desktop"
              >
                Patricia Andrich<br>Triangle Visions
              </div>
            </div>
          </div>
          <div
            class="testimonial"
            id="testimonial8"
            ref="testimonial8"
          >
            <div
              class="testimonial-body is-size-5-desktop"
            >
              &ldquo;We first met Jennifer Powell at our annual OEPF SW Congress last January and were so impressed with the quality of activities in the Our Visual Brain program.  Since adding OVB to our practice shortly thereafter, we have been increasingly happy with our decision to do so.  Jennifer is so responsive to our questions and patient needs, and our patients of all ages, enjoy the activities.  It has become a vital part of our treatment, especially for our patients with strabismus and amblyopia, who can now utilize a wide range of red/green and MFBF activities on a daily basis.  We highly recommend OVB for any and all VT practices.&rdquo;
              <div
                class="testimonial-author is-size-5-desktop"
              >
                Vision Therapy of San Antonio
              </div>
            </div>
          </div>
          <div
            class="testimonial"
            id="testimonial9"
            ref="testimonial9"
          >
            <div
              class="testimonial-body is-size-5-desktop"
            >
              &ldquo;As a Neuro-optometrist, I’m really impressed with OVB and can’t say enough about how much this will help my practice. Keeps patient’s interested and parents in the loop. Customer service is excellent and activities are engaging and relevant. Love love love.&rdquo;
              <div
                class="testimonial-author is-size-5-desktop"
              >
                Annie Reuter
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss">
$containerHeightMobile: 32rem;
$containerHeightTablet: 22rem;
$containerHeightDesktop: 26rem;
.testimonials-block {
  height: $containerHeightMobile;
  display: block;
  background-image: linear-gradient(to right, #faf3f0, #fdfaf9);
  border-top: 1px solid $gray-lighter;
  border-bottom: 1px solid $gray-lighter;
  @include tablet {
    height: $containerHeightTablet;
  }
  @include desktop {
    height: $containerHeightDesktop;
  }
}
.testimonial-body {
  box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0px 0 1px rgba(10, 10, 10, 0.02);
}
.testimonials-container {
  position: relative;
  height: $containerHeightMobile;
  //top: -44rem;
  top: calc(-1 * ($containerHeightMobile * 2));
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @include tablet {
    height: $containerHeightTablet;
    top: calc(-1 * ($containerHeightTablet * 2));
  }
  @include desktop {
    height: $containerHeightDesktop;
    top: calc(-1 * ($containerHeightDesktop * 2));
  }
}
.testimonials-row {
  display: inline-grid;
  & > * {
    grid-row: 1;
    grid-column: 1;
  }
}
.testimonial {
  position: relative;
  min-width: 20rem;
  max-width: 20rem;
  display: flex;
  opacity: 0;
  align-items: center;
  justify-content: center;
  @include tablet {
    min-width: 25rem;
    max-width: 30rem;
  }
}
.image-container {
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: 0 0;
  background-repeat: no-repeat;
  height: $containerHeightMobile;
  opacity: 0;
  @include tablet {
    height: $containerHeightTablet;
  }
  @include desktop {
    height: $containerHeightDesktop;
  }
}
.image-overlay {
  position: relative;
  //top: -22rem;
  //top: calc(-1 * ($containerHeightMobile * 2));
  top: calc(-1 * $containerHeightMobile);
  opacity: 0.5;
  left: 0;
  width: 100%;
  overflow: hidden;
  background: linear-gradient(45deg,hsla(200,62%,39%,.8),hsla(354, 84%, 60%, .3));
  height: $containerHeightMobile;
  @include tablet {
    height: $containerHeightTablet;
    //top: calc(-1 * ($containerHeightTablet * 2));
    top: calc(-1 * $containerHeightTablet);
  }
  @include desktop {
    height: $containerHeightDesktop;
    top: calc(-1 * $containerHeightDesktop);
  }
}
</style>
