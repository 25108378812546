<script>
export default {
  name: 'BookPromo'
}
</script>

<template>
  <div class="book-promo pt-4 pb-6">
    <section class="book-promo-content container mobile-container">
      <header>
        <h2 class="text-outline-dark book-promo-title">Check out the Bookstore!</h2>
        <div class="book-promo-subtitle">Books from our friends and advisors</div>
      </header>
      <div class="book-promo-row is-hidden-mobile mt-5">
        <div>
          <div class="book-image">
            <b-image
              src="img/promo/book/seeandsay@0.25x.webp"
              ratio="401by310"
            />
          </div>
        </div>
        <div>
          <div class="">
            <div class="book-title">See and Say, See and Say, Find It, Say It, Dream It, Do It!</div>
            <div class="book-byline">by Lisa Erskine Knopp</div>
          </div>
          <div  class="is-size-5 my-4">
            This "Seussical" rhyming flip book story is about three animal friends on a playful "See and Say" walking adventure, leading the characters/readers to cloud and nighttime sky viewing for building the visual skills of Anti-Suppression, Figure Ground, and more.
          </div>
          <div class="">
            <router-link
              class="button is-outlined is-light"
              :to="{ name: 'StoreView', params: {stickyNav: true}}"
            ><span class="icon is-medium mr-1"><i class="mdi mdi-book-open-variant"></i></span>Bookstore<span class="icon is-medium ml-1"><i class="mdi mdi-24px mdi-arrow-right"/></span></router-link>
          </div>
        </div>
      </div>
      <div class="mt-5 is-hidden-tablet">
        <div class="mb-4">
          <div class="book-title">See and Say, See and Say, Find It, Say It, Dream It, Do It</div>
          <div class="book-byline">by Lisa Erskine Knopp</div>
        </div>
        <div class="book-image">
          <b-image
            src="img/promo/book/seeandsay@0.25x.webp"
            ratio="401by310"
          />
        </div>
        <div  class="is-size-5-tablet mt-2 mb-4">
          This "Seussical" rhyming flip book story is about three animal friends on a playful "See and Say" walking adventure, leading the characters/readers to cloud and nighttime sky viewing for building the visual skills of Anti-Suppression, Figure Ground, and more.
        </div>
        <div class="">
          <router-link
            class="button is-outlined is-light"
            :to="{ name: 'StoreView', params: {stickyNav: true}}"
          ><span class="icon is-medium mr-1"><i class="mdi mdi-book-open-variant"></i></span>Bookstore<span class="icon is-medium ml-1"><i class="mdi mdi-24px mdi-arrow-right"/></span></router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<style lang="scss" scoped>
  .book-promo {
    display: flex;
    background-color: $tangerine;
  }
  .book-promo-content {
    color: $white;
  }
  .book-promo-row {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    flex-wrap: nowrap;
  }
  .book-promo-title {
    font-family: $family-semibold;
    font-size: 1.7rem;
    line-height: 1.9rem;
    @include tablet {
      font-size: 2rem;
      line-height: 2.5rem;
    }
  }
  .book-promo-subtitle {
    font-size: $size-5;
    line-height: $size-5;
    @include tablet {
      font-size: 1.5rem;
      line-height: 1.7rem;
    }
  }
  .book-title {
    font-size: $size-5;
    line-height: $size-5;
    @include tablet {
      font-size: 1.6rem;
      line-height: 1.6rem;
    }
  }
  .book-byline {
    font-size: $size-6;
    line-height: $size-6;
    margin-top: 0.25rem;
    @include tablet {
      font-size: $size-5;
      line-height: $size-5;
      margin-top: 0.25rem;
    }
  }
  .book-image {
    box-shadow: rgba(0, 0, 0, 0.2) 0 10px 50px;
    min-width: 15rem;
    max-width: 15rem;
    @include tablet {
      min-width: 18rem;
      max-width: 18rem;
      margin-right: 1rem;
    }
  }
</style>
