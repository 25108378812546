<template>
  <div class="column">
    <!-- <div
      class="nav-avatar-info"
      v-if="$userHasPermission('p:app-tenant-scope')"
    > -->
    <div
      class="nav-avatar-info"
    >
      <div class="app-tenant-scope">
        <b-field>
          <template #label>
            <router-link
              :to="{ name: 'AppTenantManager' }"
              class="app-tenant-scope"
            >Change Clinic Focus</router-link>
          </template>
          <div class="column">
            <div>
              <router-link
                :to="{ name: 'ClinicDashboard' }"
                class="app-tenant-scope"
              >{{appTenantScope}}</router-link>
            </div>
            <hr>
            <b-button
              v-clipboard:copy="graphiqlHeaders"
              v-clipboard:success="onCopyGraphiqlHeaders"
              size="is-small"
            >gql headers</b-button>
          </div>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    appTenantScope () {
      return this.$store.state.user.scopeAppTenant ? this.$store.state.user.scopeAppTenant.name : null
    },
    graphiqlHeaders () {
      const fnbs = JSON.stringify(this.$store.state.user.scopeAppTenant)
      const fnbsEncoded = btoa(unescape(encodeURIComponent(fnbs)))

      const fnbi = JSON.stringify(this.$store.state.user.userInfo)
      const fnbiEncoded = btoa(unescape(encodeURIComponent(fnbi)))

      const appUserDeviceId = this.$store.state.user.userDevice ? this.$store.state.user.userDevice.id : null

      const headers = {
        fnbs: fnbsEncoded,
        fnbi: fnbiEncoded,
        // authorization: `bearer ${this.$store.state.user.userToken}`,
        activityDeviceId: appUserDeviceId
      }

      return JSON.stringify(headers, null, 2)
    }
  },
  methods: {
    onCopyGraphiqlHeaders () {
      alert('Copied')
    }
  }
}
</script>

<style>

</style>
