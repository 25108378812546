<script>
export default {
  name: 'VideoBackground',
  props: {
    height: {
      type: [String, Number],
      default: null
    },
    width: {
      type: [String, Number],
      default: null
    },
    aspectRatio: {
      type: String,
      default: null
    },
    hasGradiant: {
      type: Boolean,
      default: true
    },
    gradiantOverlay: {
      type: String,
      default: 'linear-gradient(45deg, #2a4ae430, #fb949e6b)'
    },
    videoSource: {
      type: String,
      default: null
    },
    objectFit: {
      type: String,
      default: 'cover'
    },
    posterImage: {
      type: String,
      default: null
    }
  },
  computed: {
    overlayStyle () {
      return this.hasGradiant ? `${this.sectionHeight} background: ${this.gradiantOverlay}` : this.sectionHeight
    },
    sectionDimensions () {
      const heightString = this.height ? `height: ${this.height}; ` : ''
      const widthString = this.width ? `width: ${this.width}; ` : ''
      const aspectRatio = this.aspectRatio ? `aspect-ratio: ${this.aspectRatio}; ` : ''
      return `${heightString}${widthString}${aspectRatio} max-width: 100vw;`
    }
  }
}
</script>
<template>
  <div
    class="video-background-component"
  >
    <div
      ref="player"
      :style="sectionDimensions"
      class="player player-container"
    >
      <div :style="sectionDimensions" class="slide">
        <div :style="sectionDimensions" class="video-slide">
          <div
            class="video-buffering"
            :style="{
              backgroundImage: `url(${posterImage})`,
            }"
          />
          <div
            key="videoContainerOne"
            class="video-container"
          >
            <video
              ref="video"
              autoplay
              playsinline
              disablepictureinpicture
              loop
              preload="auto"
              class="video-element"
              muted
            >
              <source
                ref="videoSource"
                :src="videoSource" type="video/webm"
              />
            </video>
          </div>
        </div>
      </div>
      <div
        :style="overlayStyle"
        class="slide-overlay"
      >
        <div
          class="slide-content container"
        >
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.video-background-component {
  display: block;
  //overflow: hidden;
}
.player {
  display: block;
  position: relative;
  //background-color: #fff;
  //width: 100vw;
  opacity: 1;
  z-index: 0;
  overflow: hidden;
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  //background: #fff;

}
.video-slide {
  //background-color: #fff;
}
.video-buffering {
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  opacity: 1;
}

video {
  visibility: visible;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
.video-element {
  object-fit: cover;
  aspect-ratio: 16 / 9;
}
.slide-overlay {
  position: relative;
  top: 0;
  z-index: 15;
}
.slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
}
.banner {
  display: block;
  opacity: 1;
  //width: 100vw;
  position: relative;
  z-index: 30;
  top: -200%;
  overflow: hidden;
}
.content-box {
  background: hsla(360, 100%, 100%, 0.1);
  backdrop-filter: blur(3px);
  padding: 0 1rem 1rem 1rem;
}
</style>
