<script>
export default {
  name: 'BreadcrumbNavigation',
  props: {
  },
  data () {
    return {
      pageLabel: '',
      overrideArray: []
    }
  },
  computed: {
    isOverride () {
      return this.$route.meta.breadcrumb ? this.$route.meta.breadcrumb.override ? this.$route.meta.breadcrumb.override : false : false
    },
    breadcrumbs () {
      let crumbs = []
      if (!this.isOverride) {
        let builtRoute = ''
        const splitArray = this.$route.fullPath.split('/')
        splitArray.splice(0, 1)
        for (const value of splitArray) {
          const lastRoute = builtRoute
          builtRoute += '/' + value
          if ((builtRoute !== lastRoute) && ((lastRoute + '/') !== builtRoute)) {
            const resolved = this.$router.resolve(builtRoute).resolved
            if (resolved.meta.breadcrumb) {
              crumbs.push(resolved)
            }
          }
        }
      } else {
        crumbs = this.overrideArray
      }
      return crumbs
    }
  },
  watch: {
    '$route.params': {
      handler: function (val) {
        this.pageLabel = val.label || this.$route.meta.label
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setOverride (payload) {
      this.overrideArray = payload
    }
  },
  created () {
    this.$eventHub.$on('OVERRIDE_BREADCRUMBS', (e) => {
      this.overrideArray = e
    })
  }
}
</script>

<template>
  <nav
    class="breadcrumb-navigation"
  >
    <ul
      v-if="breadcrumbs.length"
      class="is-hidden-mobile"
    >
      <li
        v-for="(crumb, i) in breadcrumbs"
        :key="i"
      >
        <span>
          <template v-if="isOverride">
            <template v-if="i !== (breadcrumbs.length -1)">
              <router-link
                :to="crumb"
              >{{ crumb.params.name }}
              </router-link>
            </template>
            <template v-else>
              <span
                class="is-hidden-mobile"
              >{{ crumb.params.name | truncate(30) }}</span>
            </template>
          </template>
          <template v-else>
            <template v-if="i !== (breadcrumbs.length -1)">
              <router-link
                v-if="!crumb.meta.breadcrumb.dynamic"
                :to=" { path: crumb.fullPath }"
              >
                {{ crumb.meta.breadcrumb.label | truncate(30) }}
              </router-link>
              <router-link
                v-else
                :to=" { path: crumb.fullPath }"
              >
                {{ crumb.meta.breadcrumb.label | truncate(30) }}{{ crumb.params[crumb.meta.breadcrumb.key] | truncate(30) }}
              </router-link>
            </template>
            <template v-else>
              <span
                class="is-hidden-mobile"
                v-if="!crumb.meta.breadcrumb.dynamic"
              >{{ crumb.meta.breadcrumb.label | truncate(30) }}</span>
              <span
                class="is-hidden-tablet"
                v-if="!crumb.meta.breadcrumb.dynamic"
              >{{ crumb.meta.breadcrumb.label | truncate(30) }}</span>
              <span
                v-else
              >{{ crumb.meta.breadcrumb.label | truncate(30) }}{{ crumb.params[crumb.meta.breadcrumb.key] | truncate(30) }}</span>
            </template>
          </template>
        </span>
      </li>
    </ul>
    <span
      class="is-hidden-mobile"
      v-if="$route.name === 'home'"
    >{{ pageLabel | lowercase }}</span>
    <div
      class="is-hidden-tablet page-label"
    >{{ pageLabel | truncate(30) }}</div>
  </nav>
</template>

<style lang="scss">
  .breadcrumb-navigation {
    font-size: $size-6;
    @include tablet {
      display: block;
      font-size: $size-6;
      white-space: nowrap;
    }
    li {
      display: inline-block;
      &:before {
        content: '';
      }
    }
    li:first-child {
      &:before {
        content: '';
      }
    }
    li:not(:last-child) {
      &::after {
        content: '>';
        font-size: $size-6;
        margin: 0 0.2rem;
      }
    }
    .page-label {
      font-size: 1rem;
      line-height: 1rem;
      color: $gray;
      font-family: $family-semibold;
    }
  }
</style>
