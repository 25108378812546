/*
Tour module:
This is used for tours that cross pages.  Most of the tours are standalone on their own page, some require finishing on another page; this is for the latter
A page that has a partial tour will check to see if a tour is active and which step to jump to.
This module is passed an object with the target tour and step:
{
  targetTour:
  targetStep:
}
 */

const types = {
  SET_TOUR_TARGET: 'SET_TOUR_TARGET',
  CLEAR_TOUR_TARGET: 'CLEAR_TOUR_TARGET'
}

const getDefaultState = () => {
  return {
    tourTarget: null
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  tourTarget: state => state.tourTarget
}

// actions
const actions = {
  setTourTarget ({ commit }, payload) {
    commit(types.SET_TOUR_TARGET, payload)
  },
  clearTourTarget ({ commit }) {
    commit(types.CLEAR_TOUR_TARGET)
  }
}

// mutations
const mutations = {
  [types.SET_TOUR_TARGET] (state, payload) {
    state.tourTarget = payload
  },
  [types.CLEAR_TOUR_TARGET] (state) {
    state.tourTarget = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
