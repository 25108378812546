import apolloProvider from '@/graphql'

// import currentAppUser from './query/currentAppUser'

import omitDeep from 'omit-deep'
import createLicensedAppUser from '@/graphql/mutation/createLicensedAppUser.graphql'
import upsertRegistration from '@/graphql/mutation/upsertRegistration.graphql'
import subscribeToLicensePackExistingTenant from '@/graphql/mutation/subscribeToLicensePackExistingTenant.graphql'
import createTherapyCategory from '@/graphql/mutation/createTherapyCategory.graphql'
import upsertGame from '@/graphql/mutation/upsertGame.graphql'
import deleteTherapyCategory from '@/graphql/mutation/deleteTherapyCategory.graphql'
import deleteGame from '@/graphql/mutation/deleteGame.graphql'

const scrubPayload = async (payload) => omitDeep(payload, ['__typename'])

const errHandler = (response) => {
  if (response.errors.length > 0) {
    alert('oh no, oh no, oh no no no no no')
  }
  return response
}

export default {
  preSignup ({ commit }) {
    commit('preSignup')
  },

  onLogin (context) {
    // console.log('onLogin', Object.keys(context))
  },
  // currentAppUser,
  async createNewLicensedUser (store, payload) {
    const cleanPayload = await scrubPayload(payload)
    // console.log(JSON.stringify(cleanPayload, null, 2))
    try {
      const result = await apolloProvider.defaultClient.mutate({
        mutation: createLicensedAppUser,
        variables: cleanPayload
      })
      return result
    } catch (error) {
      const errResult = await errHandler(error)
      return errResult
    }
  },
  async upsertRegistration (store, payload) {
    const cleanPayload = await scrubPayload(payload)
    return apolloProvider.defaultClient
      .mutate({
        mutation: upsertRegistration,
        variables: cleanPayload
      })
      .then(result => {
        return result.data.upsertRegistration.json
      })
  },
  async subscribeToLicensePackExistingTenant (store, payload) {
    const cleanPayload = await scrubPayload(payload)
    return apolloProvider.defaultClient
      .mutate({
        mutation: subscribeToLicensePackExistingTenant,
        variables: cleanPayload
      })
  },
  async createTherapyCategory (store, payload) {
    const cleanPayload = await scrubPayload(payload)
    return apolloProvider.defaultClient
      .mutate({
        mutation: createTherapyCategory,
        variables: cleanPayload
      })
  },
  async upsertGame (store, payload) {
    const cleanPayload = await scrubPayload(payload)
    return apolloProvider.defaultClient
      .mutate({
        mutation: upsertGame,
        variables: cleanPayload
      })
  },
  async deleteTherapyCategory (store, payload) {
    const cleanPayload = await scrubPayload(payload)
    return apolloProvider.defaultClient
      .mutate({
        mutation: deleteTherapyCategory,
        variables: cleanPayload
      })
  },
  async deleteGame (store, payload) {
    const cleanPayload = await scrubPayload(payload)
    return apolloProvider.defaultClient
      .mutate({
        mutation: deleteGame,
        variables: cleanPayload
      })
  }
}
