<template>
  <div v-if="recentPatients.length > 0">
    <b-field label="Recent Patients">
      <div class="column">
        <div v-for="patient in recentPatients" :key="patient.id">
          <b-tag
            closable
            @close="removePatient(patient.id)"
          >
            <router-link
              class="is-small"
              :to="{ name: 'PatientTherapyDashboard', params: { patientName: spaceDasher(`${patient.contact.firstName.slice(0,1)}. ${patient.contact.lastName}`), patientId: patient.id } }"
            >{{`${patient.contact.firstName} ${patient.contact.lastName}`}}</router-link>
          </b-tag>
        </div>
        <b-button class="is-small" @click="clearRecentPatients">Clear All</b-button>
      </div>
    </b-field>
  </div>
</template>

<script>
export default {
  computed: {
    recentPatients () {
      return this.$store.state.ovb.recentPatients
    },
    patientFocus () {
      return this.$store.state.ovb.patientFocus
    },
    patientFullName () {
      return `${this.patientFocus.contact.firstName} ${this.patientFocus.contact.lastName}`
    }
  },
  methods: {
    removePatient (patientId) {
      this.$store.commit('ovb/REMOVE_RECENT_PATIENT', { id: patientId })
    },
    clearRecentPatients () {
      this.$store.commit('ovb/CLEAR_RECENT_PATIENTS')
    }
  }
}
</script>

<style>

</style>
