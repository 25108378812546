const install = function (Vue, options) {
  // 1. add global method or property
  Vue.prototype.$userHasPermission = function (permissionKey) {
    if (!this.$store.state.user) return false
    if (!this.$store.state.user.userInfo) return false
    if (!this.$store.state.user.userInfo.permissions) return false

    const userPermissions = this.$store.state.user.userInfo.permissions
    const permissionKeys = Array.isArray(permissionKey) ? permissionKey : [permissionKey]

    const found = userPermissions.some(r => permissionKeys.includes(r))
    return found
    // return allPermissions.indexOf(permissionKey) > -1
  }
}

const AppPermissionsPlugin = {
  install: install
}

export { AppPermissionsPlugin }
