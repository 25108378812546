// Page state for the all Worksheets Browsers within the app
// list of all WorksheetsBrowser parent views
const parentViews = ['VideoExplorer', 'PatientMotionManagement']

const cleanState = {
  name: null,
  selectedCategories: [],
  selectedLevel: 'ALL',
  searchTerm: null,
  optionValues: []
}
const generatePageStates = () => {
  const pgStatesList = []
  parentViews.forEach((vw) => {
    const newState = { ...cleanState }
    newState.name = vw
    pgStatesList.push(newState)
  })
  return pgStatesList
}

const types = {
  SET_LAYOUT: 'SET_LAYOUT',
  SET_LEVEL_VALUE: 'SET_LEVEL_VALUE',
  SET_OPTION_VALUES: 'SET_OPTION_VALUES',
  SET_CATEGORY_SELECTIONS: 'SET_CATEGORY_SELECTIONS',
  SET_SEARCH_TERM: 'SET_SEARCH_TERM',
  CLEAR_STATE: 'CLEAR_STATE'
}

const getDefaultState = () => {
  return {
    pageStates: generatePageStates(),
    selectedCategories: [],
    searchTerm: null,
    levelValue: 'ALL',
    optionValues: []
  }
}

// initial state
const state = getDefaultState()

const targetPageState = (state, viewName) => {
  return state.pageStates.find(tgtState => tgtState.name === viewName)
}
// apply state
const applyState = (state, viewName, tgtProp, payload) => {
  const tgtState = targetPageState(state, viewName)
  tgtState[tgtProp] = payload
}

// getters
const getters = {
  pageStates: state => state.pageStates
}

// actions
const actions = {
  setCategorySelections ({ commit }, payload) {
    commit(types.SET_CATEGORY_SELECTIONS, payload)
  },
  setSearchTerm ({ commit }, payload) {
    commit(types.SET_SEARCH_TERM, payload)
  },
  setLevelValue ({ commit }, payload) {
    commit(types.SET_LEVEL_VALUE, payload)
  },
  clearState ({ commit }, viewName) {
    commit(types.CLEAR_STATE, viewName)
  },
  setOptionValues ({ commit }, payload) {
    commit(types.SET_OPTION_VALUES, payload)
  }
}

// mutations
const mutations = {
  [types.SET_OPTION_VALUES] (state, payload) {
    applyState(state, payload.viewName, 'optionValues', payload.data)
  },
  [types.SET_LEVEL_VALUE] (state, payload) {
    applyState(state, payload.viewName, 'levelValue', payload.data)
  },
  [types.SET_OPTION_VALUES] (state, payload) {
    applyState(state, payload.viewName, 'optionValues', payload.data)
  },
  [types.SET_CATEGORY_SELECTIONS] (state, payload) {
    applyState(state, payload.viewName, 'selectedCategories', payload.data)
    // state.selectedCategories = payload
  },
  [types.SET_SEARCH_TERM] (state, payload) {
    applyState(state, payload.viewName, 'searchTerm', payload.data)
    // state.searchTerm = payload
  },
  [types.CLEAR_STATE] (state, viewName) {
    const tgtIndex = state.pageStates.findIndex(pgState => pgState.name === viewName)
    state.pageStates[tgtIndex].levelValue = 'ALL'
    // we don't clear the optionValues (vid libs) as they are built on the component -- we just update them via the component and SET_OPTION_VALUES
    // state.pageStates[tgtIndex].optionValues = []
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
