/*
Notification types:
"User" : this is what is displayed to the user -- both "APP" and "TENANT", this is determined by the backend based on "type"
"APP": these notifications are displayed to every clinic and originate from the super-user admin
"Tenant": these notifications are clinic-specific and only appear to the clinic of origin
 */

const types = {
  SET_CURRENT_USER_NOTIFICATIONS: 'SET_CURRENT_USER_NOTIFICATIONS',
  SET_TENANT_NOTIFICATIONS: 'SET_TENANT_NOTIFICATIONS',
  SET_APP_NOTIFICATIONS: 'SET_TENANT_NOTIFICATIONS'
}

const getDefaultState = () => {
  return {
    currentUserNotifications: [],
    tenantNotifications: [],
    appNotifications: []
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
  currentUserNotifications: state => state.currentUserNotifications,
  tenantNotifications: state => state.tenantNotifications,
  appNotifications: state => state.appNotifications
}

// actions
const actions = {
  setCurrentUserNotifications ({ commit }, payload) {
    commit(types.SET_CURRENT_USER_NOTIFICATIONS, payload)
  },
  setTenantNotifications ({ commit }, payload) {
    commit(types.SET_TENANT_NOTIFICATIONS, payload)
  },
  setAppNotifications ({ commit }, payload) {
    commit(types.SET_APP_NOTIFICATIONS, payload)
  }
}

// mutations
const mutations = {
  [types.SET_CURRENT_USER_NOTIFICATIONS] (state, payload) {
    state.currentUserNotifications = payload
  },
  [types.SET_TENANT_NOTIFICATIONS] (state, payload) {
    state.tenantNotifications = payload
  },
  [types.SET_APP_NOTIFICATIONS] (state, payload) {
    state.appNotifications = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
