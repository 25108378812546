<script>
export default {
  name: 'CollaborativeProcess',
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>
<template>
  <article class="strategic-partnerships-block">
    <div class="container">
      <div class="responsive-row">
        <div class="responsive-row-item is-text-item">
          <h2 class="text-outline-light section-title-main">A Collaborative Process</h2>
          <div class="section-subtitle">We’ve Had A Lot of Help</div>
        </div>
      </div>
      <div class="responsive-row">
        <div class="responsive-row-item is-text-item">
          <p class="is-size-5-desktop">
            Every game and activity on the Our Visual Brain platform is created with direct input from Vision Therapists and Doctors.  Our Advisory Panel, comprised of industry professionals, reviews each activity before it is released to ensure that we meet current therapeutic standards.
          </p>
        </div>
      </div>
      <div class="collab-grid-container">
        <div class="collab-card-grid mb-4">
          <div class="collab-card-container collab-0">
            <div class="card collab-card">
              <div class="card-image">
                <figure class="image">
                  <img :src="require('@/assets/promo/img/home/team/ryan_capouch.webp')" alt="Dr. Ryan Capouch">
                </figure>
              </div>
              <div class="card-content">
                <div class="content title is-7 has-text-centered">
                  Dr. Ryan Capouch<br>OD FCOVD
                </div>
              </div>
            </div>
          </div>
          <div class="collab-card-container collab-1">
            <div class="card collab-card">
              <div class="card-image">
                <figure class="image">
                  <img :src="require('@/assets/promo/img/home/team/robyn_rakov.webp')" alt="Dr. Robyn Rakov">
                </figure>
              </div>
              <div class="card-content">
                <div class="content title is-7 has-text-centered">
                  Dr. Robyn Rakov<br>OD FCOVD
                </div>
              </div>
            </div>
          </div>
          <div class="collab-card-container collab-2">
            <div class="card collab-card">
              <div class="card-image">
                <figure class="image">
                  <img :src="require('@/assets/promo/img/home/team/carolanne_roach.webp')" alt="Dr. Carolanne Roach">
                </figure>
              </div>
              <div class="card-content">
                <div class="content title is-7 has-text-centered">
                  Dr. Carolanne Roach<br>OD
                </div>
              </div>
            </div>
          </div>
          <div class="collab-card-container collab-3">
            <div class="card collab-card">
              <div class="card-image">
                <figure class="image">
                  <img :src="require('@/assets/promo/img/home/team/lisa_knopp.webp')" alt="Lisa Knopp">
                </figure>
              </div>
              <div class="card-content">
                <div class="content title is-7 has-text-centered">
                  Lisa Knopp<br>COVT, M.Ed.
                </div>
              </div>
            </div>
          </div>
          <div class="collab-card-container collab-4">
            <div class="card collab-card">
              <div class="card-image">
                <figure class="image">
                  <img :src="require('@/assets/promo/img/home/team/tiffany_tarbre.webp')" alt="Tiffany Tarbe">
                </figure>
              </div>
              <div class="card-content">
                <div class="content title is-7 has-text-centered">
                  Tiffany Tarbe<br> LDO, COTA/L
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-link
        class="button is-primary mt-4 mobile-container"
        :to="{ name: 'AboutView', params: {stickyNav: false}}"
      >
        Meet our Advisors
      </router-link>
    </div>
  </article>
</template>
<style scoped lang="scss">
.strategic-partnerships-block {
  .card.collab-card {
    .card-content {
      padding: 0.5rem;
    }
  }
}
</style>
<style scoped lang="scss">
.strategic-partnerships-block {
  display: block;
  position: relative;
  //background-image: linear-gradient(to right, $brochure-light-blue, $parchment);
  padding: 2rem 0 2rem 0;
  //color: #fff;
  //background-color: $parchment;
  //background-color: $toothpaste;
  background-color: $parchment;
  .collab-card {
    max-width: 8rem;
  }
  .collab-grid-container {
    //background-color: #cb5fae;
  }
  .collab-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    //border: 1px solid blue;
  }
  .collab-card-grid {
    //border: 1px solid red;
    flex-grow: 1;
    display: inline-grid;
    grid-template-columns: 1fr 1fr 1fr;
    //grid-template-columns: repeat( auto-fit, minmax(8rem, 8rem));
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
    margin: 0 1rem;
    @include tablet {
      grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    }
    @include desktop {
      margin: 0;
    }
  }
  .collab-box-image {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 10rem;
    border-radius: 20rem;
    overflow: hidden;
    //border: 1px solid red;
    figure {
      //display: flex;
      //align-items: center;
      //justify-content: center;
      //height: 10rem;
      //border-radius: 20rem;
      //overflow: hidden;
      img {
        height: 100%;
      }
    }
  }
}
</style>
