<script>
import { mapGetters } from 'vuex'
import axios from 'axios'
export default {
  name: 'MainNavAvatar',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters({
      userInfo: 'user/userInfo'
    }),
    enableTenantScope () {
      return (this.$store.state.user.scopeAppTenant !== null)
    },
    appTenantScope () {
      return this.$store.state.user.scopeAppTenant ? this.$store.state.user.scopeAppTenant.name : 'None'
    },
    enableResetDemoData () {
      return this.$store.state.user.userInfo.username === 'fnb-demo'
      // return true
    },
    recordedExceptions () {
      return this.$store.state.app.recordedExceptions.length
    },
    enableGraphqlErrors () {
      // return this.$userHasPermission('p:super-admin') && this.recordedExceptions > 0
      return this.$userHasPermission('p:app-tenant-scope') && this.recordedExceptions > 0
    },
    libraries () {
      return (this.$store.state.user.userInfo.libraries || []).reduce(
        (a, l) => {
          return a.find(al => al.id === l.id) ? a : [...a, l]
        }, []
      ) || []
    },
    enableLibrarySelector () {
      return this.libraries.length > 0 && this.$userHasPermission('p:super-admin')
    },
    showLibrarySelector () {
      // return this.libraries.length > 0 && this.$userHasPermission('p:super-admin')
      return true
    },
    workingLibrary () {
      return this.$store.state.ovb.workingLibrary
    },
    currentWorkingLibraryId () {
      return this.$store.state.ovb.workingLibrary ? this.$store.state.ovb.workingLibrary.id : null
    }
  },
  methods: {
    librarySelected (libraryId) {
      const library = this.libraries.find(l => l.id === libraryId)
      this.$store.dispatch('SET_WORKING_LIBRARY', library)
    },
    async logout () {
      this.$store.dispatch('brochure/setGuestUser', null)
      this.$store.dispatch('user/logout')
      this.$store.dispatch('app/logout')
      await axios.get('/post-logout')
      this.$store.dispatch('RESET_OVB')
        .then(() => {
          this.$router.push({ name: 'PreLogout' })
        })
    },
    clearAppTenantScope () {
      this.$store.dispatch('CLEAR_SCOPE_APP_TENANT')
    },
    // stopImpersonating () {
    //   this.$store.dispatch('STOP_IMPERSONATION')
    // },
    navToGraphqlErrors () {
      this.$store.commit('app/setEntityAppState', {
        entityId: 'OVB',
        type: 'Application',
        detailTabIndex: 3
      })
      if (this.$router.currentRoute.name !== 'ApplicationManager') {
        this.$router.push({ name: 'ApplicationManager' })
      }
    },
    resetDemoData () {
      this.$buefy.dialog.confirm({
        message: 'Are you sure you want to reset demo data?',
        title: 'Reset Demo Data',
        onConfirm: () => {
          this.$store.dispatch('RESET_DEMO_DATA')
        },
        reject: () => {
        }
      })
    }
  }
}
</script>

<template>
  <article class="user-container">
    <section class="support-section py-3 px-4 has-text-white">
      <header>
        <h2 class="is-size-5 mb-2">Support Only</h2>
        <div class="mb-4">
          <h3 class="is-size-6 mb-1">Tenant Scope</h3>
          <button
            :disabled="!enableTenantScope"
            :class="{'is-danger': enableTenantScope}"
            class="button is-small"
            @click="clearAppTenantScope"
          >
            <span
              v-if="enableTenantScope"
              class="icon is-medium mr-1"
            >
              <i class="mdi mdi-18px mdi-close-circle" />
            </span>
            {{appTenantScope}}
          </button>
        </div>
        <div class="support-grid">
          <div>
            <h3 class="is-size-6 mb-1">Demo Data</h3>
            <button
              :disabled="!enableResetDemoData"
              class="button is-info is-small is-fullwidth"
              @click="resetDemoData"
            >
            <span class="icon is-medium mr-1">
              <i class="mdi mdi-18px mdi-reload" />
            </span>
              Reset
            </button>
          </div>
          <div>
            <h3 class="is-size-6 mb-1">GQL Errors ({{ recordedExceptions }})</h3>
            <button
              :disabled="!enableGraphqlErrors"
              class="button is-danger is-small is-fullwidth"
              @click="navToGraphqlErrors"
            >
              <span class="icon is-medium mr-1">
                <i class="mdi mdi-18px mdi-alert" />
              </span>
              GQL Errors
            </button>
          </div>
          <div>
            <h3 class="is-size-6 mb-1">Library</h3>
            <b-select
              size="is-small"
              placeholder="Select library..."
              v-model="currentWorkingLibraryId"
              @input="librarySelected"
              :disabled="!enableLibrarySelector"
            >
              <option
                v-for="l in libraries"
                :value="l.id"
                :key="l.id"
              >
                {{ l.id }}
              </option>
            </b-select>
          </div>
        </div>
      </header>
    </section>
  </article>
</template>

<style scoped lang="scss">
.support-section {
  background-color: $feldgrau;
}
.support-grid {
  display: grid;
  grid-template-columns: repeat( auto-fill, minmax(8rem, 1fr));
  grid-gap: 0.75rem;
}
</style>
