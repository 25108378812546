<template>
 <div
  class="text-xl"
 >
  sign-up
 </div>
</template>

<script>

export default {
  name: 'Signup',
  methods: {
  },
  data () {
  },
  mounted () {
  }
}
</script>

<style scoped>

</style>
