<script>
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
gsap.registerPlugin(CSSPlugin)
export default {
  name: 'TheHero',
  components: {
  },
  props: {
    height: {
      type: [String, Number],
      default: '100vh'
    },
    slides: {
      type: Array,
      required: true
    },
    autoPlay: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      playbackCount: 0,
      currentSlide: 0,
      isVisible: false, // if component is visible/scrolled to on the screen, within tolerances
      isPaused: false,
      slideTimeline: null,
      initialized: false, // set for when refs are available
      playForward: true
    }
  },
  computed: {
    sectionHeight () {
      return `height: ${this.height};`
    },
    videoSource () {
      let vid
      switch (this.$mq) {
        case 'mobile':
          vid = require('@/assets/promo/video/hero.webm')
          break
        case 'tablet':
          vid = require('@/assets/promo/video/hero.webm')
          break
        case 'desktop':
          vid = require('@/assets/promo/video/hero.webm')
          break
        case 'widescreen':
          vid = require('@/assets/promo/video/hero.webm')
          break
        case 'fullhd':
          vid = require('@/assets/promo/video/hero.webm')
          break
      }
      return vid
    },
    posterImage () {
      let poster
      switch (this.$mq) {
        case 'mobile':
          poster = require('@/assets/promo/img/home/hero/eye_bg_fade@0.25x.webp')
          break
        case 'tablet':
          poster = require('@/assets/promo/img/home/hero/eye_bg_fade@0.25x.webp')
          break
        case 'desktop':
          poster = require('@/assets/promo/img/home/hero/eye_bg_fade@0.25x.webp')
          break
        case 'widescreen':
          poster = require('@/assets/promo/img/home/hero/eye_bg_fade@0.25x.webp')
          break
        case 'fullhd':
          poster = require('@/assets/promo/img/home/hero/eye_bg_fade@0.25x.webp')
          break
      }
      return poster
    },
    // videoPoster () {
    //   return require('@/assets/promo/img/home/hero/hero_640.webp')
    // },
    slideBox () {
      return this.$refs.slide
    },
    slideTitle () {
      return this.$refs.slideTitle
    },
    slideContent () {
      return this.$refs.slideContent
    },
    slideAction () {
      return this.$refs.slideAction
    }
  },
  methods: {
    slideMainTimeline () {
      this.initialized = true
      const master = gsap.timeline({ repeat: -1 })
      master.set(this.slideBox, { x: 0, opacity: 1 })
      master.set(this.slideTitle, { x: 0 })
      master.set(this.slideAction, { x: 0 })
      master.set(this.slideContent, { x: 0 })
      master.add(this.slideInTimeline())
        .add(this.slideOutTimeline(), `+=${this.slides[this.currentSlide].duration}`) // add gap
      return master
    },
    slideInTimeline () {
      const tl = gsap.timeline()
      tl.from(this.slideBox, { ease: 'power4.out', duration: 2, x: -100, opacity: 0 })
      tl.from(this.slideTitle, { ease: 'power4.out', duration: 2.2, x: -100 }, '<')
      tl.from(this.slideAction, { ease: 'power4.out', duration: 3, x: -100 }, '<')
      tl.from(this.slideContent, { ease: 'power4.out', duration: 3.25, x: -200 }, '<')
      return tl
    },
    slideOutTimeline () {
      const tl = gsap.timeline()
      tl.to(this.slideBox, { ease: 'power4.out', duration: 2, x: -100, opacity: 0 })
      tl.to(this.slideTitle, { ease: 'power4.out', duration: 2.2, x: -100 }, '<')
      tl.to(this.slideAction, { ease: 'power4.out', duration: 3, x: -100 }, '<')
      tl.to(this.slideContent, { ease: 'power4.out', duration: 3.25, x: -200, onComplete: this.nextSlide }, '<')
      return tl
    },
    nextSlide () {
      // console.log('nextSlide')
      this.currentSlide = this.currentSlide < (this.slides.length - 1) ? this.currentSlide += 1 : 0
    },
    visibilityChanged (isVisible) {
      this.isVisible = isVisible
    },
    onEventClick (eventName) {
      this.$emit(eventName)
    },
    loadVideo (targetPlayer) {
      if (targetPlayer) {
        targetPlayer.load()
        targetPlayer.pause()
        targetPlayer.currentTime = 0
      }
    },
    playVideo (targetPlayer) {
      if (targetPlayer) {
        targetPlayer.currentTime = 0
        targetPlayer.play()
      }
    },
    stopVideo (targetPlayer) {
      if (targetPlayer) {
        targetPlayer.pause()
        targetPlayer.currentTime = 0
      }
    }
  },
  created () {
    if (this.slideTimeline) this.slideTimeline.pause()
  },
  mounted () {
    setTimeout(x => {
      this.$nextTick(() => {
        this.slideTimeline = this.slideMainTimeline()
        this.slideTimeline.play()
        this.playVideo(this.$refs.videoPlayer)
        // console.log(this.$refs.videoPlayer)
      })
    }, 500)
  },
  beforeDestroy () {
    this.slideTimeline.pause()
    this.slideTimeline.kill()
    this.slideTimeline = null
  }
}
</script>

<template>
  <section
    :style="sectionHeight"
    class="slide-section"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 300,
    }"
  >
    <div
      :style="sectionHeight"
      class="player-container"
      :class="{'is-image': $mq !== 'mobile'}"
    >
      <div :style="sectionHeight" class="slide">
        <div :style="sectionHeight" class="video-slide">
          <div
            class="video-buffering"
            :style="{
              backgroundImage: `url(${posterImage})`,
            }"
          />
          <div
            v-if="$mq !== 'mobile'"
            key="videoContainer"
            class="video-container"
          >
<!--            <video-->
<!--              ref="videoPlayer"-->
<!--              autoplay="autoplay"-->
<!--              playsinline=""-->
<!--              disablepictureinpicture-->
<!--              loop="loop"-->
<!--              preload="auto"-->
<!--              :poster="posterImage"-->
<!--              style="object-fit: cover"-->
<!--              :muted="true"-->
<!--            >-->
              <video
              ref="videoPlayer"
              autoPlay
              playsinline
              disablePictureInPicture
              preload="auto"
              loop
              :poster="posterImage"
              style="object-fit: cover"
              muted
            >
              <source
                ref="videoSource"
                :src="videoSource" type="video/webm"
              />
            </video>
          </div>
        </div>
      </div>
      <div
        :style="sectionHeight"
        class="slide-overlay"
      >
        <div
          class="slide-content container"
        >
          <div
            ref="slide"
            id="slide-box"
            class="slide-container"
            :class="{'active': initialized}"
          >
            <div
              id="slide-title"
              ref="slideTitle"
              v-dompurify-html="slides[currentSlide].title"
            />
            <div
              id="slide-content"
              ref="slideContent"
              v-dompurify-html="slides[currentSlide].content"
            />
            <div
              id="slide-action"
              class="buttons"
              ref="slideAction"
            >
              <button
                v-if="slides[currentSlide].event"
                @click="onEventClick(slides[currentSlide].event)"
                class="button is-primary is-large"
              >
                {{ slides[currentSlide].buttonLabel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
.slide-section {
  display: block;
  overflow: hidden;
}
.player-container {
  display: block;
  position: relative;
  background-color: #fff;
  width: 100vw;
  opacity: 1;
  z-index: 0;
  overflow: hidden;
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  max-width: 100vw;
}
.slide-container {
  visibility: hidden;
  &.active {
    visibility: visible;
  }
}
.video-buffering {
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  opacity: 1;
}
video {
  visibility: visible;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
.slide-overlay {
  position: relative;
  top: 0;
  background: linear-gradient(45deg,hsla(200,62%,39%,.8),hsla(354, 84%, 60%, .3));
  z-index: 15;
}
.slide-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  bottom: 20%;
  height: 100%;
  padding: 2rem;
  &.align-left {
    align-items: flex-start;
  }
  &.align-right {
    align-items: flex-end;
  }
  @include tablet {}
  @include desktop {}
}
//.slide-container {
//  margin-top: 8rem;
//}
.banner {
  display: block;
  opacity: 1;
  width: 100vw;
  position: relative;
  z-index: 30;
  top: -200%;
  overflow: hidden;
}
</style>
