<script>
export default {
  name: 'ComprehensiveApproach',
  components: {
  },
  data () {
    return {
      isMounted: false
      // images: [
      //   {
      //     mobile: './img/visual_closure_vid@0.25x.png',
      //     tablet: './img/visual_closure_vid@0.25x.png',
      //     desktop: './img/visual_closure_vid@0.25x.png',
      //     widescreen: './img/visual_closure_vid@0.25x.png',
      //     fullhd: './img/visual_closure_vid@0.25x.png',
      //     fallback: './img/visual_closure_vid@0.25x.png'
      //   },
      //   {
      //     srcSet: 'img/promo/home/comprehensiveApproach//visual_closure_vid@0.25x.png 768w, img/promo/home/comprehensiveApproach//visual_closure_vid@0.5x.png 1025w'
      //   }
      // ]
    }
  },
  computed: {
    images () {
      return [
        {
          srcSet: 'img/promo/home/comprehensiveApproach/visual_closure_game@0.33x.png 100w, img/promo/home/comprehensiveApproach/visual_closure_game@0.5x.png 1025w'
        },
        {
          srcSet: 'img/promo/home/comprehensiveApproach/visual_closure_wksheet@0.33x.png 100w, img/promo/home/comprehensiveApproach/visual_closure_wksheet@0.5x.png 1025w'
        },
        {
          srcSet: 'img/promo/home/comprehensiveApproach/visual_closure_vid@0.33x.png 100w, img/promo/home/comprehensiveApproach/visual_closure_vid@0.5x.png 1025w'
        }
      ]
    },
    vidLibImg () {
      return [
        {
          srcSet: 'img/promo/home/comprehensiveApproach/vidLib_pr@0.33x.png 100w, img/promo/home/comprehensiveApproach/vidLib_pr@0.5x.png 1025w'
        },
        {
          srcSet: 'img/promo/home/comprehensiveApproach/vidLib_coach@0.33x.png 100w, img/promo/home/comprehensiveApproach/vidLib_coach@0.5x.png 1025w'
        },
        {
          srcSet: 'img/promo/home/comprehensiveApproach/vidLib_kim@0.33x.png 100w, img/promo/home/comprehensiveApproach/vidLib_kim@0.5x.png 1025w'
        },
        {
          srcSet: 'img/promo/home/comprehensiveApproach/vidLib_wm@0.33x.png 100w, img/promo/home/comprehensiveApproach/vidLib_wm@0.5x.png 1025w'
        }
      ]
    },
    worksheetImg () {
      return [
        {
          srcSet: 'img/promo/home/comprehensiveApproach/wksht_01@0.5x.png 100w, img/promo/home/comprehensiveApproach/wksht_01@0.5x.png 1025w'
        },
        {
          srcSet: 'img/promo/home/comprehensiveApproach/wksht_02@0.5x.png 100w, img/promo/home/comprehensiveApproach/wksht_02@0.5x.png 1025w'
        },
        {
          srcSet: 'img/promo/home/comprehensiveApproach/wksht_03@0.5x.png 100w, img/promo/home/comprehensiveApproach/wksht_03@0.5x.png 1025w'
        }
      ]
    },
    screenSize () {
      return this.$mq
    }
  },
  methods: {
  },
  mounted () {
    this.isMounted = false
  }
}
</script>
<template>
  <article class="comprehensive-approach">
    <header class="container mobile-container">
      <h2 class="text-outline-light section-title-main">Comprehensive Approach</h2>
<!--      <div class="section-subtitle">Round Out Your Treatment Plan with 3 complimentary playlists</div>-->
      <div class="section-subtitle">Round Out Your Treatment Plan with corresponding Worksheets and Videos</div>
    </header>
    <div class="container mobile-container is-size-5 mt-2">
      Our Activities, Worksheets, and Video libraries are tagged, filterable, and searchable by therapy type (among other things).<br>
      It's easy to find and assign the right Worksheets and Videos to compliment your treatment plan.
    </div>
<!--    <div class="container mobile-container">-->
<!--      <div class="visual-closure-container mt-4">-->
<!--        <figure-->
<!--          class="visual-closure-image"-->
<!--        >-->
<!--          <b-image-->
<!--            src="img/promo/home/comprehensiveApproach/visual_closure_game.png"-->
<!--            :srcset="images[0].srcSet"-->
<!--            ratio="337by460"-->
<!--          />-->
<!--          <figcaption>Activities (games)</figcaption>-->
<!--        </figure>-->
<!--        <figure-->
<!--          class="visual-closure-image"-->
<!--        >-->
<!--          <b-image-->
<!--            src="img/promo/home/comprehensiveApproach/visual_closure_vid.png"-->
<!--            :srcset="images[2].srcSet"-->
<!--            ratio="337by460"-->
<!--          />-->
<!--          <figcaption>Videos</figcaption>-->
<!--        </figure>-->
<!--        <figure-->
<!--          class="visual-closure-image"-->
<!--        >-->
<!--          <b-image-->
<!--            src="img/promo/home/comprehensiveApproach/visual_closure_wksheet.png"-->
<!--            :srcset="images[1].srcSet"-->
<!--            ratio="337by460"-->
<!--          />-->
<!--          <figcaption>Worksheets</figcaption>-->
<!--        </figure>-->
<!--      </div>-->
<!--    </div>-->
    <section class="container mobile-container mt-6">
      <header>
        <h3 class="sub-section-title">Worksheets Library</h3>
        <div class="is-size-5 has-text-grey-dark">
          Downloadable Worksheets for all ages and abilities
        </div>
      </header>
      <div
        class="worksheet-lib-list"
      >
        <div class="worksheet-lib-item">
          <div class="worksheet-lib-item-image">
            <b-image
              src="img/promo/home/comprehensiveApproach/wksht_01@0.5x.png"
              :srcset="worksheetImg[0].srcSet"
              ratio="317by245"
            />
          </div>
        </div>
        <div class="worksheet-lib-item">
          <div class="worksheet-lib-item-image">
            <b-image
              src="img/promo/home/comprehensiveApproach/wksht_02@0.5x.png"
              :srcset="worksheetImg[1].srcSet"
              ratio="317by245"
            />
          </div>
        </div>
        <div class="worksheet-lib-item">
          <div class="worksheet-lib-item-image">
            <b-image
              src="img/promo/home/comprehensiveApproach/wksht_03@0.5x.png"
              :srcset="worksheetImg[2].srcSet"
              ratio="317by245"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="container mobile-container mt-4">
      <header>
        <h3 class="section-subtitle">Video Library</h3>
        <div class="is-size-5 has-text-grey-dark">
          Add movement to your treatment plan.
        </div>
      </header>
      <div
        class="video-lib-list"
      >
        <div class="video-lib-item">
          <div class="vid-lib-item-image">
            <b-image
              src="img/promo/home/comprehensiveApproach/vidLib_coach@0.5x.png"
              :srcset="vidLibImg[0].srcSet"
              ratio="352by419"
            />
          </div>
          <div class="vid-lib-item-content">
            <h4 class="is-size-5 is-family-semibold">
              Primitive Reflexes
            </h4>
            <div class="">
              Professional PR videos created by Patricia Andrich, COVT, OTR/L
            </div>
          </div>
        </div>
        <div class="video-lib-item">
          <div class="vid-lib-item-image">
            <b-image
              src="img/promo/home/comprehensiveApproach/vidLib_coach@0.5x.png"
              :srcset="vidLibImg[1].srcSet"
              ratio="352by419"
            />
          </div>
          <div class="vid-lib-item-content">
            <h4 class="is-size-5 is-family-semibold">
              OVB Coach
            </h4>
            <div class="">
              Professional videos demonstrating how to use therapy equipment at home as well as other therapeutic activities and games.
            </div>
          </div>
        </div>
        <div class="video-lib-item">
          <div class="vid-lib-item-image">
            <b-image
              src="img/promo/home/comprehensiveApproach/vidLib_coach@0.5x.png"
              :srcset="vidLibImg[3].srcSet"
              ratio="352by419"
            />
          </div>
          <div class="vid-lib-item-content">
            <h4 class="is-size-5 is-family-semibold">
              We Move
            </h4>
            <div>
              Fun tutorials focusing on using common household objects to work on perceptual skills.
            </div>
          </div>
        </div>
        <div class="video-lib-item">
          <div class="vid-lib-item-image">
            <b-image
              src="img/promo/home/comprehensiveApproach/vidLib_coach@0.5x.png"
              :srcset="vidLibImg[2].srcSet"
              ratio="352by419"
            />
          </div>
          <div class="vid-lib-item-content">
            <h4 class="is-size-5 is-family-semibold">
              Kids in Motion
            </h4>
            <div>
              Professional Pilates videos demonstrating how to perform various movements focusing on core and balance.             </div>
          </div>
        </div>
      </div>
    </section>
  </article>
</template>

<style scoped lang="scss">
.comprehensive-approach {
  display: block;
  //background-color: #F5EBDC;
  background-color: hsl(36,46,93);
  //background-image: linear-gradient(to right, #F5EBDC, $eggshell);
  padding: 2rem 0 2rem 0;
  .worksheet-lib-list {
    display: flex;
    flex-wrap: wrap;
    .worksheet-lib-item {
      width: 14rem;
      margin-bottom: 1rem;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
    .worksheet-lib-item-image {
      width: 14rem;
      box-shadow: rgba(0, 0, 0, 0.05) 0 5px 20px;
    }
  }
  .video-lib-list {
    //background-color: #cb5fae;
    display: flex;
    flex-wrap: wrap;
    .video-lib-item {
      background-color: $empty-background;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      border-radius: 0.5rem;
      padding: 0.5rem;
      margin-bottom: 1rem;
      &:not(:last-child) {
        margin-right: 1rem;
      }
      @include tablet {
        max-width: 25rem;
        align-items: center;
      }
      @include widescreen {
        padding: 1rem;
        max-width: 25rem;
      }
    }
    .vid-lib-item-image {
      min-width: 10rem;
      @include tablet {
        min-width: 13rem;
      }
    }
    .vid-lib-item-content {
      margin-left: 0.5rem;
      margin-left: 0.5rem;
      @include widescreen {
        margin-left: 1rem;
      }
    }
  }
  .visual-closure {
    &-container {
      background-color: $empty-background;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      padding: 1rem;
      border-radius: 0.5rem;
      @include tablet {
        justify-content: flex-start;
      }
    }
    &-image {
      width: 12rem;
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
      figcaption {
        text-align: center;
        font-family: $family-semibold;
        color: $feldgrau;
        margin: 0;
        padding: 0;
        font-size: $size-6;
      }
      @include tablet {
        width: 14rem;
        &:not(:last-child) {
          margin-bottom: 0;
          margin-right: 1rem;
        }
      }
    }

  }
}
</style>
