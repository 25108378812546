<script>
import VideoBackground from '@/components/_common/special/VideoBackground'
export default {
  name: 'CustomizableTools',
  components: {
    VideoBackground
  },
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>

<template>
  <article>
    <div class="responsive-row">
      <div class="is-slide-item responsive-row-item">
        <video-background
          :video-source="require('@/assets/promo/video/CustomizableTools/games.webm')"
          :poster-image="require('@/assets/promo/img/home/CustomizableTools/games_poster.webp')"
          aspect-ratio="16/9"
          width="35rem"
          class="game-video"
        />
      </div>
      <div class="responsive-row-item is-text-item">
        <aside class="is-flex is-flex-wrap-wrap">
          <header>
            <span class="list-title">Our customizable therapy tools are designed to meet your therapy needs head-on.</span>
          </header>
          <div>
            <ul class="mt-2 list-discs points-of-interest is-size-5-desktop">
              <li class="poi">Innovative Anti-suppression and MFBF Colors</li>
              <li class="poi">TBI Protocols</li>
              <li class="poi">Academic Skill Building</li>
              <li class="poi">A Growing Library of 160+ Activities</li>
              <li class="poi">Dyslexic-friendly Format</li>
              <li class="poi">Processing Speed Support</li>
              <li class="poi">Foreign Language Instructions</li>
              <li class="poi">New Activities Added Monthly!</li>
            </ul>
          </div>
        </aside>
      </div>
    </div>
  </article>
</template>

<style scoped lang="scss">
  .game-video {
    border: 1px solid $gray-lighter;
    box-shadow: rgba(0, 0, 0, 0.2) 0 10px 50px;
  }
  .list-title {
    font-size: $size-3;
    line-height: $size-3;
    color: $feldgrau;
    font-family: $family-semibold;
  }
  .points-of-interest {
    li {
      //font-family: $family-semibold;
      //color: $gray-darker;
      color: $feldgrau;
    }
  }
</style>
