import apolloProvider from '@/graphql'
import closeUserHelpRequest from '@/graphql/mutation/closeUserHelpRequest.graphql'
import reopenUserHelpRequest from '@/graphql/mutation/reopenUserHelpRequest.graphql'
import replyToUserHelpRequest from '@/graphql/mutation/replyToUserHelpRequest.graphql'
import createUserHelpRequest from '@/graphql/mutation/createUserHelpRequest.graphql'

const types = {
  CREATE_USER_HELP_REQUEST: 'CREATE_USER_HELP_REQUEST',
  CLOSE_USER_HELP_REQUEST: 'CLOSE_USER_HELP_REQUEST',
  REOPEN_USER_HELP_REQUEST: 'REOPEN_USER_HELP_REQUEST',
  REPLY_TO_USER_HELP_REQUEST: 'REPLY_TO_USER_HELP_REQUEST'
}

const getDefaultState = () => {
  return {
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
}

// actions
const actions = {
  [types.CREATE_USER_HELP_REQUEST]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: createUserHelpRequest,
          variables: payload
        })
    }
  },
  [types.CLOSE_USER_HELP_REQUEST]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: closeUserHelpRequest,
          variables: payload
        })
    }
  },
  [types.REOPEN_USER_HELP_REQUEST]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: reopenUserHelpRequest,
          variables: payload
        })
    }
  },
  [types.REPLY_TO_USER_HELP_REQUEST]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: replyToUserHelpRequest,
          variables: payload
        })
    }
  }
}

// mutations
const mutations = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
