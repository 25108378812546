const install = function (Vue, options) {
  // 1. add global method or property
  Vue.prototype.$formatDate = function (date) {
    if (!date) return 'N/A'
    return `${date.split('T')[0]}`
  }

  Vue.prototype.$formatDateTime = function (date) {
    if (!date) return 'N/A'
    return `${date.split('T')[0]} ${date.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')}`
  }

  Vue.prototype.$formatDateTimeMilliseconds = function (date) {
    if (!date) return 'N/A'
    const milliseconds = date.split('.')[1].split('-')[0].split('+')[0]
    return `${date.split('T')[0]} ${date.split('T')[1].split('.')[0].split(':').slice(0, 2).join(':')}.${milliseconds}`
  }

  Vue.prototype.$qalert = function (json) {
    alert(JSON.stringify(json, null, 2))
  }
}

const FnbHelpersPlugin = {
  install: install
}

export { FnbHelpersPlugin }
