<script>

import TheHero from '@/components/PromoSite/HomePage/TheHero'
import GamesSection from '@/components/PromoSite/HomePage/GamesSection'
import StrategicPartnerships from '@/components/PromoSite/HomePage/StrategicPartnerships'
import TestimonialsBlock from '@/components/PromoSite/HomePage/TestimonialsBlock'
import ComprehensiveApproach from '@/components/PromoSite/HomePage/ComprehensiveApproach/ComprehensiveApproach'
import TherapyManagementBlock from '@/components/PromoSite/HomePage/TherapyManagementBlock'
import BookPromo from '@/components/PromoSite/HomePage/BookPromo'
import CookieLaw from 'vue-cookie-law'
import { mapGetters } from 'vuex'
import { goToRef } from '@/mixins/global'
import currentAppUser from '@/graphql/query/currentAppUser.graphql'

export default {
  name: 'HomeView',
  components: {
    TheHero,
    GamesSection,
    StrategicPartnerships,
    TestimonialsBlock,
    ComprehensiveApproach,
    BookPromo,
    CookieLaw,
    TherapyManagementBlock
  },
  mixins: [goToRef],
  data () {
    return {
      showTree: false
    }
  },
  computed: {
    ...mapGetters({
      libraryInfo: 'brochure/libraryInfo',
      workingLibrary: 'ovb/workingLibrary'
    }),
    heroHeight () {
      return this.$mq === 'mobile' ? '100vh' : '100vh'
    },
    heroSlides () {
      return [
        {
          title: '<div class="hero-title mb-2 text-outline-light">Therapeutic Gaming</div>',
          content: '<div class="hero-content mb-5 text-outline-dark">Our Visual Brain is a perceptual and academic<br>skill building platform you and your patient<br>use together.</div>',
          buttonLabel: 'Learn More',
          duration: 5,
          event: 'GOTO_GAMING'
        },
        {
          title: '<div class="hero-title mb-2">Flexible. Adaptable.</div>',
          content: '<div class="hero-content mb-5">Use in-office, at home, or during telehealth.<br>Therapy on your terms.</div>',
          // buttonLabel: 'Learn More',
          duration: 5
          // event: 'GOTO_FEATURES'
        },
        {
          title: '<div class="hero-title mb-2">Accessible Anywhere</div>',
          content: '<div class="hero-content mb-5">The Our Visual Brain platform is Cloud-based<br> &amp; runs on any device with a modern web-browser<br>and internet connection.</div>',
          // buttonLabel: 'Learn More',
          duration: 5
          // event: 'GOTO_FEATURES'
        }
      ]
    },
    isLoading () {
      return this.loading > 0
    },
    currentRoute () {
      return this.$router.currentRoute
    },
    // userInfo () {
    //   return this.$store.state.user.userInfo
    // },
    isMobile () {
      return ((this.$mq === 'mobile') || (this.$mq === 'tablet'))
    },
    isLoggedIn () {
      return this.$store.state.user.isLoggedIn
    }
  },
  methods: {
    onGotoGaming () {
      this.$router.push({ name: 'FeaturesView' })
    }
  },
  apollo: {
    userInfo: {
      query: currentAppUser,
      fetchPolicy: 'network-only',
      update (data) {
        // IF THERE IS A CURRENT USER, THEN WE SET THE USER INFO AND REDIRECT TO THEIR HOME PAGE
        // THIS HAPPENS BECAUSE AUTH0 REDIRECTS HERE AFTER LOGIN
        const currentAppUser = data.currentAppUser
        if (currentAppUser) {
          this.$store.dispatch('user/setUser', currentAppUser)
          this.$router.push({ name: currentAppUser.homePath })
        }
      },
      error () {
        // WE DO NOTHING HERE - IF THERE IS NO USER, THEN JUST STAY ON HOME PAGE
        // console.log(error.message)
      }
    }
  }
}
</script>

<template>
  <section
    class='home-view'
  >
    <div
      v-if="!isLoggedIn"
      class="home-view-no-auth"
    >
      <the-hero
        :height="heroHeight"
        :auto-play="true"
        :slides="heroSlides"
        @GOTO_GAMING="onGotoGaming"
      />
      <div ref="betterFoundation">
        <games-section />
      </div>
      <strategic-partnerships />
      <testimonials-block />
      <comprehensive-approach />
      <therapy-management-block />
      <book-promo />
    </div>
    <!-- PRETTY SURE THIS GOES AWAY -->
<!--    <div-->
<!--      v-if="!userInfo.appUserId"-->
<!--      class="free-demo"-->
<!--    >-->
<!--      <button-->
<!--        @click="onFreeTrial"-->
<!--        class="is-hidden-mobile text-outline-light is-rounded is-watermelon button is-large is-family-semibold">-->
<!--        Start Your Free Trial!-->
<!--      </button>-->
<!--    </div>-->
    <footer>
      <cookie-law theme='ovb'></cookie-law>
    </footer>
  </section>
</template>

<style
  lang="scss"
  scoped
>
  .home-view {
    background-image: linear-gradient(to right, #faf3f0, #fdfaf9);
    .free-demo {
      position: fixed;
      top: 6rem;
      right: 2rem;
      z-index: 99;
    }
  }
</style>
