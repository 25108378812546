<script>
import { gsap } from 'gsap'
import { CSSPlugin } from 'gsap/CSSPlugin'
gsap.registerPlugin(CSSPlugin)
export default {
  name: 'SlideSectionThin',
  components: {
  },
  props: {
    height: {
      type: [String, Number],
      default: '20rem'
    },
    width: {
      type: [String, Number],
      default: '20rem'
    },
    hasGradiant: {
      type: Boolean,
      default: true
    },
    gradiantOverlay: {
      type: String,
      default: 'linear-gradient(45deg, #2a4ae430, #fb949e6b)'
    },
    slides: {
      type: Array,
      required: true
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    showBanner: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      playerInfo: {
        activePlayer: 0, // player currently visible
        hiddenPlayer: 1,
        players: [
          {
            playerLabel: 'playerOne',
            videoContainerLabel: 'videoContainerOne',
            videoLabel: 'videoOne',
            contentBox: 'contentBoxOne',
            titleLabel: 'titleOne',
            contentLabel: 'contentOne',
            buttonLabel: 'buttonOne',
            currentSlide: 0
          },
          {
            playerLabel: 'playerTwo',
            videoContainerLabel: 'videoContainerTwo',
            videoLabel: 'videoTwo',
            contentBox: 'contentBoxTwo',
            titleLabel: 'titleTwo',
            contentLabel: 'contentTwo',
            buttonLabel: 'buttonTwo',
            currentSlide: 1
          }
        ]
      },
      isVisible: false, // if component is visible/scrolled to on the screen, within tolerances
      isPaused: false
    }
  },
  computed: {
    overlayStyle () {
      return this.hasGradiant ? `${this.sectionHeight}` : this.sectionHeight
    },
    sectionHeight () {
      return `height: ${this.height}; width: ${this.width}; max-width: 100vw;`
    },
    activePlayer () {
      return this.playerInfo.activePlayer === 0 ? this.$refs.playerOne : this.$refs.playerTwo
    },
    hiddenPlayer () {
      return this.playerInfo.activePlayer === 0 ? this.$refs.playerTwo : this.$refs.playerOne
    },
    slideInfo () {
      const playerOneSlideNum = this.playerInfo.players[0].currentSlide
      const playerTwoSlideNum = this.playerInfo.players[1].currentSlide
      return [
        {
          index: playerOneSlideNum,
          type: this.slides[playerOneSlideNum].type,
          align: this.slides[playerOneSlideNum].align.toUpperCase(),
          duration: this.slides[playerOneSlideNum].duration,
          video: this.slides[playerOneSlideNum].video[this.$mq],
          poster: this.slides[playerOneSlideNum].poster[this.$mq]
        },
        {
          index: playerTwoSlideNum,
          type: this.slides[playerTwoSlideNum].type,
          align: this.slides[playerTwoSlideNum].align.toUpperCase(),
          duration: this.slides[playerTwoSlideNum].duration,
          video: this.slides[playerTwoSlideNum].video[this.$mq],
          poster: this.slides[playerTwoSlideNum].poster[this.$mq]
        }
      ]
    }
  },
  methods: {
    startNextSlide () {
      this.hideHiddenSlideContent()
      // set hidden player to 0
      this.stopVideo(this.$refs[this.playerInfo.players[this.playerInfo.hiddenPlayer].videoLabel])
      // make sure both videos are at left:0
      gsap.set(this.$refs.playerOne, { duration: 0, css: { left: 0 } })
      gsap.set(this.$refs.playerTwo, { duration: 0, css: { left: 0 } })
      this.$refs[this.playerInfo.players[this.playerInfo.hiddenPlayer].videoLabel].play()
      // fade in next slide, fade out current active slide
      gsap.to(this.activePlayer, { duration: 2, ease: 'power4.in', opacity: 0 })
      gsap.to(this.hiddenPlayer, { duration: 2, ease: 'power4.in', opacity: 1, onComplete: () => this.endNextSlide() })
    },
    endNextSlide () {
      // switch active players
      const temp = this.playerInfo.activePlayer
      this.playerInfo.activePlayer = this.playerInfo.hiddenPlayer
      this.playerInfo.hiddenPlayer = temp
      // set slide for hidden player to be one more than active player (or looped back to 0)
      const currentActiveSlide = this.playerInfo.players[this.playerInfo.activePlayer].currentSlide
      this.playerInfo.players[this.playerInfo.hiddenPlayer].currentSlide = currentActiveSlide < (this.slides.length - 1)
        ? (currentActiveSlide + 1)
        : 0
      // emit event for next slide
      this.$emit('NEXT_SLIDE', currentActiveSlide)
      // load video for next slide
      this.loadVideo(this.$refs[this.playerInfo.players[this.playerInfo.hiddenPlayer].videoLabel])
      // get content refs
      const activeBox = this.$refs[this.playerInfo.players[this.playerInfo.activePlayer].contentBox]
      const activeTitle = this.$refs[this.playerInfo.players[this.playerInfo.activePlayer].titleLabel]
      const activeContent = this.$refs[this.playerInfo.players[this.playerInfo.activePlayer].contentLabel]
      const activeButton = this.$refs[this.playerInfo.players[this.playerInfo.activePlayer].buttonLabel]
      // move hiddenPlayer out of view width on x axis so position doesn't get wonky
      gsap.set(this.hiddenPlayer, { css: { left: '-200vw', opacity: 0 } })
      // animate in content
      gsap.to(activeBox, { duration: 0.75, opacity: 1, ease: 'power4.out' })
      gsap.from(activeBox, { duration: 0.75, x: -20, ease: 'power4.out' })
      gsap.from(activeTitle, { duration: 0.75, x: -40, ease: 'power4.out' })
      gsap.from(activeContent, { duration: 0.75, x: -40, ease: 'power4.out', delay: 0.15 })
      gsap.from(activeButton, { duration: 0.75, x: -40, ease: 'power4.out', delay: 0.25 })
      gsap.to(activeTitle, { duration: 0.75, opacity: 1, ease: 'power4.out' })
      gsap.to(activeContent, { duration: 0.75, opacity: 1, ease: 'power4.out', delay: 0.15 })
      gsap.to(activeButton, { duration: 0.75, opacity: 1, ease: 'power4.out', delay: 0.25, onComplete: () => this.triggerTimer() })
    },
    hideHiddenSlideContent () {
      // set slide content opacity to 0 in hidden slides
      const hiddenBox = this.$refs[this.playerInfo.players[this.playerInfo.hiddenPlayer].contentBox]
      const hiddenTitle = this.$refs[this.playerInfo.players[this.playerInfo.hiddenPlayer].titleLabel]
      const hiddenContent = this.$refs[this.playerInfo.players[this.playerInfo.hiddenPlayer].contentLabel]
      const hiddenButton = this.$refs[this.playerInfo.players[this.playerInfo.hiddenPlayer].buttonLabel]
      gsap.set(hiddenBox, { opacity: 0 })
      gsap.set(hiddenTitle, { opacity: 0 })
      gsap.set(hiddenContent, { opacity: 0 })
      gsap.set(hiddenButton, { opacity: 0 })
    },
    triggerTimer () {
      // get active player index
      const playerIndex = this.playerInfo.activePlayer
      // get slide duration
      const dur = (this.slideInfo[playerIndex].duration) * 1000
      if (this.autoPlay) {
        setTimeout(() => {
          this.startNextSlide()
        }, dur)
      }
    },
    visibilityChanged (isVisible) {
      this.isVisible = isVisible
    },
    initSlideshow () {
      this.$refs[this.playerInfo.players[this.playerInfo.activePlayer].videoLabel].play()
      this.triggerTimer()
    },
    onEventClick (eventName) {
      this.$emit(eventName)
    },
    loadVideo (targetPlayer) {
      if (targetPlayer) {
        targetPlayer.load()
        targetPlayer.pause()
        targetPlayer.currentTime = 0
      }
    },
    playVideo (targetPlayer) {
      if (targetPlayer) {
        targetPlayer.currentTime = 0
        targetPlayer.play()
      }
    },
    stopVideo (targetPlayer) {
      if (targetPlayer) {
        targetPlayer.pause()
        targetPlayer.currentTime = 0
      }
    }
  },
  mounted () {
    // giv refs a chance tp register
    setTimeout(() => {
      this.$nextTick(() => this.initSlideshow())
    }, 500)
  }
}
</script>

<template>
  <section
    :style="sectionHeight"
    class="slide-section"
    v-observe-visibility="{
      callback: visibilityChanged,
      throttle: 300,
    }"
  >
    <!-- ------------------- START PLAYER ONE --------------- -->
    <div
      :id="slides[slideInfo[0].index].id"
      ref="playerOne"
      :style="sectionHeight"
      class="player-one player-container"
      :class="{'is-image': slideInfo[0].type !== 'VIDEO'}"
    >
      <div :style="sectionHeight" class="slide">
        <div :style="sectionHeight" class="video-slide">
          <div
            class="video-buffering"
            :style="{
              backgroundImage: `url(${slideInfo[0].poster})`,
            }"
          />
          <div
            key="videoContainerOne"
            class="video-container"
          >
            <video
              ref="videoOne"
              autoplay="autoplay"
              playsinline=""
              disablepictureinpicture
              loop="loop"
              preload="auto"
              style="object-fit: cover"
              :muted="true"
            >
              <source
                ref="videoSourceOne"
                :src="slideInfo[0].video" type="video/webm"
              />
            </video>
          </div>
        </div>
      </div>
      <div
        :style="overlayStyle"
        class="slide-overlay"
      >
        <div
          class="slide-content"
          :class="{'align-left': slides[slideInfo[0].index].align.toUpperCase() === 'LEFT', 'align-right': slides[slideInfo[0].index].align.toUpperCase() === 'RIGHT'}"
        >
          <div
            class="content-container"
            ref="contentBoxOne"
            :class="{'content-box': slides[slideInfo[0].index].hasContentBox === true}"
          >
            <div
              ref="titleOne"
              v-dompurify-html="slides[slideInfo[0].index].title"
            />
            <div
              ref="contentOne"
              v-dompurify-html="slides[slideInfo[0].index].content"
            />
            <div class="buttons" ref="buttonOne">
              <button
                v-if="!autoPlay"
                @click="startNextSlide"
                class="button is-primary"
              >
                Next
              </button>
              <button
                v-if="slides[slideInfo[0].index].event"
                @click="onEventClick(slides[slideInfo[0].index].event)"
                class="button is-primary"
              >
                {{ slides[slideInfo[0].index].buttonLabel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- ------------------- START PLAYER TWO --------------- -->
    <div
      :id="slides[slideInfo[1].index].id"
      ref="playerTwo"
      :style="sectionHeight"
      class="player-two player-container"
      :class="{'is-image': slideInfo[1].type !== 'VIDEO'}"
    >
      <div :style="sectionHeight" class="slide">
        <div :style="sectionHeight" class="video-slide">
          <div
            class="video-buffering"
            :style="{
              backgroundImage: `url(${slideInfo[1].poster})`,
            }"
          />
          <div
              key="videoContainerTwo"
              class="video-container"
            >
              <video
                ref="videoTwo"
                autoplay="autoplay"
                playsinline=""
                disablepictureinpicture
                loop="loop"
                preload="auto"
                style="object-fit: cover"
                :muted="true"
              >
                <source
                  ref="videoSourceTwo"
                  :src="slideInfo[1].video" type="video/webm"
                />
              </video>
            </div>
        </div>
      </div>
      <div
        :style="overlayStyle"
        class="slide-overlay"
      >
        <div
          class="slide-content"
          :class="{'align-left': slides[slideInfo[1].index].align.toUpperCase() === 'LEFT', 'align-right': slides[slideInfo[1].index].align.toUpperCase() === 'RIGHT'}"
        >
          <div
            class="content-container"
            ref="contentBoxTwo"
            :class="{'content-box': slides[slideInfo[1].index].hasContentBox === true}"
          >
            <div
              ref="titleTwo"
              v-dompurify-html="slides[slideInfo[1].index].title"
            />
            <div
              ref="contentTwo"
              v-dompurify-html="slides[slideInfo[1].index].content"
            />
            <div class="buttons" ref="buttonTwo">
              <button
                v-if="!autoPlay"
                @click="startNextSlide"
                class="button is-primary"
              >
                Next
              </button>
              <button
                v-if="slides[slideInfo[1].index].event"
                @click="onEventClick(slides[slideInfo[1].index].event)"
                class="button is-primary"
              >
                {{ slides[slideInfo[1].index].buttonLabel }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--    &lt;!&ndash; -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; TITLE BANNER -&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45;&#45; &ndash;&gt;-->
    <!--    <div v-if="showBanner" class="banner">-->
    <!--      <slot name="banner">-->
    <!--        Testing-->
    <!--      </slot>-->
    <!--    </div>-->
  </section>
</template>

<style lang="scss" scoped>
.slide-section {
  display: block;
  overflow: hidden;
}
.player-container {
  &.is-image {
    .slide .video-slide {
      .video-container {
        position: relative;
        left: -200vw;
      }
    }
  }
}
.player-one {
  display: block;
  position: relative;
  //background-color: #fff;
  //width: 100vw;
  opacity: 1;
  z-index: 0;
  overflow: hidden;
}
.player-two {
  display: block;
  opacity: 0;
  //background-color: #fff;
  //width: 100vw;
  position: relative;
  z-index: 20;
  left: -200vw;
  top: -100%;
  overflow: hidden;
}
.slide {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  //background: #fff;

}
.video-slide {
  //background-color: #fff;
}
.video-buffering {
  width: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: 50%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
}
.video-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
  z-index: 0;
  opacity: 1;
}

video {
  visibility: visible;
  pointer-events: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 100%;
  width: 100%;
}
.slide-overlay {
  position: relative;
  top: 0;
  z-index: 15;
}
.slide-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
  &.align-left {
    align-items: flex-start;
  }
  &.align-right {
    align-items: flex-end;
  }
}
.banner {
  display: block;
  opacity: 1;
  //width: 100vw;
  position: relative;
  z-index: 30;
  top: -200%;
  overflow: hidden;
}
.content-box {
  background: hsla(360, 100%, 100%, 0.1);
  backdrop-filter: blur(3px);
  padding: 0 1rem 1rem 1rem;
}
</style>
