<template>
  <div class="column">
    <app-tenant-focus />
    <hr>
    <patient-focus />
    <hr>
    <clinic-game-play-list-focus />
    <div style="flex: auto; flex-direction: column; gap: 1rem;">
      <div>Dev Header</div>
      <div>{{ devHeaders }}</div>
      <b-button
        v-clipboard:copy="devHeaders"
        v-clipboard:success="onCopyDevHeader"
        size="is-small"
      >dev headers for graphql</b-button>
      <b-button
        v-clipboard:copy="devHeaderForVue"
        v-clipboard:success="onCopyDevHeader"
        size="is-small"
      >dev header for vue</b-button>
    </div>
  </div>
</template>

<script>
import AppTenantFocus from './AppTenantFocus.vue'
import PatientFocus from './PatientFocus.vue'
import ClinicGamePlayListFocus from './ClinicGamePlayListFocus.vue'

export default {
  components: {
    AppTenantFocus,
    PatientFocus,
    ClinicGamePlayListFocus
  },
  computed: {
    devHeaders () {
      const devHeader = window.btoa(JSON.stringify(this.$store.state.user.userInfo))
      const headers = {
        'ovb-dev-header': devHeader
      }

      return JSON.stringify(headers, null, 2)
    },
    devHeaderForVue () {
      const devHeader = window.btoa(JSON.stringify(this.$store.state.user.userInfo))
      return devHeader
    }
  },
  methods: {
    onCopyDevHeader () {
      alert('Copied')
    }
  }
}
</script>

<style>

</style>
