<template>
  <div v-if="recentClinicGamePlayLists.length > 0">
    <b-field label="Recent ClinicPlayLists">
      <div class="column">
        <div v-for="playList in recentClinicGamePlayLists" :key="playList.id">
          <b-tag
            closable
            @close="removeClinicPlayList(playList.id)"
          >
            <router-link
              class="is-small"
              :to="{ name: 'ClinicPlayListDetail', params: { clinicPlayListId: playList.id } }"
            >{{`${playList.name}`}}</router-link>
          </b-tag>
        </div>
        <b-button class="is-small" @click="clearRecentClinicPlayLists">Clear All</b-button>
      </div>
    </b-field>
  </div>
</template>

<script>
export default {
  computed: {
    recentClinicGamePlayLists () {
      return this.$store.state.ovb.recentClinicGamePlayLists
    },
    clinicPlayListFocus () {
      return this.$store.state.ovb.clinicPlayListFocus
    }
  },
  methods: {
    removeClinicPlayList (clinicPlayListId) {
      this.$store.commit('ovb/REMOVE_RECENT_CLINIC_PLAYLIST', { id: clinicPlayListId })
    },
    clearRecentClinicPlayLists () {
      this.$store.commit('ovb/CLEAR_RECENT_CLINIC_PLAYLISTS')
    }
  }
}
</script>

<style>

</style>
