// Custom Filters
// const dateFns = require('date-fns')
import * as dateFns from 'date-fns'
export default {
  formatDate: (value, msg) => {
    // create default if no error msg provided
    const errMsg = msg || 'Invalid Date'
    // check if value is already a date
    const isDate = (Object.prototype.toString.call(value) === '[object Date]')
    // if not, parse the date
    const theDate = isDate ? value : dateFns.parseISO(value)
    // check if parsed date is a valid date by checking timestamp
    const isValidDate = !isNaN(Date.parse(theDate))
    // if valid, return the formatted date. Otherwise, return error text
    return isValidDate ? dateFns.format(theDate, 'MM/dd/yyyy') : errMsg
  },
  formatDateTime: (value, msg) => {
    // create default if no error msg provided
    const errMsg = msg || 'Invalid Date'
    // check if value is already a date
    const isDate = (Object.prototype.toString.call(value) === '[object Date]')
    // if not, parse the date
    const theDate = isDate ? value : dateFns.parseISO(value)
    // check if parsed date is a valid date by checking timestamp
    const isValidDate = !isNaN(Date.parse(theDate))
    // if valid, return the formatted date. Otherwise, return error text
    return isValidDate ? dateFns.format(theDate, 'MM/dd/y h:mma') : errMsg
  }
}
