import apolloProvider from '@/graphql'
import updateUowStatus from '@/graphql/mutation/updateUowStatus.graphql'

const types = {
  UPDATE_UOW_STATUS: 'UPDATE_UOW_STATUS'
}

const getDefaultState = () => {
  return {
  }
}

// initial state
const state = getDefaultState()

// getters
const getters = {
}

// actions
const actions = {
  [types.UPDATE_UOW_STATUS]: {
    root: true,
    handler: async (store, payload) => {
      return apolloProvider.defaultClient
        .mutate({
          mutation: updateUowStatus,
          variables: payload
        })
        .then(result => {
          store.commit('app/refreshKey', null, { root: true })
        })
    }
  }
}

// mutations
const mutations = {
  // [types.RESET_BILLING] (state) {
  //   Object.assign(state, getDefaultState())
  // },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
