<script>
// import VideoBackground from '@/components/_common/special/VideoBackground'

export default {
  name: 'TherapyManagementBlock',
  components: {
    // VideoBackground
  },
  data () {
    return {
    }
  },
  computed: {
  }
}
</script>
<template>
  <div class="therapy-management container mobile-container">
    <article class="therapy-management-row">
      <section>
        <header>
          <h2 class="text-outline-light section-title-main">Intuitive Therapy Management Tools</h2>
          <div class="section-subtitle">Get in and get out!</div>
        </header>
        <div class="is-size-5-tablet mt-4">
          Our therapy management tools simplify therapy by centralizing scheduling, customization, and communication between therapists and patients. The easy-to-use interface allows for efficient tracking of progress, monitoring patient engagement, and documenting treatment plans. It helps patients stay on track and therapists to manage their caseload effectively, providing better care to patients and freeing up time for other tasks
        </div>
        <div class="content">
          <ul
            class="is-size-5-tablet"
          >
            <li>
              Quickly & Easily Check Compliance
            </li>
            <li>
              Add & Modify Assignments Inside Your Dashboard
            </li>
            <li>
              Detailed Scoring Metrics
            </li>
            <li>
              Secure Patient Messaging
            </li>
          </ul>
        </div>
        <section class="mb-4">
          <header>
            <h4 class="section-subtitle">Take a Closer Look</h4>
          </header>
          <div class="is-size-5-tablet">
            Schedule a demonstration &mdash; a one-on-one video conference walkthrough of our platform.
          </div>
          <a
            href="https://calendar.app.google/KvFP69dU5k66Fen7A"
            target="_blank"
            class="button is-primary mt-2"
            :class="{ 'is-large': $mq !== 'mobile' }"
          >
            Schedule a Demonstration
          </a>
        </section>
      </section>
      <aside>
        <div
          class="patient-overview-image-mobile is-hidden-tablet"
        >
          <b-image
            src="img/promo/home/TherapyManagement/PatientOverview@0.75x.webp"
            ratio="459by369"
          />
        </div>
        <div
          class="patient-overview-image is-hidden-mobile"
        >
          <b-image
            src="img/promo/home/TherapyManagement/PatientOverview_longish@0.75x.webp"
            ratio="1044by1540"
          />
        </div>
      </aside>
    </article>
  </div>
</template>

<style scoped lang="scss">
.therapy-management {
  display: block;
  position: relative;
  background-image: $page-background;
  padding: 2rem 0 2rem 0;
  .therapy-management-row {
    display:flex;
    flex-direction: column;
    .patient-overview-image-mobile {
      margin-top: 1rem;
      width: 100%;
      box-shadow: rgba(0, 0, 0, 0.2) 0 10px 50px;
    }
    @include tablet {
      flex-direction: row;
      aside {
        margin-left: 2rem;
      }
      .patient-overview-image {
        width: 30rem;
        box-shadow: rgba(0, 0, 0, 0.2) 0 10px 50px;
        @include tablet {
          width: 26rem;
        }
        @include desktop {
          width: 30rem;
        }
      }
    }
  }
}
</style>
